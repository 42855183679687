import React from "react";

import {
  // Customizable Area Start
  Box,
  Button,
  InputLabel,
  Typography,
  Slide,
  Tabs,
  Tab,
  Grid,
  Select,
  TextField,
  Link,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  MenuItem,
  Checkbox,
  Dialog,
  ListItemText,
  DialogContent,
  DialogActions,
  Snackbar,
  TextareaAutosize,
  FormControl,
  CircularProgress,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Alert } from '@material-ui/lab';
import Pagination from '@material-ui/lab/Pagination';
import { styled, withStyles } from "@material-ui/core/styles";
import TagsCRUD from "./Tags.web";
import TextArea from '../../../components/src/CustomTextArea.web';

interface TabPanelData {
  value: number;
  index: number;
  children: React.ReactNode;
}
// Customizable Area End

import AccountCreationController, {
  Props,
} from "./AccountCreationController.web";
import { uploadIcon, rectangleIcon, plusIcon, cardIcon2, deleteIcon } from "./assets";


const rows = [
  {
    name: "loremipsum@gmail.com",
    Groups: "-",
    District: "-",
    Industry: "Lorem Ipsum",
    protein: "sdfckjhufvn"
  },

]
const names = [
  'Group Name 1',
  'Group Name 2',
  'Group Name 3',
  'Group Name 4',
  'Group Name 5'
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export class AccountCreation extends AccountCreationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

  // Customizable Area Start
  const { userList, groupList, userCount, tabValue, page, rowsPerPage, adminDetails } = this.state;
  const { classes } = this.props;
  // Customizable Area End

    function a11yProps(index: number) {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }

    const TabPanelSurvey = (props: TabPanelData) => {
      const { children, value, index } = props;

      return (
        <div
          id={`simple-tabpanel-${index}`}
          hidden={value !== index}
          aria-labelledby={`simple-tab-${index}`}
          role="tabpanel"
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      );
    };


    return (
      // Customizable Area Start
      <Box className={classes.mainBox}>
        {
          <Box sx={{ width: '100%' }} className={classes.tabs} >
            <Box className={classes.cardHeader} >
              <Tabs value={tabValue} data-test-id="tabValue" onChange={this.handleChangeTab} aria-label="basic tabs example" id="tabChangeSection"
                TabIndicatorProps={{ style: { background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)", height: "10px", borderRadius: "15px 15px 0 0" } }
                }>
                <Tab className={classes.tabsurvey} label="User list" {...a11yProps(0)} />
                <Tab className={classes.tabsurvey} label="Groups" {...a11yProps(1)} data-test-id="user-list-btn" />
              </Tabs>
            </Box>
            {tabValue === 0 && (
              <div style={{padding:'24px'}}>
              <Grid container spacing={2} style={{ padding: "20px" }}>
                <Grid item xs={12} md={4} sm={12} style={{ justifyContent: 'flex-start' }}>
                  <Button size="large" data-test-id="create-user" className={classes.createUser} onClick={this.handleClickCreateUser}>
                    Create user
                  </Button>
                </Grid>
                <Grid item xs={12} md={8} sm={12} style={{ display: 'flex', justifyContent: 'flex-end', flexWrap: "wrap" }}>
                  <a 
                    download="User Upload Template.xlsx" 
                    target="_blank" 
                    href={this.userTemplateFile}
                    data-test-id="view-upload-template"
                    className={classes.link}
                    style={{ padding: "10px" }}
                  >
                    Download template
                  </a>
                  <Button
                    size="large"
                    data-test-id="Bulk-upload-user"
                    className={classes.uploadUsers}
                    onClick={this.handleUploadUserClick}
                    endIcon={<img src={uploadIcon} alt="Icon" />}
                  >
                    Bulk upload users
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer >
                    <Table className={classes.table} aria-label="customized table">
                      <TableHead className={classes.tableheader}>
                        <TableRow>
                          <TableCell className={classes.tabelCellHead}>First Name</TableCell>
                          <TableCell className={classes.tabelCellHead}>Last Name</TableCell>
                          <TableCell className={classes.tabelCellHead}>Email</TableCell>
                          <TableCell className={classes.tabelCellHead}>Groups</TableCell>
                          {adminDetails?.attributes?.has_districts && (<TableCell className={classes.tabelCellHead}>District/Local authority</TableCell>)}
                          {adminDetails?.attributes?.has_industry_sector && (<TableCell className={classes.tabelCellHead}>Industry/Sector</TableCell>)}
                          {adminDetails?.attributes?.has_company && (<TableCell className={classes.tabelCellHead}>Company name</TableCell>)}
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userList.map((row: any) => (
                          <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                              {row.first_name|| "-"}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.last_name|| "-"}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.email}
                            </TableCell>
                            <TableCell component="th" scope="row" style={{ width: "0px" }}>
                            <FormControl key={row.user_id}>
                              <Select
                                variant="outlined"
                                labelId="demo-mutiple-checkbox-label"
                                data-test-id={`demo-mutiple-group-checkbox-${row.user_id}`}
                                id="demo-mutiple-checkbox"
                                multiple
                                value={row.group_ids}
                                open={this.state.openDropdownId == row.user_id}
                                onClose={this.handleSelectClose}
                                onOpen={() =>this.handleSelectOpen(row.user_id)}
                                onChange={(e) => e.preventDefault()}
                                renderValue={() => groupList.filter((group: any) => row.group_ids.includes(group.id)).map((group: any) => group.name).join(", ")}
                                MenuProps={MenuProps}
                                style={{
                                  border: "1px solid gray",
                                  borderRadius: '10px',
                                  width: "300px",
                                  height: "40px",
                                }}
                              >
                                {groupList.map((group: any) => (
                                  <MenuItem 
                                    key={group.id} 
                                    value={group.id} 
                                    id={`user-list-group-list-dropdown-${group.id}`}
                                    onClick={(event:any) => {
                                      this.toggleUserInGroup(row.user_id, group.id, { target: { checked: !row.group_ids.includes(group.id)}})
                                    }}
                                  >
                                    <ListItemText primary={group.name} style={{overflow:"hidden"}} />
                                    <Checkbox 
                                      id={`group-id-${group.id}`}
                                      data-test-id={`checkbox-user-list-${row.user_id}-group-list-${group.id}`}
                                      style={{ color: "#3c5584" }} 
                                      checked={row.group_ids.includes(group.id)}
                                      // onChange={(event:any) => this.toggleUserInGroup(row.user_id, group.id, event)}
                                      />
                                  </MenuItem>
                                ))}
                              </Select>
                              </FormControl>
                            </TableCell>
                            { adminDetails?.attributes?.has_districts && (
                              <TableCell component="th" scope="row">{row.district_name || "-"}</TableCell>
                            )}
                            { adminDetails?.attributes?.has_industry_sector && (
                              <TableCell component="th" scope="row">{row.industry_name || "-"}</TableCell>
                            )}
                            { adminDetails?.attributes?.has_company && (
                              <TableCell component="th" scope="row">{row.company || "-"}</TableCell>                                
                            )}
                            <TableCell component="th" scope="row" style={{ textAlign: "end" }} >
                              <Button variant="outlined" data-test-id={`Edit-button-${row.user_id}`} className={classes.EditButton} onClick={() => this.handleOpenEdit(row)}>
                                Edit
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box style={{ display: "flex", justifyContent: "center", padding: "20px", border: "1px solid #BBBBBB" }}>
                    <span className={classes.pagination}>{rowsPerPage * (page - 1) + 1} to {(rowsPerPage * page) > userCount ? userCount : rowsPerPage * page} of {userCount} Items</span>
                    <Pagination className={classes.root} count={Math.ceil(userCount / rowsPerPage)} page={page} onChange={this.handleUserPagination} variant="outlined" shape="rounded" showFirstButton showLastButton />
                  </Box>
                </Grid>
              </Grid>
            </div>
            )}
            {tabValue === 1 && (
            <div style={{padding:'24px'}}>
              <Grid container spacing={2} style={{ padding: "20px" }}>
                <Grid item xs={12} md={6} lg={3}>
                  <div className={classes.cardBox}
                  onClick={this.handleCreateGroup}
                  data-test-id={`create-group`}>
                    <div className={classes.rectangleBody}>
                      <div className={classes.circle}>
                        <img
                          className={classes.plusIcon}
                          src={plusIcon}
                          alt="plusIcon"
                        />
                      </div>
                      <Typography className={classes.surveyText}>
                        Create new group
                      </Typography>
                    </div>
                  </div>
                </Grid>
                {(this.state.groupList || []).map(
                  (groupList: any, ind: any) => (
                    <Grid
                      key={ind}
                      item
                      xs={12}
                      md={6}
                      lg={3}
                      data-test-id={`unit-group-${ind}`}
                      onClick={() => this.handleSelectUsersForGroup(groupList.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className={classes.card}>
                        <div className={classes.container}>
                          <div className={classes.mainContainer}>
                            <img
                              className={classes.img}
                              src={cardIcon2}
                              alt="cardIcon"
                            />
                            <Typography className={classes.Respondents}>
                              {groupList.user_count || 0} Users
                            </Typography>
                          </div>
                          <CardHeaderName style={{wordWrap: "break-word" as const }} >
                            {groupList?.name}
                          </CardHeaderName>
                          <CardHeaderDescription style={{wordWrap: "break-word" as const, fontFamily: 'aeonik-regular',wordBreak:'break-all',maxWidth:'100%',overflowWrap:'break-word' }} className={classes.group}>
                            {groupList?.description}
                          </CardHeaderDescription>
                        </div>
                        <Grid 
                          container
                          spacing={2}
                          style={{ marginTop: "20px" }}
                        >
                          <Grid item xs={12} sm={6}>
                            <Button
                              onClick={(ev) => this.handleEditGroup(ev, groupList?.name, groupList?.description, groupList?.id)}
                              size="large"
                              fullWidth
                              data-test-id={`edit-group-btn-${ind}`}
                              className={classes.createUser}
                            >
                              Edit
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Button
                              onClick={(ev) => this.handleOpenDeleteGroupModal(ev, groupList?.id)}
                              size="large"
                              fullWidth
                              data-test-id={`delete-group-btn-${ind}`}
                              className={classes.deleteBtn}
                            >
                              Delete
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  )
                )}
              </Grid>
            </div>
            )}
            {tabValue === 2 && (
              <TabPanelSurvey value={tabValue} index={2}>
                <TagsCRUD />
              </TabPanelSurvey>
            )}
          </Box>
        }
        <Dialog aria-labelledby="customized-dialog-title" open={this.state.createUserModal} fullWidth
          PaperProps={{
            style: {
              borderRadius: "16px",
              boxShadow: "none",
              width: "690px",
              top: 100,
              position: 'absolute'
            },
          }}>
          <Box className={classes.surveyTital}>
            Create user
          </Box>
          <DialogContent style={{ height: "auto", padding: "9px 35px 20px 35px" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputLabel className={classes.lable}>User email address</InputLabel>
                <TextField id="outlined-basic" fullWidth variant="outlined" data-test-id="add-email"  style={{
                  border: "1px solid gray",
                  borderRadius: '10px',
                }}
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  error={this.state.error}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.lable}>Select the group</InputLabel>
                <Select
                  variant="outlined"
                  fullWidth
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  data-test-id="select-group"
                  multiple
                  value={this.state.userGroups}
                  onChange={this.handleCheck}
                  renderValue={() => groupList.filter((group: any) => this.state.userGroups.includes(group.id)).map((group: any) => group.name).join(", ")}
                  MenuProps={MenuProps}
                  style={{
                    border: "1px solid gray",
                    borderRadius: '10px',
                  }}
                >
                  {this.state.groupList.map((group: any) => (
                    <MenuItem key={group.id} value={group.id}>
                      <ListItemText primary={group.name} style={{overflow:"hidden"}}/>
                      <Checkbox style={{ color: "#3c5584" }} checked={this.state.userGroups.includes(group.id)} />
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Snackbar open={this.state.error} autoHideDuration={4000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              TransitionComponent={Slide}
              TransitionProps={{
                style: { transformOrigin: 'top center' },
                timeout: 500,
              }}
              onClose={this.handleSnackbarClose}>
              <Alert severity="warning"
                color="error"
                style={{ backgroundColor: 'white', color: 'black', borderRadius: "10px", border: "1px solid #DEDEDE" }}>
                {this.state.errorMessage}
              </Alert>
            </Snackbar>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <div className={classes.modalbtn}>
                <Button
                  data-test-id="modal-Create-btn"
                  className={classes.edit}
                  onClick={this.handleCreateUserSubmit}
                  disabled={this.state.loading}
                >
                  Create
                </Button>
                <Button data-test-id="cancel-btn" className={classes.close} onClick={this.handleCancelCreateUser} style={{ marginBottom: "30px" }}>Cancel</Button>
              </div>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog aria-labelledby="customized-dialog-title" open={this.state.uploadModal} fullWidth
          PaperProps={{
            style: {
              borderRadius: "16px",
              boxShadow: "none",
              width: "500px",
              padding: "15px",
              top: 100,
              position: 'absolute'
            },
          }}>
          <DialogContent>
            <Typography className={classes.Error} gutterBottom>
              Bulk upload
            </Typography>
            <Typography className={classes.confrim} gutterBottom>
              Use this upload feature to add multiple users at<br /> once to the user list.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <div className={classes.modalbtn}>
              <InputLabel htmlFor="bulk-user-file-upload" className={classes.edit}>
                <input
                  disabled={this.state.uploadCancelState}
                  type="file"
                  accept='.xlsx, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  onChange={this.handleFileChange}
                  id="bulk-user-file-upload"
                  style={{ display: 'none'}}
                />
               {this.state.uploadCancelState ? <CircularProgress style={{ color: 'white' }} size={30}/> : 'Upload'}
              </InputLabel>
                <Button 
                disabled={this.state.uploadCancelState}
                data-test-id="modal-Cancel-btn" 
                className={classes.close} 
                onClick={this.handleUpoadUserCancel}>
                  Cancel
                </Button>
              </div>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog aria-labelledby="customized-dialog-title" open={this.state.uploadSuccessModal} fullWidth
          PaperProps={{
            style: {
              borderRadius: "16px",
              boxShadow: "none",
              width: "300px",
              padding: "20px",
              top: 100,
              position: 'absolute'
            },
          }}>
          <DialogContent>
            <Typography className={classes.Error} style={{ marginTop: "25px" }} gutterBottom>
              Upload successful
            </Typography>
            <Typography style={{textAlign:'center',margin:'15px 0px',color:'#359577'}}>
              {this.state.bulkUploadSuccessMessage}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <div className={classes.modalbtn}>
                <Button
                  data-test-id="modal-Continue-btn"
                  className={classes.edit}
                  style={{ marginBottom: "25px",padding:'14px 45px' }}
                  onClick={this.handleClickUploadSuccessContinue}
                >
                  Close
                </Button>
              </div>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog aria-labelledby="customized-dialog-title" open={this.state.uploadFailModal} fullWidth
          PaperProps={{
            style: {
              borderRadius: "16px",
              boxShadow: "none",
              width: "415px",
              // padding: "20px",
              top: 100,
              position: 'absolute'
            },
          }}>
          <DialogContent>
            <Typography className={classes.Error} style={{fontSize:'24px'}} gutterBottom>
              Upload fail
            </Typography>
            <Typography style={{ textAlign: 'center', fontFamily: 'aeonik-regular',color:'#ef536c',fontSize:'16px' }} gutterBottom>
              Reason: The file size has exceed the limit. File size should be less than 15 MB
            </Typography>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <div className={classes.modalbtn}>
                
                <Button
                  data-test-id="modal-Tryagian-btn"
                  className={classes.edit}
                  onClick={this.handleClickUploadUserFailTryAgain}>
                  Try Again
                </Button>
                <Button 
                  data-test-id="modal-cancel1-btn" 
                  className={classes.close} 
                  onClick={this.handleClickUploadUserFailCancel}>
                    Cancel
                  </Button>
              </div>
            </Grid>
          </DialogActions>
        </Dialog>
        
        <Dialog aria-labelledby="customized-dialog-title" open={this.state.particalUploadModal} fullWidth
          //Partical success modal
          PaperProps={{
            style: {
              borderRadius: "16px",
              boxShadow: "none",
              width: "500px",
              top: 100,
              position: 'absolute'
            },
          }}>
          <DialogContent>
            <Typography className={classes.Error} style={{fontFamily:'aeonik-bold'}} gutterBottom>
              Upload partially successful
            </Typography>
            <Typography className={classes.Error} style={{textAlign:'center',fontFamily:'aeonik-regular'}} gutterBottom>
              Some issues were encountered during the upload:
            </Typography>
            <div>
              {this.state.bulkUploadResponseArray.map((message, index) => (
                <Typography
                  key={index}
                  style={{ color: index === 0 ? '#359577' : '#ef536c' ,textAlign:'center',fontSize:'18px',fontFamily:'aeonik-regular'}}
                  gutterBottom
                >
                  {message}
                </Typography>
              ))}
            </div>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <div className={classes.modalbtn}>
                <Button
                  data-test-id="modal-Tryagian-btn-partial"
                  className={classes.edit}
                  onClick={this.handleClickUploadUserFailTryAgain}>
                  Try Again
                </Button>
                <Button 
                  data-test-id="modal-cancel1-btn-partial" 
                  className={classes.close} 
                  onClick={this.handleClickUploadUserFailCancel}>
                    Cancel
                  </Button>
              </div>
            </Grid>
          </DialogActions>
        </Dialog>

        <Dialog aria-labelledby="customized-dialog-title" open={this.state.uploadFailedModal} fullWidth
          //Uploade Failed
          PaperProps={{
            style: {
              borderRadius: "16px",
              boxShadow: "none",
              width: "500px",
              top: 100,
              position: 'absolute'
            },
          }}>
          <DialogContent>
            <Typography className={classes.Error} style={{fontFamily:'aeonik-bold'}} gutterBottom>
              Upload failed
            </Typography>
            <div>
              {this.state.bulkUploadResponseArray.map((message, index) => (
                <Typography
                  key={index}
                  style={{ color:'#ef536c' ,textAlign:'center',fontSize:'18px',fontFamily:'aeonik-regular'}}
                  gutterBottom
                >
                  {message}
                </Typography>
              ))}
            </div>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <div className={classes.modalbtn}>
                <Button
                  data-test-id="modal-Tryagian-btn-failed"
                  className={classes.edit}
                  onClick={this.handleClickUploadUserFailTryAgain}>
                  Try Again
                </Button>
                <Button 
                  data-test-id="modal-cancel1-btn-failed" 
                  className={classes.close} 
                  onClick={this.handleClickUploadUserFailCancel}>
                    Cancel
                  </Button>
              </div>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog aria-labelledby="customized-dialog-title" open={this.state.EditUserModal} fullWidth
          PaperProps={{
            style: {
              borderRadius: "16px",
              boxShadow: "none",
              width: "690px",
              position: 'absolute'
            },
          }}>
          <Box className={classes.surveyTital} style={{ display: "flex", justifyContent: "space-between"}}>
            <div></div>
            <div>Edit user</div>
            <div><Button onClick={this.handleOpenDeleteUser} data-test-id="delete-user-icon-button"><img src={deleteIcon} alt="Delete" style={{ height: "20px" }}/></Button></div>
          </Box>
          <DialogContent style={{ height: "auto", padding: "9px 35px 20px 35px" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputLabel className={classes.lable}>User email address</InputLabel>
                <TextField id="outlined-basic" fullWidth variant="outlined" data-test-id="user-email" style={{
                  border: "1px solid gray",
                  borderRadius: '10px',
                }}
                  value={this.state.email}
                  error={this.state.error}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.lable}>Select the group</InputLabel>
                <Select
                  variant="outlined"
                  fullWidth
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  data-test-id="selectgrop1"
                  value={this.state.userGroups}
                  onChange={this.handleCheck}
                  renderValue={() => groupList.filter((group: any) => this.state.userGroups.includes(group.id)).map((group: any) => group.name).join(", ")}
                  MenuProps={MenuProps}
                  style={{
                    border: "1px solid gray",
                    borderRadius: '10px',
                  }}
                >
                  {groupList.map((group: any) => (
                    <MenuItem key={group.id} value={group.id}>
                      <ListItemText primary={group.name} style={{overflow:"hidden"}}/>
                      <Checkbox style={{ color: "#3c5584" }} checked={this.state.userGroups.includes(group.id)} data-test-id={`edit-user-group-option-${group.id}`}/>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              
              {adminDetails?.attributes?.has_districts && (
                <Grid item xs={12}>
                  <InputLabel className={classes.lable}>District/Local authority</InputLabel>
                  <Select
                    fullWidth
                    variant="outlined"
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    className={classes.select}
                    name="selectedEndTime"
                    data-test-id="District-dropdown"
                    value={this.state.district}
                    onChange={(e) => this.setState({ district: e?.target.value })}
                    displayEmpty
                  >
                    {this.state.districtList.map(dist => (
                      <MenuItem onClick={()=>this.handleDistrictChange(dist)} value={dist.id} data-test-id={`district-dropdown-value-${dist.id}`}>{dist.name}</MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}
              {adminDetails?.attributes?.has_industry_sector && (
                <Grid item xs={12}>
                  <InputLabel className={classes.lable}>Industry sector</InputLabel>
                  <Select
                    fullWidth
                    variant="outlined"
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    className={classes.select}
                    name="selectedEndTime"
                    data-test-id="Industry-dropdown"
                    value={this.state.industry}
                    onChange={(e) => this.setState({ industry: e.target.value })}
                    displayEmpty
                  >
                    {this.state.industryList.map(inds => (
                      <MenuItem onClick={()=>this.handleIndustry(inds)} value={inds.id} data-test-id={`industry-dropdown-value-${inds.id}`}>{inds.name}</MenuItem>
                    ))}
                  </Select>
              </Grid>
              )}
              {adminDetails?.attributes?.has_company && (
                <Grid item xs={12}>
                <InputLabel className={classes.lable}>Company name</InputLabel>
                <TextField 
                  inputProps={{ maxLength: 160 }}
                  id="outlined-basic" 
                  fullWidth 
                  variant="outlined" 
                  data-test-id="company-textfield"
                    style={{
                    border: "1px solid gray",
                    borderRadius: '10px',
                  }}
                  value={this.state.company}
                  onChange={(e) => this.setState({ company: e.target.value })}
                />
              </Grid>
              )}
            </Grid>
            <Snackbar open={this.state.error} autoHideDuration={4000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              TransitionComponent={Slide}
              TransitionProps={{
                style: { transformOrigin: 'top center' },
                timeout: 500,
              }}
              onClose={this.handleSnackbarClose}>
              <Alert severity="warning"
                color="error"
                style={{ backgroundColor: 'white', color: 'black', borderRadius: "10px", border: "1px solid #DEDEDE" }}>
                Missing required field.
              </Alert>
            </Snackbar>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <div className={classes.modalbtn}>
                <Button
                  data-test-id="modal-Update-btn"
                  className={classes.edit}
                  onClick={this.handleUserEditSubmit}
                  disabled={this.state.loading}
                >
                  Update
                </Button>
                <Button data-test-id="modal-close2-btn" className={classes.close} onClick={this.handleCloseEdit} style={{ marginBottom: "30px" }}>cancel</Button>
              </div>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog aria-labelledby="customized-dialog-title" open={this.state.deleteUserModal} fullWidth
          PaperProps={{
            style: {
              borderRadius: "16px",
              boxShadow: "none",
              width: "500px",
              padding: "20px",
              top: 100,
              position: 'absolute'
            },
          }}>
          <DialogContent>
            <Typography className={classes.Error} gutterBottom>
              Delete user
            </Typography>
            <Typography className={classes.deleteMessage} gutterBottom>
              Are you sure you want to delete {this.state.email} user? <br/>This action is not reversible
            </Typography>
            <Snackbar open={this.state.error} autoHideDuration={4000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              TransitionComponent={Slide}
              TransitionProps={{
                style: { transformOrigin: 'top center' },
                timeout: 500,
              }}
              onClose={this.handleSnackbarClose}>
              <Alert severity="warning"
                color="error"
                style={{ backgroundColor: 'white', color: 'black', borderRadius: "10px", border: "1px solid #DEDEDE" }}>
                {this.state.errorMessage}
              </Alert>
            </Snackbar>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <div className={classes.modalbtn}>
                <Button
                  data-test-id="delete-user-confirm-btn"
                  className={classes.edit}
                  disabled={this.state.loading}
                  onClick={this.confirmDeleteUser}>
                  Delete
                </Button>
                <Button 
                  data-test-id="delete-user-cancel-btn" 
                  className={classes.close} 
                  onClick={this.cancelDeleteUser}>
                    Cancel
                  </Button>
              </div>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog aria-labelledby="customized-dialog-title" open={this.state.createGroup} fullWidth onClose={this.handleCloseGroup}
          PaperProps={{
            style: {
              borderRadius: "16px",
              boxShadow: "none",
              width: "90%",
              top: 40,
              position: 'absolute'
            },
          }}>
          <Box className={classes.surveyTital}>
            {this.state.isGroupEdit ? "Edit group": "Create group" }
          </Box>
          <DialogContent style={{ height: "auto", padding: "9px 35px 15px 35px" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputLabel className={classes.lable}>Enter the group name</InputLabel>
                <TextField fullWidth variant="outlined"
                  data-test-id="user-email-name"
                  className={classes.inputBox}
                  value={this.state.createGroupAdd.email}
                  onChange={(event) => this.handleChangeCreateGroup(event)}
                  name="email"
                  error={this.state.groupError.name}
                  placeholder="Enter name"
                  inputProps={{maxLength: 64}}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.lable}>Group description</InputLabel>
                <TextareaAutosize aria-label="empty textarea" style={{
                  border: `1px solid ${ this.state.groupError.description ? "red" : "#E0E0E0"}`,
                  borderRadius: '10px',
                  width: "100%",
                  height: "116px",
                  padding: "14px",
                  fontSize: "16px",
                  resize: "none",
                  fontFamily: 'aeonik-regular',
                  fontWeight: 500,
                  color: "#202020",
                  overflow: 'visible',
                }}
                  name="description"
                  value={this.state.createGroupAdd.description}
                  onChange={(event) => this.handleChangeCreateGroup(event)}
                  placeholder="Enter description"
                  maxLength={512}
                  data-test-id="group-description-textfield"
                />
              </Grid>
            </Grid>
            <Snackbar open={Boolean(this.state.error)} autoHideDuration={4000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              TransitionComponent={Slide}
              TransitionProps={{
                style: { transformOrigin: 'top center' },
                timeout: 500,
              }}
              onClose={this.handleSnackbarClose}>
              <Alert severity="warning"
                color="error"
                style={{ backgroundColor: 'white', color: 'black', borderRadius: "10px", border: "1px solid #DEDEDE" }}>
                {this.state.errorMessage}
              </Alert>
            </Snackbar>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <div className={classes.modalbtn}>
                <Button
                  data-test-id="modal-Submit-btn"
                  className={classes.edit}
                  onClick={this.state.isGroupEdit ? this.handleGroupEdit : this.handleGroupSubmit}
                  disabled={this.state.loading}
                >
                  { this.state.isGroupEdit ? "Update":  "Create"}
                </Button>
                <Button data-test-id="cancel-btn-group" className={classes.close} onClick={this.handleCloseGroup} style={{ marginBottom: "30px" }}>Cancel</Button>
              </div>
            </Grid>
          </DialogActions>
        </Dialog>
        <DeleteGroupModal aria-labelledby="customized-dialog-title" open={this.state.deleteGroupModal} 
          PaperProps={{
            style: {
              borderRadius: "16px",
              boxShadow: "none",
              
              padding: "20px",
              top: 100,
              position: 'absolute'
            },
          }}>
          <DialogContent>
            <Typography className={classes.Error} gutterBottom>
              Delete group
            </Typography>
            <Typography className={classes.deleteMessage} gutterBottom>
              Are you sure you want to delete this group? <br/>This action is not reversible
            </Typography>
            <Snackbar open={this.state.error} autoHideDuration={4000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              TransitionComponent={Slide}
              TransitionProps={{
                style: { transformOrigin: 'top center' },
                timeout: 500,
              }}
              onClose={this.handleSnackbarClose}>
              <Alert severity="warning"
                color="error"
                style={{ backgroundColor: 'white', color: 'black', borderRadius: "10px", border: "1px solid #DEDEDE" }}>
                {this.state.errorMessage}
              </Alert>
            </Snackbar>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12}>
              <div className={classes.modalbtn}>
                <Button
                  data-test-id="delete-group-confirm-btn"
                  className={classes.edit}
                  disabled={this.state.loading}
                  onClick={this.confirmDeleteGroup}>
                  Delete
                </Button>
                <Button
                  data-test-id="delete-group-cancel-btn"
                  className={classes.close}
                  onClick={this.closeDeleteGroupModal}>
                    Cancel
                  </Button>
              </div>
            </Grid>
          </DialogActions>
        </DeleteGroupModal>
        <Snackbar open={this.state.createUserSuccess} autoHideDuration={4000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          TransitionComponent={Slide}
          TransitionProps={{
            style: { transformOrigin: 'top center' },
            timeout: 500,
          }}
          onClose={this.handleSnackbarClose}>
          <Alert severity="success"
            color="success"
            style={{ backgroundColor: 'white', color: 'black', borderRadius: "10px", border: "1px solid #DEDEDE" }}>
            User created successfully
          </Alert>
        </Snackbar>
      </Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const DeleteGroupModal = styled(Dialog)({
  '& .MuiPaper-root': {
    '@media (max-width: 1920px)': {
      width:'40%',
    },
    '@media (max-width: 600px)': {
      width:'60%',
    },
  },
  '& .MuiDialog-paper': {
  },
})
const CardHeaderName = styled(Typography)({
  fontSize: '20px',
  fontWeight: 600,
  marginBottom: '16px',
  lineHeight: 'normal',
  fontFamily: 'aeonik-regular',
  color: "#121212",
  '@media (max-width: 600px)': {
    fontSize:'16px',
  },
})
const CardHeaderDescription = styled(Typography)({
  
  '@media (max-width: 600px)': {
    fontSize:'14px',
  },
})
const styles = {
  root: {
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#BFCAE4"
    },
    "& .MuiPaginationItem-outlined": {
      border: '2px solid #F1F1F1',
    },
    "& .MuiPaginationItem-rounded": {
      borderRadius: '8px solid #F1F1F1'
    },
  },
  tabelCellHead: {
    fontWeight: 600,
    fontFamily: 'aeonik-regular',
  },
  mainContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  pagination: {
    marginTop: "3px",
    fontWeight: 400,
    fontFamily: 'aeonik-regular',
  },
  EditButton: {
    borderRadius: "5px",
    border: "1px solid #304a7c",
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
    color: "#304a7c",
    fontWeight: 600,
    width: "65%"
  },
  cardHeader: {
    borderBottom: '1px solid #E7E7E7',
    padding: '0 40px',
    minHeight: '72px',
    display: 'flex',
    alignItems: 'center',
  },
  mainBox: {
    border: '1px solid #ddd',
    margin: '50px',
    boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px;',
    borderRadius: '20px',
    background: "#FFFFFF",
    height: "auto",
    flex: 1,
    "@media (max-width: 500px)": {
      margin: '20px',
    }
  },
  tabsurvey: {
    '&.MuiTab-root': {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: ' 23px',
      color: ' #202020',
      fontFamily: "aeonik-bold",
      opacity: ' 0.4',
      textTransform: 'none' as const,
      minHeight: "100px",

    },
    '&.MuiTab-root.Mui-selected': {
      color: ' #202020',
      opacity: 1
    },
  },
  lable: {
    color: "#333333",
    fontSize: "16px",
    fontFamily: 'aeonik-regular',
    fontWeight: 600,
    margin: "10px 0",

  },
  select: {
    border: "1px solid #E0E0E0",
    borderRadius: "10px",
  },
  edit: {
    padding: '18px 55px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    background: 'linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)',
    fontSize: '16px',
    fontWeight: 700,
    color: 'rgba(255, 255, 255, 1)',
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    textTransform: "none" as const,
    '&:hover': {
      backgroundColor: '#1C386D',
      boxShadow: 'none',
    },
  },
  Error: {
    display: "flex",
    justifyContent: "center",
    fontFamily: 'aeonik-regular',
    fontSize: "20px",
    fontWeight: 700,
    textTransform:'none' as const,
  },
  confrim: {
    textAlign: "center" as const,
    marginTop: "20px",
    fontFamily: 'aeonik-regular',
    fontWeight: 600
  },
  modalbtn: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: "center",
    gap: "10px",
  },
  close: {
    padding: '18px 55px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
    color: "#1C386D",
  },
  surveyTital: {
    color: "#202020",
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: 'aeonik-regular',
    display: "flex",
    padding: "20px",
    justifyContent: "center",
    borderBottom: "1px solid #E7E7E7",
    marginBottom: "10px"
  },
  createUser: {
    border: "1px solid",
    borderColor: "#304a7c",
    borderRadius: "10px",
    color: "#304a7c",
    fontFamily: 'aeonik-regular',
    textTransform: "none" as const,
    fontWeight: 700
  },
  link: {
    color: "#304a7c",
    fontFamily: 'aeonik-regular',
    textTransform: "none" as const,
    fontWeight: 700,
  },
  uploadUsers: {
    width: "auto",
    border: "1px solid",
    borderColor: "#304a7c",
    borderRadius: "10px",
    color: "#304a7c",
    fontFamily: 'aeonik-regular',
    textTransform: "none" as const,
    fontWeight: 700
  },
  table: {
    border: "1px solid #B2B2B2",
  },
  tableheader: {
    backgroundColor: "#ECF2FF",
  },
  newSurvey: {
    position: 'relative' as const,
    height: '280px',
    cursor: 'pointer'
  },
  rectangle: {
    height: '100%',
    width: '100%'
  },
  dialogBody: {
    width: '540px',
    height: '370px',
    borderRadius: '16px',
    background: '#FFF'
  },
  headContainer: {
    borderBottom: '1px solid #E7E7E7',
    padding: '32px 0px 24px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dialogHeader: {
    color: ' var(--text, #202020)',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
  },
  dialogContent: {
    padding: '16px 50px 40px 50px'
  },
  formTitle: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    marginBottom: '12px',
    color: 'rgba(51, 51, 51, 1)'
  },
  formInput: {
    padding: '16px',
    borderRadius: '10px',
    border: '1px solid #E0E0E0',
    background: '#FFF',
    width: '100%',
    marginBottom: '40px'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center'
  },
  rectangleBody: {
    // position: 'absolute' as const,
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'center'
  },
  circle: {
    borderRadius: '50%',
    width: '58px',
    height: '58px',
    background: 'var(--g, linear-gradient(180deg, #1C386D 0%, #1C386D  100%))',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '24px'
  },
  plusIcon: {
    width: '43.5px',
    height: '43.5px'
  },
  surveyText: {
    fontSize: '16px',
    fontWeight: 700,
    fontFamily: 'aeonik-bold',
    lineHeight: 'normal',
    color: 'rgba(28, 56, 109, 1), rgba(191, 202, 228, 1)',
    '@media (maxWidth:500px)':{
      fontSize:'15px',
    }
  },
  text: {
    fontSize: '14px',
    fontWeight: 500,
    marginBottom: '36px',
    lineHeight: 'normal',
    fontStyle: 'normal',
  },
  btn: {
    padding: '14px 32px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    background: 'var(--g, linear-gradient(180deg, #1C386D 0%, #1C386D 100%))',
    fontSize: '16px',
    fontWeight: 700,
    color: 'rgba(255, 255, 255, 1)',
    lineHeight: 'normal',
    fontStyle: 'normal',
    flex: '1 1 0px'
  },
  btn2: {
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    color: 'rgba(28, 56, 109, 1), rgba(191, 202, 228, 1)',
    background: 'none',
    marginTop: '20px'
  },
  card: {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: "space-between",
    minHeight: '270px',
    padding: '24px 16px',
    borderRadius: '28px' as const,
    border: '0.5px solid #DEDEDE',
    background: '#FCFCFC',
    height: "100%",
    boxSizing: "border-box" as const
  },
  container: {
    display: 'flex',
    flexDirection: 'column' as const,
    color: 'var(--text, #202020)',
  },
  img: {
    width: '50px',
    height: '50px',
    marginBottom: '24px'
  },
  subHead: {
    fontSize: '14px',
    fontWeight: 600,
    marginBottom: '8px',
    lineHeight: 'normal',
    fontStyle: 'normal',
  },
  cardBox: {
    display: 'flex',
    justifyContent: 'center',
    border: "1px dashed #A7A7A7",
    height: '100%',
    minHeight: "270px",
    borderRadius: '28px' as const,
    background: '#FCFCFC',
    cursor: "pointer"
  },
  Respondents: {
    // width: "150px",
    height: "22px",
    borderRedius: "5px",
    gap: "10px",
    fontWeight: 600,
    fontSize: "15px",
    textAlign: "center" as const,
    fontFamily: 'aeonik-regular',
    padding: "5px",
    color: "#304a7c"
  },
  cardHeaderName: {
    fontSize: '20px',
    fontWeight: 600,
    marginBottom: '16px',
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    color: "#121212",
      },
  ErrorMessage: {
    width:'90%',
    fontSize: '15px',
    fontWeight: 400,
    marginBottom: '10px',
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    color: "#ff0404",
  },
    deleteMessage: {
    textAlign: "center" as const,
    fontSize: '15px',
    fontWeight: 400,
    marginBottom: '10px',
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
  },
  inputBox: {
    borderRadius: '10px',
    fontSize: "16px",
    fontWeight: 500,
    color: "#202020",
    '& .MuiInput-underline:after': {
      borderBottomColor: '#e0e0e0',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#e0e0e0',
      },
      '&:hover fieldset': {
        borderColor: '#e0e0e0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#e0e0e0',
      },
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      '&:hover fieldset': {
        borderColor: '#f44336;',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#f44336;',
      },
    },
  },
  deleteBtn: {
    border: "1px solid #ef536c",
    borderRadius: "10px",
    color: "#ef536c",
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
    fontWeight: 700
  }
};

export default withStyles(styles)(AccountCreation);
// Customizable Area End
