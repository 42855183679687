import React, {
    FunctionComponent,
    useMemo
  } from "react";
  
  import { withStyles } from "@material-ui/core/styles";
  import ProgressBar from "./ProgressBar.web"  //"../../blocks/dashboard/src/ProgressBar.web"  
  import { getColors }  from "./color"
  
  import {
    Box,
  } from "@material-ui/core";
  
  interface Props {
    data: any;
    classes: any;
  }
  
  const OptionsType: FunctionComponent<Props> = ({
    data,
    classes,
  }) => {
    const variousColors = useMemo(() => getColors(data.length), [])
    return (
      <Box className={classes.outerContainer} data-test-id="option-type">
        <Box className={classes.mcqPercent}>
          {data.map((unitOption: any, ind: number) => (
            <ProgressBar
              value={unitOption.percentage}
              text={unitOption.number_of_respondents}
              barColor={variousColors[ind].barColor}
              bgColor={variousColors[ind].bgColor}
              textColor={variousColors[ind].textColor || "fff"}
            />
          ))}
          <Box className={classes.legendOuter}>
              {data.map((unitOption: any, ind: number) => (
                <div key={`dot-${unitOption?.percentage}-${ind}`} className={classes.legend}>
                  <div className={classes.dot} style={{backgroundColor: variousColors[ind].barColor}} />
                  {unitOption.optionName}
                </div>
              ))}
            </Box>
        </Box>
      </Box>
    );
  };
  
  const styles = {
    outerContainer: {
      width: "100%",
    },
    legendOuter: {
      marginTop: "32px",
      display: "flex",
      flexWrap: "wrap" as const,
    },
    legend: {
      display: "flex",
      padding: "16px 12px",
      marginRight: "12px",
      fontFamily: 'aeonik-regular',
      fontSize: "16px",
      alignItems: "center"
    },
    dot: {
      width: "17px",
      height: "17px",
      borderRadius: "50%",
      marginRight: "12px",
      minWidth:"17px",
    }
  };
  
  export default withStyles(styles)(OptionsType);
  