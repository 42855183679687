import React, {
  FunctionComponent,
} from "react";

import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Hidden,
  Typography,
  styled,
} from "@material-ui/core";

import TextualTypeWeb from "../../blocks/dashboard/src/TextualType.web";
import OptionsTypeWeb from "./OptionType.web";
import SliderTypeWeb from './SliderType.web';
import RankingTypeWeb from './RankingType.web';
import DateTypeWeb from '../../blocks/dashboard/src/DateType.web'

import RadioType from './RadioType.web'
interface Props {
  questionIndex: number;
  surveyId: string;
  questionData: any;
  classes: any;
  header: string;
}

const QuestionAnalysis: FunctionComponent<Props> = ({
  questionIndex,
  surveyId,
  questionData,
  classes,
  header,
}) => {
  const getComponent = () => {
    switch (questionData?.data.type) {
      case "radio":
        return <RadioType response={questionData} data={questionData.data.analytics} key={questionData?.id} />;
      case "checkbox":
        return <OptionsTypeWeb data={questionData.data.analytics} key={questionData?.id} />;

      case "short-text":
      case "long-text":
        return (
          <TextualTypeWeb
            questionIndex={questionData?.id}
            surveyId={surveyId}
            questionType={questionData?.data?.type}
            key={questionData?.id}
          />
        );

      case "slider":
        return <SliderTypeWeb data={questionData.data.analytics} key={questionData?.id} />;

      case "ranking":
        return <RankingTypeWeb data={questionData.data} />;
      case "date":
        return <DateTypeWeb questionIndex={questionIndex} surveyId={surveyId} data={questionData.data} QuestionID={questionData?.id} />;
      default:
        return <Box data-test-id="empty-box-default" />;
    }
  };

  return (
    <Box className={classes.outerContainer}>
      {header && (
        <StyledDiv>
          <HeaderTitle variant="h1"> {header}</HeaderTitle>
        </StyledDiv>
      )}

      <Box className={classes.header} data-test-id="pop">
        <Typography variant="h6" className={classes.headingSurvey}>
          Question {questionIndex}
        </Typography>
        <Typography variant="h6" className={classes.headingOverview}>
          {questionData?.title}
        </Typography>
      </Box>
      <Box className={classes.contentData}>{getComponent()}</Box>
    </Box>
  );
};

const StyledDiv = styled('div')({
  borderBottom: '1px solid #DEDEDE',
});

const HeaderTitle = styled(Typography)({
  background: 'linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)',
  color: '#FFFFFF',
  padding: '7px',
  borderRadius: '10px',
  textAlign: 'center',
  marginLeft: '30px',
  marginRight: '30px',
  marginTop: '5px',
  marginBottom: '10px',
  fontFamily: 'aeonik-regular',
  fontSize: '24px',
});

const styles = {
  outerContainer: {
    width: "100%",
    height: "100%",
  },
  header: {
    padding: "23px",
    borderBottom: "1px solid #DEDEDE",
    display: "flex",
    alignItems: "start", 
    justifyContent: "space-between", 
  },
  contentData: {
    overflowY: "auto" as const,
    height: "100%",
    Background: "red",
    padding: "32px",
    boxSizing: "border-box" as const,
    overflow: "hidden" as const,
    width: "calc(100%)",
  },
  headingSurvey: {
    fontFamily: 'aeonik-regular',
    fontWeight: 700,
    fontSize: "18px",
    color: "#9FA0A1",
    whiteSpace: 'nowrap' as const, 
    marginRight: "16px", 
  },
  headingOverview: {
    fontFamily: 'aeonik-regular',
    fontWeight: 700,
    fontSize: "18px",
    color: "#1C386D",
    textAlign: 'start' as const,
    flex: 1, 
    overflow: 'hidden', 
    textOverflow: 'ellipsis', 
  },
  questionType: {
    fontFamily: 'aeonik-regular',
    fontWeight: 700,
    fontSize: "18px",
    color: "#9FA0A1",
    marginLeft: "8px",
  },
};

export default withStyles(styles)(QuestionAnalysis);
