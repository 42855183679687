// Customizable Area Start
import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Slider,
  Slide,
  Snackbar,
  styled,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import DateFnsUtils from '@date-io/date-fns';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Input from '../../../components/src/CustomInput.web';
import TextArea from '../../../components/src/CustomTextArea.web';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Card from "../../../components/src/Card.web";
import { DragIndicator, Visibility } from '@material-ui/icons';
import UserFillSurveyController, { Props } from './UserFillSurveyController.web';
import { withState } from 'recompose';
import DatePicker from "react-multi-date-picker";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// Customizable Area End


export class UserFillSurvey extends UserFillSurveyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleSlash = this.handleSlash.bind(this);
    this.getStyle = this.getStyle.bind(this);
    this.handleDatePickerInteraction = this.handleDatePickerInteraction.bind(this);
    // Customizable Area End
  }
   // Customizable Area Start
  
   handleSlash(dateString:string , id:number){
    if (!dateString) {
      return null;
    }
    const parts = dateString.split('/');
    if (parts.length === 3) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; 
      const year = parseInt(parts[2], 10);
      return new Date(year, month, day); 
    }
    return null;
   }

   getStyle(id:number) {
    return {
      padding: '13px',
      height: '40px',
      borderRadius: '10px',
      cursor: 'pointer',
      borderColor: this.state.errorDate[id] === 'Please enter a valid date' ? "#f44336" : "grey"
    };
  }

  
  getValue(questionDetails: { answer: string | null; id: number; }) {
    return questionDetails.answer !== null
      ? this.handleSlash(questionDetails.answer, questionDetails.id)
      : this.state.birthDates[questionDetails.id] || "";
    }
    
    handleDatePickerInteraction() {
      const elements = document.querySelectorAll('div[style*="visibility"]');
      
      if (elements.length > 0) {
        elements.forEach((element) => {
          element.addEventListener('click', () => {
    
            const updateCalendarState = () => {
              const computedStyle = getComputedStyle(element as HTMLElement);
              const visibility = computedStyle.visibility;
              
              if (visibility === "visible") {
                this.setState({ calenderOpen: true });
              } else {
                this.setState({ calenderOpen: false });
              }
            };
    
            updateCalendarState(); 
    
            const observer = new MutationObserver(() => {
              updateCalendarState(); 
            });
    
            observer.observe(element, { attributes: true, attributeFilter: ['style', 'class'] });
          });
        });
      } 
    }
    
    render() {
      
      function placeFun(label:any)
      {
        return label || "";
      }
      
      function helperTextFun(error:any,msg:string)
      {
        return error ? msg : ""
        
      }
      const { currentQuestion, totalQuestion, questionDetails, header, questionsList,totalSection,currentSectionIndex } = this.state;
      const { classes } = this.props
      const weekDays = ["S", "M", "T", "W", "T", "F", "S"];
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      return (
      
        <MainContain onClick={() => {
          this.handleDatePickerInteraction();
          setTimeout(() => {
            this.handleMouseLeave(this.state.QuestionId);
          }, 50);
        }}>
        <CardWrapper >
          <Header>{this.state.surveyTitle}</Header>
          <CardBody >
          { this.state.hasHeader[currentSectionIndex] &&  <SectionHeader>{this.state.sections[this.state.currentSectionIndex].header}</SectionHeader>}
         
            <QuestionNumber data-test-id="question-number">Page {currentSectionIndex+1} out of {totalSection}</QuestionNumber>
            
              <Snackbar
                // TransitionComponent={Slide}
                open={this.state.error}
                autoHideDuration={4000}
                onClose={this.handleSnackbarClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <StyledAlertIcon severity="warning">
                  {this.state.errorMessage}
                </StyledAlertIcon>
              </Snackbar>
            <Snackbar open={this.state.success} autoHideDuration={4000}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              TransitionComponent={Slide}
              onClose={this.handleSnackbarClose}>
              <Alert
                severity="success"
                style={{ backgroundColor: 'white', color: 'black', borderRadius: "10px", border: "1px solid #DEDEDE" }}>
                {this.state.successMessage}
              </Alert>
            </Snackbar>

            <div>
              {this.state.sections[this.state.currentSectionIndex]?.questions.map((questionDetails:any, index:number) => (
                <QuestionCard key={questionDetails.id} data-test-id="question-card" className={questionDetails.require && this.isAnswerEmpty(questionDetails) && this.state.error
                  ? classes.questionCardError
                  : classes.questionCard}
                >
                  {questionDetails.question_type == "short-text" && (
                    <Box key={`short-text-question-${questionDetails.id}`}>
                      <Box data-test-id="question-title-short-text" className={classes.questionTitle}>{questionDetails.order_number}. {questionDetails.question_title}{questionDetails.require && "*"}</Box>
                      <TextField
                        key={questionDetails.id}
                        variant='outlined'
                        fullWidth
                        error={questionDetails.error}
                        data-test-id="short-answer-input"
                        // label='Short answer text'
                        value={questionDetails?.answer}
                        onChange={(event) => this.onChangeValue(event, questionDetails.id,'short-text')}
                        placeholder={placeFun(questionDetails.question_label)} 
                        helperText={helperTextFun(questionDetails.error,"Text is too long. Please enter 128 characters")}
                        FormHelperTextProps={{ style: { fontSize: 14,marginLeft:0 } }}
                        InputProps={{
                          style: { borderRadius: 10 } 
                        }}
                      />
                    </Box>
                  )}
                  {questionDetails.question_type == "long-text" && (
                    <Box key={`long-text-question-${questionDetails.id}`}>
                      <Box data-test-id="question-title-long-text" className={classes.questionTitle}>{questionDetails.order_number}. {questionDetails.question_title}{questionDetails.require && "*"}</Box>
                      <TextField
                        error={questionDetails.error}
                        variant='outlined'
                        fullWidth
                        multiline
                        rows={7}
                        data-test-id="long-answer-input"
                        value={questionDetails?.answer }
                        onChange={(event) => this.onChangeValue(event, questionDetails.id, 'long-text')}
                        placeholder={placeFun(questionDetails.question_label)} 
                        helperText={helperTextFun(questionDetails.error,"Text is too long. Please enter 512 characters.")}
                        FormHelperTextProps={{ style: { fontSize: 14 ,marginLeft:0} }}
                        InputProps={{
                          style: { borderRadius: 10, padding: '10px' } 
                        }}
                      />
                    </Box>
                  )}
                  {questionDetails.question_type == "radio" && (
                    <Box key={`radio-question-${questionDetails.id}`}>
                      <Box data-test-id="question-title-radio" className={classes.questionTitle}>{questionDetails.order_number}. {questionDetails.question_title}{questionDetails.require && "*"}</Box>
                      {questionDetails.options.map((option:any) => (
                        <div className={classes.radioButtonBox} key={option.id}>
                          <input
                            data-test-id={`radio-option-${option.id}`}
                            className={classes.radioButton}
                            id={option.id}
                            onChange={(event) => this.onChangeRadioValue(event, questionDetails.id)}
                            value={option.id}
                            type="radio"
                            checked={option.id == questionDetails.answer}
                          />
                          <label htmlFor={option.id} className={classes.radioButtonLabel}>{option.name}</label>
                        </div>
                      ))}
                    </Box>
                  )}
                  {questionDetails.question_type == "checkbox" && (
                    <Box key={`checkbox-question-${questionDetails.id}`}>
                      <Box data-test-id="question-title-checkbox" className={classes.questionTitle}>{questionDetails.order_number}. {questionDetails.question_title}{questionDetails.require && "*"}</Box>
                      {questionDetails.options.map((option:any) => (
                        <div className={classes.checkboxMain} key={option.id}>
                          <input
                            data-test-id={`checkbox-option-${option.id}`}
                            className={classes.checkboxBox}
                            id={option.id}
                            onChange={(event) => this.onChangeCheckValue(event, questionDetails.id)}
                            value={option.id}
                            type="checkbox"
                            checked={questionDetails.answer?.filter((ans: any) => ans == option.id).length}
                          />
                          <label htmlFor={option.id} className={classes.checkboxLabel}>{option.name}</label>
                        </div>
                      ))}
                    </Box>
                  )}
                  {questionDetails.question_type == "slider" && (
                    <Box key={`slider-question-${questionDetails.id}`}>
                      <Box data-test-id="question-title-slider" className={classes.questionTitle}>{questionDetails.order_number}. {questionDetails.question_title}{questionDetails.require && "*"}</Box>
                      <div data-test-id="slider-box" className={classes.sliderBox}>
                        <UserFillSurveySlider
                          key={`slider-input-${questionDetails.id}`}
                          data-test-id="slider-range-input"
                          className={classes.sliderInput}
                          min={questionDetails.slider.min_number}
                          max={questionDetails.slider.max_number}
                          valueLabelDisplay="on"
                          step={1}
                          value={questionDetails.answer}
                          onChange={(event,newValue) => this.onChangeSliderValue(event,newValue, questionDetails.id)}
                          defaultValue={questionDetails.slider.min_number}
                        />

                        <div className={classes.sliderTextDiv}>
                          <SliderLabelDiv>
                            {questionDetails.slider.lower_label}<br />{questionDetails.slider.min_number}
                          </SliderLabelDiv>
                          <SliderLabelDiv>
                            {questionDetails.slider.higher_label}<br />{questionDetails.slider.max_number}
                          </SliderLabelDiv>
                        </div>
                      </div>
                    </Box>
                  )}
                  {questionDetails.question_type == "date" && (
                <Box key="date-question-box">
                <Box data-test-id="question-title-slider" className={classes.questionTitle}>{questionDetails.order_number}. {questionDetails.question_title}{questionDetails.require && "*"}</Box>
                <DateWrapper>
                  <Typography style={styles.dateInputLabel}>Select date:</Typography>
                  <div className={classes.datepickerWrap}
                  onMouseEnter={()=>this.handleMouseEnter(questionDetails.id,questionDetails.answer)}
                  onMouseLeave={()=>this.handleMouseLeave(questionDetails.id)}
                  onClick={(event) => {
                    event.stopPropagation();
                    this.handleID(questionDetails.id);
                    this.handleDatePickerInteraction();
                  }}
                  >
                    <DatePicker
                      key={`date-input-${questionDetails.id}`}
                      onOpenPickNewDate={false}
                      data-test-id="date-picker"
                      value={this.getValue(questionDetails)}                    
                      placeholder='DD/MM/YYYY'
                      onChange={this.onChangeDate}
                      ref={this.state.datePickerRef}
                      style={this.getStyle(questionDetails.id)}                     
                       arrow={false}
                      className="custom-datepicker"
                      arrowStyle={{ color: "red" }}
                      highlightToday={false}
                      weekStartDayIndex={1}
                      showOtherDays
                      headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
                      format="DD/MM/YYYY"
                      
                    />
                  <CalendarTodayIcon data-test-id='calender-icon' style={{left:'-35px',position:'relative' , cursor : 'pointer', pointerEvents: 'none'}} id="calander-icon-button" />
                </div>
                </DateWrapper>
                <div style={{ color: '#f44336', marginTop: '10px', fontFamily:'sans-serif', fontSize:"14px"}}>
                    {this.state.errorDate[questionDetails.id]} 
                </div>
              </Box>
                )}
                  {questionDetails.question_type == "ranking" && (
                    <Box key="date-question-box">
                      <Box data-test-id="question-title-slider" className={classes.questionTitle}>{questionDetails.order_number}. {questionDetails.question_title}{questionDetails.require && "*"}</Box>
                      <div data-test-id="draggable-statement-div">
                        <DragDropContext onDragEnd={(result) => this.handleDragDrop(result, questionDetails)} data-test-id="drag-drop-context-id">
                          <Droppable droppableId="list-container">
                            {(provided: any) => (
                              <div
                                className="list-container"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {questionDetails.options.map((option: any, index: number) => (
                                  <Draggable key={option.id} draggableId={"dragid" + option.id} index={index}>
                                    {(provided: any, snapshot: any) => (
                                      <div
                                        className="item-container"
                                        ref={provided.innerRef}
                                        {...provided.dragHandleProps}
                                        {...provided.draggableProps}
                                      >
                                        <div className={classes.rankingStatement}>
                                          <div className={classes.textContainer}>
                                            <div className={classes.row}>
                                              <DragIndicator className={classes.rotatedIcon}/></div>
                                              <div style={{wordWrap:"break-word"}}>{option.name}</div>
                                          </div>
                                          <div className={classes.rankingCircle}>{index + 1}</div>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </div>
                    </Box>
                  )}
                </QuestionCard>
              ))}
            </div>

          </CardBody>
          <NavigationDiv>
            <div style={{ display: "flex" }}>
              {this.state.sections.map((index, currentActive) => (
                <div
                  key={currentActive}
                  className={currentActive === this.state.currentSectionIndex ? classes.activeNavigationTab : classes.inactiveNavigationTab}
                />
              ))}
            </div>
          </NavigationDiv>
        </CardWrapper>



        <ButtonWrapper>
          <div>
            <SecondaryBtn onClick={this.cancelSurvey} size="large" data-test-id="cancel-survey-button">
              Cancel
            </SecondaryBtn>
          </div>
          <RightBtnContain>
            <SecondaryBtn onClick={this.saveChanges} size="large" data-test-id="save-changes-button">
              Save changes
            </SecondaryBtn>
            {currentSectionIndex < totalSection-1 && (
              <PrimaryBtn onClick={this.nextQuestion} size="large" data-test-id="next-question-button">
                Next
              </PrimaryBtn>
            )}
            {currentSectionIndex == totalSection-1 && (
              <PrimaryBtn size="large" data-test-id="submit-survey-button" onClick={this.submitSurvey}>
                Submit
              </PrimaryBtn>
            )}
          </RightBtnContain>
        </ButtonWrapper>

        <Dialog aria-labelledby="cancel-survey-modal" open={this.state.isCancelSurveyModalOpen} fullWidth
          className={classes.cancelSurveyModal}
          PaperProps={{
            style: {
              borderRadius: "16px",
              boxShadow: "none",
              width: "500px",
              padding: "15px",
              top: 100,
              position: 'absolute' as const
            },
          }}>
          <DialogContent >
            <Typography className={classes.exitSurveyHeader} gutterBottom>
              Exit survey
            </Typography>
            {/* <Typography className={classes.exitSurveyText} gutterBottom>
              Are you sure you want to leave?<br />You haven't saved your changes
            </Typography> */}
            <SurveSubmitText>
              Are you sure you want to leave?<br />You haven't saved your changes
            </SurveSubmitText>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12} style={{ justifyContent: "center" }}>
              <div className={classes.modalbtn}>
                <Button
                  data-test-id="modal-Save-changes-button"
                  className={classes.primaryBtn}
                  onClick={this.saveChanges}>
                  Save
                </Button>
              </div>
              <div className={classes.modalbtn}>
                <Button
                  data-test-id="modal-Cancel-button"
                  className={classes.noBorderBtn}
                  onClick={this.modalCancelButton}>
                  Cancel
                </Button>
              </div>
            </Grid>
          </DialogActions>
        </Dialog>
        <Dialog aria-labelledby="cancel-survey-modal" open={this.state.isSubmitModalOpen} fullWidth
          className={classes.cancelSurveyModal}
          PaperProps={{
            style: {
              borderRadius: "16px",
              boxShadow: "none",
              width: "500px",
              padding: "15px",
              top: 100,
              position: 'absolute' as const
            },
          }}>
          <DialogContent >
            <Typography className={classes.exitSurveyHeader} gutterBottom>
              Submit response
            </Typography>
            {/* <Typography className={classes.exitSurveyText} gutterBottom>
                Are you sure you want to submit your response?
              </Typography> */}
            <SurveSubmitText>
              Are you sure you want to submit your response?
            </SurveSubmitText>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12} style={{ justifyContent: "center" }}>
              <div className={classes.modalbtn}>
                <Button
                  data-test-id="modal-submit-response-button"
                  className={classes.primaryBtn}
                  onClick={this.conformSubmitSurvey}>
                  Submit
                </Button>
              </div>
              <div className={classes.modalbtn}>
                <Button
                  data-test-id="modal-cancel-submit-button"
                  className={classes.noBorderBtn}
                  onClick={this.cancelSubmitSurvey}>
                  Cancel
                </Button>
              </div>
            </Grid>
          </DialogActions>
        </Dialog>
      </MainContain>
      // Customizable Area End
      )
    }
}
// Customizable Area Start
const StyledAlertIcon = styled(Alert)({
  backgroundColor:'white',
  color:'black',
  borderRadius:'8px',
  border:'1px solid #E2E2E2',
  '& .MuiAlert-icon':{
    color:'red'
  },
})
const SectionHeader = styled('div')({
  background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
  padding: '10px',
  fontSize: '19px',
  fontFamily: 'aeonik-regular',
  borderRadius: '8px',
  color: 'white',
  marginBottom: '30px'
})
const UserFillSurveySlider = withStyles({
  root: {
    marginTop:'20px',
    color: '#4c6db4',
    height: 8,
  },
  thumb: {
    height: 18,
    width: 18,
    backgroundColor: '#fff',
    border: '4px solid currentColor',
    marginTop: -6,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: '-33px',
    top:'-25px',
    '& *': {
      background: 'transparent',
      color: '#000',
      transform: 'none',
      width: '80px',
      borderRadius: '4px',
      textAlign:'center',
      fontSize: "18px"
    },
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);
const CustomSlider = styled(Slider)({
  color: '#4c6db3',
  height: 8,
  padding: '10px 0',
  marginTop:'20px',

  '& .MuiSlider-thumb': {
      height: 18,
      width: 18,
      backgroundColor: '#fff',
      border: '4px solid currentColor',
      marginTop: -6,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
          boxShadow: 'inherit',
      },
  },

  '& .MuiSlider-track': {
      height: 8,
      borderRadius: 4,
      
  },

  '& .MuiSlider-rail': {
      height: 8, 
      borderRadius: 4,
      
      // backgroundColor:'white',
      // opacity:'1'
  },
  '& .PrivateValueLabel-circle-66': {
      transform: 'none',
      width: '70px',
      color: 'transparent',
      borderRadius: '3px',
      position:'absolute',
      left:'-22px'
  },
  '& .PrivateValueLabel-label-67': {
      transform: 'none',
      color: 'black'
  }
});
const Header = styled(Typography)({
  padding: '20px 20px 30px 30px',
  color: 'var(--text, #202020)',
  fontSize: '24px',
  fontStyle: 'normal',
  fontFamily: 'aeonik-bold',
  fontWeight: 700,
  lineHeight: 'normal',
  borderBottom: '1px solid #F3F3F3',
  wordWrap: 'break-word',
  '@media screen and (max-width:500px)': {
    padding: '10px 10px 10px 15px',
    fontSize: '20px',
  }
})
const SurveSubmitText = styled(Typography)({
  textAlign: "center" as const,
  fontSize: "20px",
  fontWeight: 500,
  fontFamily: 'aeonik-regular',
  margin: "20px 0px 20px 0px",
  '@media screen and (max-width: 767px)': {
    fontSize: '15px',
  }

})
const NavigationDiv = styled('div')({
  // position: "absolute" as const,
  display: "flex",
  width: "100%",
  justifyContent: "center",
  // marginTop: "-14px",
  marginBottom: '20px',
  bottom: "30px",
  
  '@media screen and (max-width: 767px)': {
    transform: 'scale(0.6)',
    position: 'absolute',
    bottom: '-10px',
  }
  // backgroundColor:'red',
})
const RightBtnContain = styled('div')({
  '@media screen and (max-width: 582px)': {
    display: 'flex',
    flexDirection: 'column-reverse',
    width: '150px',
  }
})
const SecondaryBtn = styled(Button)({
  borderRadius: '10px',
  padding: '18px 32px',
  marginRight: '16px',
  border: '1px solid #1C386D',
  color: '#1C386D',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  minWidth: '180px',
  textTransform: "none",
  fontFamily: 'aeonik-bold',
  '@media screen and (max-width: 767px)': {
    padding: '12px 16px',
    minWidth: '150px',
    marginTop: '10px',
    fontSize: '14px',
  }
})
const PrimaryBtn = styled(Button)({
  borderRadius: '10px',
  background: 'var(--g, linear-gradient(180deg, #1C386D 0%, #1C386D 100%))',
  padding: '18px 32px',
  color: '#FFFFFF',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  minWidth: '180px',
  textTransform: "capitalize",
  fontFamily: 'aeonik-bold',
  '@media screen and (max-width: 767px)': {
    padding: '12px 16px',
    minWidth: '150px',
    marginTop: '10px',
    fontSize: '14px',
  }
})
const ButtonWrapper = styled('div')({
  // border: '2px solid grey',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '50px',
  '@media screen and (max-width: 500px)': {
    justifyContent: 'center',
    padding: '0px 50px'
  }
})
const MainContain = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 0',
  '@media screen and (max-width: 520px)': {
    padding: '20px 20px',
    width: '88%',
  }

})
const QuestionNumber = styled('div')({
  fontFamily: 'aeonik-regular',
  fontSize: "24px",
  fontWeight: 700,
  marginBottom: "10px",
  wordWrap: 'break-word',
  '@media screen and (max-width:500px)': {
    fontSize: '18px',
  }
})
const CardWrapper = styled('div')({
  margin: '46px 40px 40px 40px',
  background: "#fff",
  flex: "1 1 0px",
  // height: 'calc(100% - 162px)',
  borderRadius: '12px',
  // overflow: 'auto',
  // minHeight: "800px", 
  position: "relative" as const,
  // backgroundColor: 'red',
  '@media screen and (max-width: 520px)': {
    width: '100%',
    margin: '0px',
  }

})
const CardBody = styled('div')({
  padding: '36px 30px 30px 30px',
  // backgroundColor:'green',
  '@media screen and (max-width:500px)': {
    padding: '15px 15px 30px 15px',
  }
})
const QuestionCard = styled('div')({
  borderRadius: "10px",
  padding: "24px 32px",
  marginTop: "24px",
  '@media screen and (max-width:100px)': {
    marginTop: '5px',
    padding: '5px 10px',
  }
})
const SliderLabelDiv = styled('div')({
  textAlign: "center",
  fontFamily: 'aeonik-regular',
  wordWrap: 'break-word',
  '@media screen and (max-width:500px)': {
    fontSize: '14px',
  }
})
const DateWrapper = styled('div')({
  display:'flex',
  alignItems:'center',
  '@media screen and (max-width:500px)': {
    // fontSize: '14px',
    flexDirection:'column',
    alignItems:'flex-start'
  }

})
const styles = {
  card: {
    margin: '46px 40px 40px 40px',
    background: "#fff",
    flex: "1 1 0px",
    // height: 'calc(100% - 162px)',
    borderRadius: '12px',
    // overflow: 'auto',
    // minHeight: "800px", 
    position: "relative" as const,
    backgroundColor: 'red',
  },
  datepickerWrap:{
    display:'flex',
    alignItems:'center',
    '& .rmdp-week-day':{
      color:'black',
      fontWeight:600,
      fontFamily:'aeonik-regular'
    },
    '& .rmdp-arrow':{
      border: 'solid grey !important',
      borderWidth:'0px 2px 2px 0px !important'
    },
    '& .rmdp-day.rmdp-selected span:not(.highlight)':{
      backgroundColor:'#4d6fb4 !important',
      color:'white !important'
    },
    '& .rmdp-header-values':{
      fontFamily:'aeonik-regular',
      fontSize:'14px',
      marginLeft:'10px',
    },
    '& .rmdp-header':{
      // fontFamily:'aeonik-regular',
      // fontSize:'14px',
      // marginLeft:'10px',
      paddingRight:'5px',
      fontWeight:'bold' as const,
    },
    '& .rmdp-day':{
      fontFamily:'aeonik-regular',
      fontWeight:100,
    },
    '& .rmdp-day.rmdp-today span':{
      backgroundColor:"#5856B5"
    },

  },
  rotatedIcon: {
    transform: 'rotate(90deg)', // Adjust the angle as needed
    marginRight:'10px',
  },
  dateInputLabel: {
    color: "#333333",
    fontSize: "18px",
    fontFamily: 'aeonik-regular',
    fontWeight: 600,
    width: '110px'
  },
  header: {
    padding: '20px 20px 30px 30px',
    color: 'var(--text, #202020)',
    fontSize: '24px',
    fontStyle: 'normal',
    fontFamily: 'aeonik-bold',
    fontWeight: 700,
    lineHeight: 'normal',
    borderBottom: '1px solid #F3F3F3',
    wordWrap: 'break-word' as const,
  },
  body: {
    padding: '36px 30px 30px 30px'
  },
  mainBox: {
    display: "flex" as const,
    flexDirection: "column" as const,
    flex: "1 1 0px"
  },
  whiteBox: {
    minHeight: "800px",
  },
  questionNumber: {
    fontFamily: 'aeonik-regular',
    fontSize: "24px",
    fontWeight: 700,
    marginBottom: "10px",
    wordWrap: 'break-word' as const,
  },
  questionCard: {
    borderRadius: "10px",
    border: "1px solid #E7E7E7",
    padding: "24px 32px",
    marginTop: "24px",
  },
  questionCardError: {
    borderRadius: "10px",
    border: "1px solid #E34040",
    padding: "24px 32px",
    marginTop: "24px",
  },
  questionTitle: {
    fontFamily: 'aeonik-regular',
    fontSize: "18px",
    fontWeight: 500,
    marginBottom: "15px",
    wordWrap: 'break-word' as const,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0px 50px 24px 50px'
  },
  secondaryBtn: {
    borderRadius: '10px',
    padding: '18px 32px',
    marginRight: '16px',
    border: '1px solid #1C386D',
    color: '#1C386D',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    minWidth: '180px',
    textTransform: "capitalize" as const,
  },
  primaryBtn: {
    borderRadius: '10px',
    background: 'var(--g, linear-gradient(180deg, #1C386D 0%, #1C386D 100%))',
    padding: '18px 32px',
    color: '#FFFFFF',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    minWidth: '180px',
    textTransform: "capitalize" as const,
    fontFamily: 'aeonik-bold',
  },
  noBorderBtn: {
    padding: '18px 32px',
    color: '#000000',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    minWidth: '180px',
    textTransform: "capitalize" as const,
    fontFamily: 'aeonik-bold',
  },
  exitSurveyHeader: {
    textAlign: "center" as const,
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: 'aeonik-regular',
    margin: "20px 0px 20px 0px",
    textTransform: 'none' as const
  },
  exitSurveyText: {
    textAlign: "center" as const,
    fontSize: "20px",
    fontWeight: 500,
    fontFamily: 'aeonik-regular',
    margin: "20px 0px 20px 0px"
  },
  modalbtn: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: "center",
    gap: "10px",
    marginTop: "5px"
  },
  sliderBox: {
    marginTop: "20px",
  },
  sliderInput: {
    width: "100%",
  },
  sliderTextDiv: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: 'aeonik-regular',
    display: "flex",
    justifyContent: "space-between",
    gap: "50px",
  },
  sliderLabelDiv: {
    textAlign: "center" as const,
    fontFamily: 'aeonik-regular',
    wordWrap: 'break-word' as const,
  },
  radioButtonBox: {
    fontSize: "20px",
    fontWeight: 400,
    fontFamily: 'aeonik-regular',
    marginTop: "10px",
    display: "flex",
    alignItem: "center",
    marginLeft:'-6px',
    marginBottom:'20px',
  },
  radioButton: {
    marginRight: "15px",
    width: '24px',
    height: '24px',
    '@media screen and (max-width:600px)': {
      width:'auto',
      height:'auto',
      }
  },
  radioButtonLabel: {
    wordWrap: 'break-word' as const,
    fontSize:'18px'
  },
  checkboxMain: {
    fontSize: "20px",
    fontWeight: 400,
    fontFamily: 'aeonik-regular',
    marginTop: "10px",
    display: "flex",
    alignItem: "center",
    marginBottom:'20px'
    // wordWrap: 'break-word' as const,
  },
  checkboxBox: {
    marginRight: "15px",
    width: '21px',
    height: '21px',
    '@media screen and (max-width:600px)': {
    width:'auto',
    height:'auto',
    }
  },
  checkboxLabel: {
    wordWrap: 'break-word' as const,
    fontSize: '18px',
  },
  navigationDiv: {
    // position: "absolute" as const,
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginTop: "-14px",
    bottom: "30px"
  },
  activeNavigationTab: {
    margin: "2px",
    width: "60px",
    height: "0px",
    borderRadius: "8px",
    border: "5px solid #1C386D"
  },
  row: {
    display: 'flex',
    alignItems: 'center' as const,
    flexDirection: 'row' as const
  },
  rankingStatement: {
    fontFamily: 'aeonik-regular',
    fontSize: "18px",
    fontWeight: 500,
    padding: "12px",
    marginBottom: "5px",
    color: "#333333",
    backgroundColor: "#f5f6f8",
    // border: "1px solid black",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection:'row' as const,
    alignItems: "center",
    // lineBreak: "anywhere" as const
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center' as const,
    flex: 1,
    // flexWrap: 'wrap' as const
  },
  rankingCircle: {
    display:'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: '50%',
    height: '40px',
    width: '40px',
    // lineHeight: '40px',
    // textAlign: 'center' as const,
    fontFamily: 'aeonik-regular',
    fontWeight: 500,
    minWidth:'40px',
  },
  inactiveNavigationTab: {
    margin: '2px',
    width: "60px",
    height: "0px",
    borderRadius: "8px",
    border: "5px solid #BFCAE4"
  },
}

export default withStyles(styles)(UserFillSurvey)
// Customizable Area End