import React, { FunctionComponent, useState } from 'react';

import QuestionCard from './QuestionCard.web';
import Input from './CustomInput.web';
import TextArea from './CustomTextArea.web';
import { Box, makeStyles } from '@material-ui/core';

interface Props {
  onChange: (data: any) => any;
  onDeleteClick: (data: any) => any;
  data: any;
  isLiveorPastSurvey:boolean;
}
const useStyles = makeStyles({
  mainBox: {
    fontFamily: 'aeonik-regular',
    "& ::placeholder":{
        fontFamily: 'aeonik-regular',
    },
}
});
const ParagraphQuestion: FunctionComponent<Props> = ({ onChange, data, onDeleteClick,isLiveorPastSurvey }) => {
  const classes = useStyles();
  return (
    <Box className={classes.mainBox}>
    <QuestionCard
      isLiveorPastSurvey={isLiveorPastSurvey}
      title='Comment box'
      required
      data={data}
      onDeleteClick={() => onDeleteClick(data?.ind)}
      onRequiredCheck={(val) => onChange({
        ...data,
        required: val
      })}
    >
      <Input
        readOnly={isLiveorPastSurvey}
        disabled={isLiveorPastSurvey}
        maxLength={128}
        style={styles.input}
        placeholder='Add question text'
        value={data?.question_title}
        onChange={(event) =>
          onChange({
            ...data,
            question_title: event.target.value,
          })
        }
      />
      <TextArea
        readOnly={isLiveorPastSurvey}
        disabled={isLiveorPastSurvey}
        label='Comment box label'
        value={data?.question_label}
        placeholder='Add placeholder text'
        maxLength={32}
        onChange={(event) =>
          onChange({
            ...data,
            question_label: event.target.value,
          })
        }
      />
    </QuestionCard>
    </Box>
  )
};

const styles = {
  input: {
    marginBottom: '16px'
  }
}

export default ParagraphQuestion;