import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// @ts-ignore
import {publish} from '../../../components/src/updateHelper';
import React from 'react';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface Checkbox {
  id: number;
  label: string;
  checked: boolean;
}
interface Avatar {
  url: string;
}

interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  confirm_password: string;
  company: string;
  industry_sector: string;
  local_authority: string | null;
  country: string | null;
  terms_of_service: boolean;
  activated: boolean;
  created_at: string;
  updated_at: string;
  confirmed: boolean;
  confirmation_token: string | null;
  role: string;
  reset_token: string;
  signup_completed: string | null;
  avatar: Avatar;
  new_password: string | null;
  new_password_confirmation: string | null;
  admin_id: string | null;
  has_company: boolean;
  has_districts: boolean;
  has_industry_sector: boolean;
  selected_industries: string[];
  selected_districts: string[];
  is_tutorial_completed: boolean;
  admin_ids: any[];
  stripe_id: string | null;
  subscribed: boolean;
  subscription_id: string | null;
  open_api_key: string | null;
  industry_name: string | null;
  district_name: string | null;
}

interface District {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  created_by_super: boolean;
}

interface Industry {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  created_by_super: boolean;
}

interface AdminApiResponseInterface {
  user: User;
  districts: District[];
  industries: Industry[];
}
interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  profile: any


  isChanged:boolean,
  initialFirstName:string,
  initialLastName:string,
  initialCompanyName:string,
  initialIndustry:string,
  initialDistrictLocalCheck:boolean,
  initialIndustryCheck:boolean,
  initialCompanyCheck:boolean,
  currProfile:{
    FirstName:string,
    LastName:string,
    CompanyName:string,
    District:number,
  }
  currCompanyName:string,
  removedImageClicked:boolean,

  openList: boolean,
  selectedGroups: any,
  names: any,
  names1: any,
  names2: any,
  AddNew: boolean,
  defaultList: {
    Company: '',
    local: "",
    industry: "",
  },
  avtar: any,
  initials: any,
  isImageValid: boolean,
  errorMessage: any,
  ShowCompaniesList: any,
  districtsList: any,
  Edit: {
    newItemName: '',
    id: null
  },
  EditList: boolean,
  EditedItemIndex: string | null,
  Valid: boolean,
  errorOccurred: boolean,
  errorMessages: any,
  industrycheckedItems: any,
  districtCheckedItems:any
  districtlocal: boolean,
  industrySector: boolean,
  companyCheck: boolean,
  validationError: any,
  errors: any,
  snackbarOpen: boolean,
  severity: 'success' | 'info' | 'warning' | 'error' | undefined;
  imageChanged: boolean;
  termsAndConditionDialogVisible: boolean;
  isTermsAndConditionActive: boolean;
  termsData: {
    id: number | null,
    description: string,
  },
  privacyData: {
    id: number | null,
    description: string,
  },
  profilEditor:boolean,
  zoomLevel:number,
  croppedImageFile:any,
  editorRef:any,
  fullProfileImage:any,
  tabValue:number,
  countriesList:string[],
  selectedCountries:string[],
  filteredDistricts:string[],
  oldDistrictName:string,
  oldIndustryName:string,
  showDisabledFields:boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BasicSettings extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAdminDetailsCallID:string = ""
  putUpdateAdminCallID: string = ""
  getShowCompaniesCallId: string = ""
  getDistrictsCallId: string = ""
  UpdateShowCompaniesCallID: string = ""
  UpdateDistrictsCallID: string = ""
  crateDistrictsCallID: string = ""
  crateCompanyCallID: string = ""
  IndustrySectorsCallID:string =""
  districtSectorsCallID:string =""
  getTermsAndConditionCallId: string = ""
  getPrivacyPolicyCallId: string = ""
  deleteLocalAuthorityCallID:string =""
  deleteIndustrySectorCallID:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleAddNew = this.handleAddNew.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      profile: {
        id: null,
        CompanyName: '',
        EmailAddress: '',
        LastName: '',
        FirstName: '',
        District: '',
        selectedImage: null,
        checkboxState: {},
      },
      removedImageClicked:false,
      isChanged:false,
      initialFirstName:'',
      initialLastName:'',
      initialCompanyName:'',
      initialIndustry:'',
      initialDistrictLocalCheck:true,
      initialIndustryCheck:true,
      initialCompanyCheck:true,
      currProfile:{
        FirstName:'',
        LastName:'',
        CompanyName:'',
        District:0,
      },
      currCompanyName:'',
      initials: '',
      openList: false,
      AddNew: false,
      selectedGroups: 2,
      names: [
        'Oliver Hansen',
        'Van Henry',
        'April Tucker',
        'Ralph Hubbard',
        'Omar Alexander',
        'Carlos Abbott',
      ],
      names1: [
        'John',
        'Mary',
        'Michael',
        'Alice',
        'Bob',
        'Eve'
      ],
      names2: [
        'company x',
        'company y',
        'company z',
      ],
      defaultList: {
        Company: '',
        local: "",
        industry: "",
      },
      avtar: null,
      isImageValid: true,
      errorMessage: null,
      ShowCompaniesList: [],
      districtsList: [],
      Edit: {
        newItemName: '',
        id: null
      },
      EditList: false,
      EditedItemIndex: null,
      Valid: false,
      errorOccurred: false,
      errorMessages: ["Example error message"],
      industrycheckedItems:[],
      districtCheckedItems: [],
      districtlocal: false,
      industrySector: false,
      companyCheck: false,
      validationError: "",
      errors: null,
      snackbarOpen: false,
      severity: 'info',
      imageChanged: false,
      termsAndConditionDialogVisible: false,
      isTermsAndConditionActive: false,
      termsData: {
        id: null,
        description: '',
      },
      privacyData: {
        id: null,
        description: '',
      },
      profilEditor:false,
      zoomLevel:1,
      croppedImageFile:null,
      editorRef:React.createRef(),
      fullProfileImage:null,
      tabValue:0,
      countriesList:[],
      selectedCountries:[],
      filteredDistricts:[],
      oldDistrictName:'',
      oldIndustryName:'',
      showDisabledFields:false,
      // Customizable Area End
    };
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseData),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseData),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

  if (!apiRequestCallId || !responseJson || getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
    return;
  }

  const handlers = {
    [this.putUpdateAdminCallID]: this.updateAdminReciver.bind(this),
    [this.getShowCompaniesCallId]: this.getShowCompaniesReciver.bind(this),
    [this.getDistrictsCallId]: this.getDistrictsReciver.bind(this),
    [this.UpdateShowCompaniesCallID]: this.handleUpdateShowCompanies.bind(this),
    [this.UpdateDistrictsCallID]: this.handleUpdateDistricts.bind(this),
    [this.crateDistrictsCallID]: this.CreateDetailRecive.bind(this),
    [this.crateCompanyCallID]: this.CreateCompanyRecive.bind(this),
    [this.IndustrySectorsCallID]: this.IndustryDetailRecive.bind(this),
    [this.districtSectorsCallID]: this.districtDetailRecive.bind(this),
    [this.getTermsAndConditionCallId]: this.termsAndConditionRecive.bind(this),
    [this.getPrivacyPolicyCallId]: this.privacyPolicyRecive.bind(this),
    [this.getAdminDetailsCallID]: this.getAdminResponse.bind(this),
    [this.deleteIndustrySectorCallID]: this.receiveIndustrySectorDeleteResponse.bind(this),
    [this.deleteLocalAuthorityCallID]: this.receiveLocalAuthorityDeleteResponse.bind(this),
  };

  const handler = handlers[apiRequestCallId];

  if (handler) {
    handler(responseJson);
  }

  runEngine.debugLog("Message Recived", message);
    // Customizable Area End

    // Customizable Area Start
    // Customizable Area End
  }

  getCurrentUser() {
    const user = localStorage.getItem('user');

    if (user) {
      const userObj = JSON.parse(user);
      const initials =
        (userObj?.attributes?.first_name?.charAt(0)?.toUpperCase() || '-') +
        (userObj?.attributes?.last_name?.charAt(0)?.toUpperCase() || '-');

      const userData = {
        FirstName: userObj.attributes.first_name,
        LastName: userObj.attributes.last_name,
        id: userObj.id,
        EmailAddress: userObj.attributes.email,
        CompanyName: userObj.attributes.company,
        District: +userObj.attributes.industry_sector || '',
      };
      this.setState({ profile: userData, initials ,
        districtlocal: userObj?.attributes?.has_districts || false,
        industrySector: userObj?.attributes?.has_industry_sector || false,
        companyCheck: userObj?.attributes?.has_company || false
      });
    }

  }
  async componentDidMount() {
    await Promise.all([
      this.getTermsAndConditons(),
      this.getPrivacyPolicy(),
      this.getDistricts(),
      this.getShowCompanies(),
      this.getAdminDetails(),
      ]);
    }

  handleUpdateShowCompanies(responseJson: any) {
    if (responseJson.errors) {
      this.UpdateindustrySectorsReciver(responseJson);
      return;
    }
    this.setState({ Edit: { newItemName: "", id: null }, AddNew: false, openList: true }, () => {
      this.getShowCompanies();
    });
  }

  handleUpdateDistricts(responseJson: any) {
    if(responseJson.errors) {
      this.UpdateDistrictsReciver(responseJson);
      return;
    }
    this.setState({ Edit: { newItemName: "", id: null }, AddNew: false, openList: true }, () => {
      this.getDistricts();
    });
  }

  // update Admin Put Admin
  UpdateAdminDetails = async () => {
    const authToken = localStorage.getItem('token')

    const header = {
      token: authToken
    };

    let formdata = new FormData();

    if(this.state.imageChanged) {
      formdata.append("avatar",this.state.croppedImageFile);
    }
    formdata.append("first_name", this.state.profile.FirstName);
    formdata.append("last_name", this.state.profile.LastName);
    formdata.append("company", this.state.profile.CompanyName);
    formdata.append("industry_sector", this.state.profile.District);
    formdata.append("has_districts", this.state.districtlocal.toString());
    formdata.append("has_industry_sector", this.state.industrySector.toString());
    formdata.append("has_company", this.state.companyCheck.toString());

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.putUpdateAdminCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.UpdateAdminApiEndPoint + this.state.profile.id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.UpdateAdminApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  updateAdminReciver = (responseJson: any) => {
    if (responseJson.errors) {
      this.openSnackbar(responseJson.errors[0], 'error');
    } else {
      this.setState({isChanged:false});
      
      const user = {
        id: responseJson?.id,
        type: responseJson?.role,
        attributes: {
          email: responseJson?.email,
          first_name: responseJson?.first_name,
          last_name: responseJson?.last_name,
          industry_sector: responseJson?.industry_sector,
          company: responseJson?.company,
          terms_of_service: responseJson?.terms_of_service,
          activated: responseJson?.activated,
          local_authority: responseJson?.local_authority,
          has_districts: responseJson?.has_districts,
          has_industry_sector: responseJson?.has_industry_sector,
          has_company: responseJson?.has_company,
          avatar: {url: responseJson?.avatar?.url}
        }
      }
      const initials =
      (user?.attributes?.first_name?.charAt(0)?.toUpperCase() || '-') +
      (user?.attributes?.last_name?.charAt(0)?.toUpperCase() || '-');

      this.setState({ initials })

      localStorage.setItem("user", JSON.stringify(user));
      if (window) {
        window.dispatchEvent(new Event('storage'))
      }

      this.getAdminDetails();
      this.openSnackbar("Profile updated successfully", 'success');
    }
  };

  openSnackbar = (message: any, severity: any) => {
    this.setState({ snackbarOpen: true, errors: message, severity });
  };

  closeSnackbar = () => {
    this.setState({ snackbarOpen: false, errors: null });
  };

  // comany List getAPI
  getShowCompanies = () => {
    const authToken = localStorage.getItem('token')

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getShowCompaniesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.getShowCompaniesCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getShowCompaniesReciver = (responseJson: any) => {
    if (responseJson) {
      this.setState({ 
        ShowCompaniesList: responseJson.industry_sectors,
        industrycheckedItems: responseJson?.industry_sectors?.filter((el:any)=>el?.is_selected)?.map((el:any)=>el.id) });
      this.getCurrentUser()
      this.getAdminDetails();
    }
  }

  // update ShowCompanies Put Admin
  UpdateShowCompanies = async () => {
    const authToken = localStorage.getItem('token')

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };

    const { newItemName } = this.state.Edit;
    const { Edit } = this.state;
    let formData = {
      name: newItemName
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.UpdateShowCompaniesCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.UpdateShowComaniesEndPoint + Edit.id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.UpdateAdminApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // comany districts getAPI
  getDistricts = () => {
    const authToken = localStorage.getItem('token')

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDistrictsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.getDistrictsCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  filteredCounty = (countyList:any) => {
    return countyList.filter((county: string | null) => county !== null && county.trim() !== "");
  }
  getDistrictsReciver = (responseJson: any) => {
    if (responseJson) {
      this.setState({ 
        filteredDistricts:responseJson?.districts,
        countriesList:this.filteredCounty(responseJson.county_list),
        selectedCountries:this.state.countriesList,
        districtsList: responseJson?.districts,
        districtCheckedItems:responseJson?.districts?.filter((el:any)=>el?.is_selected)?.map((el:any)=>el.id) });
      this.getCurrentUser()
      this.getAdminDetails();
    }
  }

  UpdateindustrySectorsReciver = (responseJson: any) => {
    if (responseJson && responseJson.errors) {
      this.setState({
        errorOccurred: true,
        errorMessages: ['Pick a new name, this name is already taken']
      });
    }
  };

  // update Districts Put Admin
  UpdateDistricts = async () => {
    const authToken = localStorage.getItem('token');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };

    const { newItemName } = this.state.Edit;
    const { Edit } = this.state;
    let formData = {
      name: newItemName
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.UpdateDistrictsCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.UpdateDistrictsEndPoint + Edit.id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.UpdateAdminApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  };

  UpdateDistrictsReciver = (responseJson: any) => {
    if (responseJson && responseJson.errors) {
      this.setState({
        errorOccurred: true,
        errorMessages: ['Pick a new name, this name is already taken']
      });
    }
  };

  // createDistricts Post Api
  CreateDistrictsDetails = () => {
    const authToken = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };

    let formData = {
      name: this.state.Edit.newItemName
    };

    const requestMessageDistricts = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageDistricts.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageDistricts.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.PostDistrictsEndPoint
    );
    requestMessageDistricts.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessageDistricts.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formData)
    );

    this.crateDistrictsCallID = requestMessageDistricts.messageId;

    runEngine.sendMessage(requestMessageDistricts.id, requestMessageDistricts);
  }

  CreateDetailRecive = (responseJson: any) => {
    if (responseJson.errors) {
      this.setState({
        errorOccurred: true,
        errorMessages: ['Pick a new name, this name is already taken']
      });
      return;
    }
    this.setState({ AddNew: false, openList: true })
    this.getDistricts()
  }

  // createIndustry Post Api
  CreateCompanyDetails = () => {
    const authToken = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };

    let formData = {
      name: this.state.Edit.newItemName
    };

    const requestMessageCompany = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageCompany.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageCompany.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.PostShowIndusSectors
    );
    requestMessageCompany.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessageCompany.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formData)
    );

    this.crateCompanyCallID = requestMessageCompany.messageId;

    runEngine.sendMessage(requestMessageCompany.id, requestMessageCompany);
  }

  CreateCompanyRecive = (responseJson: any) => {
    if (responseJson.errors) {
      this.setState({
        errorOccurred: true,
        errorMessages: ['Pick a new name, this name is already taken']
      });
      return;
    }
    this.setState({ AddNew: false, openList: true })
    this.getShowCompanies()
  }

  // industry_sectors POst Api
  selectedIndustryDetails = () => {
    const authToken = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };

    let formData = {
      selected_sector_ids: this.state.industrycheckedItems,
      is_selected:this.state.industrycheckedItems
    };

    const requestMessageDistricts = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageDistricts.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageDistricts.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.industrySectorsSelectedEndPoint
    );
    requestMessageDistricts.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessageDistricts.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formData)
    );

    this.IndustrySectorsCallID = requestMessageDistricts.messageId;

    runEngine.sendMessage(requestMessageDistricts.id, requestMessageDistricts);
  }

  IndustryDetailRecive = (responseJson: any) => {
   this.setState({ openList: false });
  }

// districts Post Api
  selectedDistrictsDetails = () => {
    const authToken = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };

    let formData = {
      selected_district_ids: this.state.districtCheckedItems,
      is_selected : this.state.districtCheckedItems
    };

    const requestMessageDistricts = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageDistricts.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageDistricts.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.districtSectorsEndPoint
    );
    requestMessageDistricts.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessageDistricts.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formData)
    );

    this.districtSectorsCallID = requestMessageDistricts.messageId;

    runEngine.sendMessage(requestMessageDistricts.id, requestMessageDistricts);
  }

  districtDetailRecive = (responseJson: any) => {
   this.setState({ openList: false });
  }

  

  handleChangeCheckbox = (event: any, checkboxName: any) => {
    this.setState({ [checkboxName]: event.target.checked } as any,this.checkForChanges);
  };
  handleSave = () => {
    this.selectedIndustryDetails();
    this.selectedDistrictsDetails();
  }
  handleCheck = (id:any) => {
    if (this.state.selectedGroups === 1) {
      this.setState((prevState) => ({
        industrycheckedItems: prevState.industrycheckedItems.includes(id)
          ? prevState.industrycheckedItems.filter((itemId: any) => itemId !== id)
          : [...prevState.industrycheckedItems, id],
      }));
    } else if (this.state.selectedGroups === 2) {
      this.setState((prevState) => ({
        districtCheckedItems: prevState.districtCheckedItems.includes(id)
          ? prevState.districtCheckedItems.filter((itemId:any) => itemId !== id)
          : [...prevState.districtCheckedItems, id],
      }));
    }
  };
  getAdminDetails = () => {
    const authToken = localStorage.getItem('token')
    const user = localStorage.getItem('user');
    const userId = (user && user !== "undefined") ? JSON.parse(user).id : null;
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: authToken
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_user/users/edit_admin?id=${userId}`
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    this.getAdminDetailsCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAdminResponse = (response: AdminApiResponseInterface) => {
    if (response) {
      const user = {
        first_name: response.user.first_name,
        last_name: response.user.last_name,
        company: response.user.company || "",
      };
      this.setState((prevState) => ({
        profile: {
          ...prevState.profile,
          FirstName: user.first_name,
          LastName: user.last_name,
          CompanyName:user.company,
        },
      }));
      this.setState({
        profile: {
          ...this.state.profile,
          selectedImage: response.user.avatar.url,
        },
        fullProfileImage:response.user.avatar.url,
      });
      this.setState((prevState) => ({
        currProfile: {
          ...prevState.currProfile,
          FirstName: user.first_name,
          LastName: user.last_name,
          CompanyName:user.company,
        },
      }));
      this.setState({initialFirstName:response.user.first_name});
      this.setState({initialLastName:response.user.last_name});
      this.setState({initialCompanyName:response.user.company});
      this.setState({initialIndustry:response.user.industry_sector});
      this.setState({initialDistrictLocalCheck:response.user.has_districts});
      this.setState({initialIndustryCheck:response.user.has_industry_sector});
      this.setState({initialCompanyCheck:response.user.has_company});
    }
    
  }
  handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      profile: {
        ...prevState.profile,
        [name]: value,
      },
    }),this.checkForChanges);
    this.setState((prevState) => ({
      currProfile: {
        ...prevState.currProfile,
        [name]: value,
      },
    }),this.checkForChanges);
  }
  checkForChanges = () => {
    const firstNameChange = this.state.initialFirstName !== this.state.currProfile.FirstName;
    const lastNamechange = this.state.initialLastName !== this.state.currProfile.LastName;
    const companyNamechage = this.state.initialCompanyName !== this.state.currProfile.CompanyName;
    const industryChange = Number(this.state.initialIndustry) !== this.state.profile.District;
    const DistrictCheckChange = this.state.initialDistrictLocalCheck !== this.state.districtlocal;
    const IndustryCheckChange = this.state.initialIndustryCheck !== this.state.industrySector;
    const CompanyCheck = this.state.initialCompanyCheck !== this.state.companyCheck;
    
    if(this.state.imageChanged ||firstNameChange || lastNamechange || companyNamechage || DistrictCheckChange || IndustryCheckChange || CompanyCheck || industryChange)
    {
      this.setState({isChanged:true})
    }else{
      this.setState({isChanged:false});
    }
  }

  

  removeProfilePicture = (ev: any) => {
    ev.preventDefault()
    ev.stopPropagation()
    this.setState({
      profile: {
        ...this.state.profile,
        selectedImage: null,
      },
      imageChanged: true,
      avtar: null,
      croppedImageFile:null,
    },this.checkForChanges);
    
  }
  handleImageChange = (event: any) => {
    const file = event.target.files?.[0];
    this.setState({
      avtar: file,
      errorMessage: '',
      imageChanged: true,
      croppedImageFile:file,
    },this.checkForChanges);

    if (file) {
      const fileSizeMB = file.size / (1024 * 1024);
      const supportedFormats = ['image/jpeg', 'image/png', 'image/gif'];

      if (fileSizeMB <= 5 && supportedFormats.includes(file.type)) {
        this.setState({fullProfileImage:URL.createObjectURL(file)});
        this.setState((prevState) => ({
          profile: {
            ...prevState.profile,
            selectedImage: URL.createObjectURL(file),
          },
          isImageValid: true,
          profilEditor: true,
        }));
      } else {
        this.setState({
          isImageValid: false,
          errorMessage: 'Invalid file format or size. Please select a valid image (PNG, JPEG, GIF) within 5MB.',
        });
      }
    }
  };
  handleSnackbarClose = () => {
    this.setState({ errorOccurred: false });
  };

  handleClickOpen = () => {
    this.setState({ openList: true, AddNew: false });
  };

  handleClose = () => {
    this.setState({
      openList: false, AddNew: false, Edit: {
        newItemName: '',
        id: null,
      },
      EditedItemIndex: null
    });
  };

  handleCloseAddNew = () => {
    this.setState({
      AddNew: false, openList: true, Edit: {
        newItemName: '',
        id: null,
      },
      EditedItemIndex: null
    });
  }

  handleAddNew = () => {
    this.setState({showDisabledFields:false});
    this.setState({ AddNew: true, openList: false ,Edit: {
      newItemName: '',
      id: null,
    },});
  };

  handleEdit = (item: any,type:string) => {
    if(type === 'local')
    {
      this.setState({oldDistrictName:item.name});
    }
    if(type === 'industry')
    {
      this.setState({oldIndustryName:item.name});
    }
    this.setState({
      Edit: {
        newItemName: item.name || '',
        id: item.id || null,
      },
      EditedItemIndex: item.index || null,
      AddNew: true,
      openList: false,
      showDisabledFields:true,
    })
  };

  handleGroupSelect = (event: any) => {
    const selectedGroups = event.target.value;
    this.setState({ selectedGroups });
  };

  handleSaveChanges = async () => {
    this.UpdateAdminDetails();
    publish('dataUpdated', this.state.profile);
  }

  handleNewItemNameChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      Edit: {
        ...prevState.Edit,
        [name]: value,
      },
    }));
  };

  createList = async () => {
    if (this.state.Edit.newItemName === "") {
      this.setState({ errorOccurred: true, errorMessages: ['Please fill the field']})
      return
    }

    if (this.state.selectedGroups === 1) {
      if (this.state.Edit.id) {
        this.UpdateShowCompanies();
      } else {
        this.CreateCompanyDetails();
      }
    } else if (this.state.selectedGroups === 2) {
      if (this.state.Edit.id) {
        this.UpdateDistricts()
      } else {
        this.CreateDistrictsDetails();
      }
    }
    // this.setState({ AddNew: false, openList: true })
  };
  // Customizable Area Start

  renderTermsAndPrivacyData = () => {
    if (this.state.isTermsAndConditionActive) {
      return {
        title: "Terms and conditions",
        data: this.state.termsData
      }
    }
    return {
      title: "Privacy policy",
      data: this.state.privacyData
    }
  }

  handleOpenTermsAndConditionDialog = () => {
    this.setState({ termsAndConditionDialogVisible: true, isTermsAndConditionActive: true });
  }

  handleOpenPrivacyPolicyDialog = () => {
    this.setState({ termsAndConditionDialogVisible: true, isTermsAndConditionActive: false });
  }

  handleCloseTermsAndCondtionDioalg = () => {
    this.setState({ termsAndConditionDialogVisible: false });
  }

  termsAndConditionRecive(responseJson: any) {
    if (responseJson) {
      const { id, description } = responseJson;
      this.setState({
        termsData: {
          id, description
        }
      });
    }
  }

  privacyPolicyRecive(responseJson: any) {
    if (responseJson) {
      const { id, description } = responseJson;
      this.setState({
        privacyData: {
          id, description
        }
      });
    }
  }

  getTermsAndConditons = () => {
    const authToken = localStorage.getItem('token')

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermsAndConditions
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.getTermsAndConditionCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPrivacyPolicy = () => {
    const authToken = localStorage.getItem('token')

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPrivacyPolicy
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.getPrivacyPolicyCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  showProfileEditor = () => {
    this.setState({profilEditor:true});
  }
  handleZoomChange = (event:any, newValue:any) => {
    this.setState({ zoomLevel: newValue });
  };
  handleProfileEditorClose = () => {
    this.setState({profilEditor:false});
  }
  handleApplyProfileEditor = async () => {
    if (this.state.editorRef.current) {
      const canvas = this.state.editorRef.current.getImageScaledToCanvas();
      
      // Function to generate a random string
      const generateRandomString = (length=5) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
      };

      const randomFileName = generateRandomString(10); // Adjust the length as needed

      canvas.toBlob((blob: any) => {
        if (blob) {
          const file = new File([blob], `${randomFileName}.png`, { type: 'image/jpeg' });
          this.setState({
            profile: {
              ...this.state.profile,
              selectedImage: URL.createObjectURL(blob)
            },
            profilEditor: false
          })
          this.setState({ croppedImageFile: file });
          this.setState({ imageChanged: true }, () => this.checkForChanges());
        }
      }, 'image/png');
      
    }
  };
  handleCountryChange = (event: any) => {
    const selectedCountries = event.target.value;

    const filteredDistricts = selectedCountries.length > 0
      ? this.state.districtsList.filter((district: any) =>
        !district.county || selectedCountries.includes(district.county))
      : this.state.districtsList.filter((district: any) => !district.county);

    this.setState({
      selectedCountries,
      filteredDistricts
    });
  };
  handleTabChange = (event:any,newValue:number) => {
    this.setState({tabValue:newValue})
    if(newValue === 0)
    {
      this.setState({selectedGroups:2})
    }
    if(newValue === 1)
    {
      this.setState({selectedGroups:1});
    }
  }
  deleteIndustrySector = (industryId:number) => {
    const authToken = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_user/users/delete_industry_sector?id=${industryId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    this.deleteIndustrySectorCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  receiveIndustrySectorDeleteResponse = (response: any) => {
    if (response.message === 'Deleted successfully') {
      this.getShowCompanies();
    }
  }
  deleteLocalAuthority = (localAuthorityID:number) => {
    const authToken = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_user/users/delete_district?id=${localAuthorityID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    this.deleteLocalAuthorityCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  receiveLocalAuthorityDeleteResponse = (response: any) => {
    if (response.message === 'Deleted successfully') {
      this.getDistricts();
    }
  }

  // Customizable Area End
}
