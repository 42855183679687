// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import moment from "moment";
import { promises } from "dns";
import { cursorTo } from "readline";
import QuestionCard from "../../../components/src/QuestionCard.web";
import React from "react";
import { DraggableLocation, DropResult, Combine } from 'react-beautiful-dnd';

export const configJSON = require("./config");


export interface Props {
    navigation: any;
    id: string;
    classes: any;
}

interface S {

    header: string;
    currentQuestion: number;
    questionDetails: any;
    totalQuestion: number;
    questionsList: any[];
    isCancelSurveyModalOpen: boolean;
    isSubmitModalOpen: boolean;
    error: boolean;
    errorDate: { [questionId: number]: string };
    birthDates: { [questionId: number]: string  };
    QuestionId:number;
    errorMessage: string;
    surveyId: number;
    success: boolean;
    successMessage: string;

  totalSection: number,
  currentSectionHeader: string,
  sections: Section[],
  currentSectionIndex: number,
  shortQuestionError: boolean,
  longQuestionError:boolean,
  surveyTitle: string,
  initialRankingOrder: number[],
  timeTaken: number,
  answers: Answer[],
  isChangesSaved:boolean,
  intervalId:any
  datePickerRef:any;
  hasHeader:boolean[];
  calenderFocus:boolean;
  dateAnswer:string;
  dateUpdateAnswer:Date|null
  initialAnswers: any[]
  calenderOpen:boolean;
}

interface DateChangeEvent {
  target: {
    value: string; // Assuming formattedDate is of type string
    checked:boolean 
  };
}
interface DragDropEvent {
  draggableId: string;
  type: string;
  source: {
    index: number;
    droppableId: string;
  };
  reason: string;
  mode: string;
  destination: DraggableLocation | null | undefined;
  combine: Combine | null | undefined; // Allow undefined as well
}


interface SurveyQuestion {
  id: number;
  question_type: string;
  question_title: string;
  survey_id: number;
  require: boolean;
  order_number: number;
  slider_answer_options:Option[]
  options: Option[]; // Since options can be an array of any type
  answer: string | string[] | number[] ; // Answer can be a string, an array of strings, or an array of numbers
  error:boolean
  filter:() => {}
}
interface Option {
  id: number;
  name: string;
  question_id: number;
  created_at: string;
  updated_at: string;
}
interface Answer {
  question_id: string | number;
  question_type: string;
  required: boolean;
  answer: string | number | string[] | number[] | null;
}
interface Section {
  header: string;
  questions: SurveyQuestion[];
}

interface SS {
    id: any;
}
// Customizable Area End

export default class UserFillSurveyController extends BlockComponent<
    Props,
    S,
    SS
> {
    getSurveyQuestionsCallId: string = ""
    submitSurveyResponseCallId: string = ""

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.state = {

            header: "",
            currentQuestion: 0,
            totalQuestion: 0,
            error: false,
            errorDate: {},
            birthDates: {},
            QuestionId:0,
            errorMessage: "",
            surveyId: 0,
            questionDetails: {},
            questionsList: [],
            isCancelSurveyModalOpen: false,
            isSubmitModalOpen: false,
            success: false,
            successMessage: "",

          totalSection: 0,
          currentSectionHeader: '',
          sections: [],
          currentSectionIndex: 0,
          shortQuestionError: false,
          longQuestionError:false,
          surveyTitle: '',
          initialRankingOrder: [],
          timeTaken: 0,
          answers: [],
          isChangesSaved:false,
          intervalId:null,
          datePickerRef:React.createRef(),
          hasHeader:[],
          calenderFocus:false,
          dateAnswer:'',
          dateUpdateAnswer:null,
          initialAnswers: [],
          calenderOpen:false,
        }
        // Customizable Area End


        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
            return;
        }

        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));


        if (!apiRequestCallId || !responseJson) {
            return;
        }
        if (apiRequestCallId === this.getSurveyQuestionsCallId && responseJson) {
            this.getSurveyQuestionsReceive(responseJson)
        }
        if (apiRequestCallId === this.submitSurveyResponseCallId && responseJson) {
            this.submitSurveyReceive(responseJson)
        }
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        await Promise.all([this.getSurveyQuestions()])
    }

    
    
    getSurveyQuestions = () => {
        const authToken = localStorage.getItem('token')

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: authToken
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getSurveyQuestionsApiEndPoint + `/${this.props.navigation.getParam("surveyId")}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiGetMethodType
        );

        this.getSurveyQuestionsCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSurveyQuestionsReceive = (responseJson: any) => {
      if (responseJson?.data) {
        const initialAnswers = responseJson.data.attributes.sections
        .flatMap((section: { questions: any; }) => section.questions)
        .map((question: { id: any; answer: any; }) => ({
            question_id: question.id,
            answer: question.answer || null
        }));
        this.setState({
          initialAnswers: initialAnswers
      });
        this.startTimer();
        const sectionsArray = responseJson.data.attributes.sections;
        const totalSection = sectionsArray.length
        const survey_id = responseJson.data.id;
        this.setState({ sections: sectionsArray });
        this.setState({ totalSection: totalSection });
        this.setState({ surveyTitle: responseJson.data.attributes.name })
        this.setState({ surveyId: survey_id })
        const hasHeaderArray = sectionsArray.map((item:any) => item.header.trim() !== "");
        this.setState({hasHeader:hasHeaderArray});
        let originalRankingOrder: number[] = [];
        sectionsArray.forEach((section: Section) => {
          section.questions.forEach((question: SurveyQuestion) => {
            if (question.question_type === "ranking") {
              question.options.forEach((option: Option) => {
                originalRankingOrder.push(option.id);
              });
            }
          });
        });
        sectionsArray.forEach((section:Section) => {
          section.questions.forEach((question:any) => {
            if (question.question_type === "ranking" && question.slider_answer_options) {
              question.options = question.slider_answer_options.map((option:Option) => ({
                id: option.id,
                name: option.name,
              }));
            }
          });
        });
        this.setState({ initialRankingOrder: originalRankingOrder });
        }
        if (responseJson.errors) {
            this.props.navigation.navigate("SurveysWeb")
        }
    }
    startTimer = () => {
      let intervalId = setInterval(() => {
        this.setState(prevState => ({
          timeTaken: prevState.timeTaken + 1
        }));
      }, 1000);
      this.setState({intervalId:intervalId})
    }
    stopTimer = () => {
      if (this.state.intervalId) {
        clearInterval(this.state.intervalId);
        this.setState({intervalId:null})
      }
    }

    submitSurveyReceive = (responseJson: any) => {
        if (responseJson?.message) {
            if (this.state.isSubmitModalOpen) {
                this.props.navigation.navigate("SurveyReport", { surveyId: this.state.surveyId })
            } else if (this.state.isCancelSurveyModalOpen) {
                this.props.navigation.navigate("SurveysWeb")
            } else {
                this.setState({ success: true, successMessage: "Changes saved successfully",timeTaken:0,isChangesSaved:true })
                this.stopTimer();
            }
        } else if (responseJson.errors) {
            this.setState({ error: true, errorMessage: responseJson.errors[0] })
        }
    }

    submitSurveyResponse = (data: any) => {
        const authToken = localStorage.getItem('token')

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: authToken
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify({ data })
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postSubmitSurveyResponseApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiPostMethodType
        );

        this.submitSurveyResponseCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // cancelSurvey = () => this.setState({ isCancelSurveyModalOpen: true });
    cancelSurvey = () => {
      this.setState ((prevState)=>{
        const currentAnswers = prevState.sections
        .flatMap(section => section.questions)
        .map(question => ({
            question_id: question.id,
            answer: question.answer || null
        }));
        const hasUnsavedChanges = prevState.initialAnswers.some((initialAnswer) => {
          const currentAnswer = currentAnswers.find(answer => answer.question_id === initialAnswer.question_id);
          return currentAnswer && currentAnswer.answer !== initialAnswer.answer;
      });
      if (hasUnsavedChanges) {
        return { isCancelSurveyModalOpen: true };
    } else {
        this.stopTimer();
        this.setState({ currentSectionIndex: 1 });
        const msg = new Message(getName(MessageEnum.NavigationSurveyCatalog));
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
        this.props.navigation.navigate('SurveysWeb');
        return null;
    }
      })
    }

  isAnswerEmpty = (question: any) => {
    if (question.question_type === 'ranking') {
      return (
        question.answer === undefined ||
        question.answer === null ||
        question.answer.every((value: string|number, index: number) => value === this.state.initialRankingOrder[index])
      );
    }
    if (question.question_type === 'short-text' && this.state.shortQuestionError) {
      return true;
    }
    if (question.question_type === 'long-text' && this.state.longQuestionError) {
      return true;
    }

    return (
      question.answer === undefined ||
      question.answer === null ||
      (typeof question.answer === 'string' && question.answer.trim() === '') ||
      (Array.isArray(question.answer) && question.answer.length === 0)
    );
  };

    nextQuestion = () => {
      const currentSectionIndex = this.state.currentSectionIndex;
      const currentSection = this.state.sections[currentSectionIndex];
      const unansweredRequiredQuestions = currentSection.questions.filter(
        (question: SurveyQuestion) => question.require && this.isAnswerEmpty(question)
      );
      const exceed  = currentSection.questions.filter(
        (question: SurveyQuestion) => question && this.exceedLimitCheck(question)
      );
      if (unansweredRequiredQuestions.length > 0 || exceed.length > 0) {
        this.setState({ errorMessage: "Please provide an answer to a mandatory question" })
        if (this.state.error) {
          this.setState({error:false})
          setTimeout(() => {
            this.setState({error:true})
          }, 100); 
        } else {
          this.setState({error:true})
        }
      } else {
        this.setState({ error: false })
        this.setState({ currentSectionIndex: this.state.currentSectionIndex + 1 });
        window.scrollTo({
          top: 0,
          behavior: 'auto'
        });
      }
    }

    modalCancelButton = () => this.setState({ isCancelSurveyModalOpen: false });

    saveChanges = () => {
      const answersExtract: Answer[] = [];
      this.state.sections.forEach((section) => {
        section.questions.forEach((question:SurveyQuestion) => {
          let obj = {
            question_id: question.id,
            question_type: question.question_type,
            answer: question.answer || null,
            required: question.require,
            error:question?.error 
          }
          answersExtract.push(obj);
        })
      })
      
      const currentTime = new Date();
      const formattedTime = `${('0' + currentTime.getHours()).slice(-2)}:${('0' + currentTime.getMinutes()).slice(-2)}`;
      const currentDate = currentTime.toISOString().split('T')[0];
      const submitted_time = formattedTime;
      const submitted_date = currentDate;

        const data = {
            survey_id: this.state.surveyId,
            saves: true,
            submit: false,
            answers:answersExtract,
            time_taken:this.state.timeTaken,
            submitted_time:submitted_time,
            submitted_date:submitted_date,
        };
        this.submitSurveyResponse(data);
    }
  exceedLimitCheck = (question: any) => {
    
    if (question.question_type === 'short-text') {
      return typeof(question.answer) === 'string' && question.answer.length > 128
    }
    if (question.question_type === 'long-text') {
      return typeof(question.answer) === 'string' && question.answer.length > 512
    }if (question.question_type === 'date') {
     return question.error;
    }
  }
    submitSurvey = () => {
      const currentSectionIndexSubmit = this.state.currentSectionIndex;
      const currentSection = this.state.sections[currentSectionIndexSubmit];
      const unansweredRequiredQuestionsSubmit = currentSection.questions.filter(
        (question: SurveyQuestion) => question.require && this.isAnswerEmpty(question)
      );
      const exceedSubmit  = currentSection.questions.filter(
        (question: SurveyQuestion) => question && this.exceedLimitCheck(question)
      );
      if (unansweredRequiredQuestionsSubmit.length > 0 || exceedSubmit.length > 0 ) {
        this.setState({ errorMessage: "This question is mandatory, please provide an answer",error:true })
        if (this.state.error) {
          this.setState({error:false})
          setTimeout(() => {
            this.setState({error:true})
          }, 100); 
        } else {
          this.setState({error:true})
        }
      } else {
        this.setState({ error: false })
        this.setState({ isSubmitModalOpen: true });
      }
  
    }

    cancelSubmitSurvey = () => this.setState({ isSubmitModalOpen: false });

    conformSubmitSurvey = () => {
      const answersExtract: Answer[] = [];
      this.state.sections.forEach((section) => {
        section.questions.forEach((question:SurveyQuestion) => {
          let obj = {
            question_id: question.id,
            question_type: question.question_type,
            answer: question.answer || null,
            required: question.require,
          }
          answersExtract.push(obj);
        })
      })
      const currentTime = new Date();
      const formattedTime = `${('0' + currentTime.getHours()).slice(-2)}:${('0' + currentTime.getMinutes()).slice(-2)}`;
      const currentDate = currentTime.toISOString().split('T')[0];
      const submitted_time = formattedTime;
      const submitted_date = currentDate;

        const data = {
            survey_id: this.state.surveyId,
            saves: false,
            submit: true,
            answers:answersExtract,
            time_taken:this.state.timeTaken,
            submitted_time:submitted_time,
            submitted_date:submitted_date,
        };

        this.submitSurveyResponse(data);
    }



  handleDragDrop = (result:DragDropEvent, questionDetails:SurveyQuestion) => {
    const { destination, source } = result;
    if (!destination || destination.index === source.index) {
      return;
    }
  
    const draggedItemId = parseInt(result.draggableId.replace("dragid", ""));
    // const updatedOptions = [...this.state.sections[this.state.currentSectionIndex].questions.find((question:SurveyQuestion) => question.id === questionDetails.id).options];
    const currentSection = this.state.sections[this.state.currentSectionIndex];
    if (!currentSection) return;
  
    const question = currentSection.questions.find((question: SurveyQuestion) => question.id === questionDetails.id);
    if (!question) return;
  
    const updatedOptions = [...question.options];


    const [removedItem] = updatedOptions.splice(source.index, 1);
  
    updatedOptions.splice(destination.index, 0, removedItem);
  
    const optionIdsInNewOrder = updatedOptions.map(option => option.id);
    const updatedSections = this.state.sections.map((section) => {
      const updatedQuestions = section.questions.map((question:SurveyQuestion) => {
        if (question.id === questionDetails.id) {
          question.options = updatedOptions;
          question.answer = optionIdsInNewOrder; 
        }
        return question;
      });
      return { ...section, questions: updatedQuestions };
    });
  
    this.setState({ sections: updatedSections });
  };

  onChangeValue = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | DateChangeEvent, questionId: number,questionType:string) => {
    const { value } = event.target;
    let error = false;
    if(questionType === 'short-text')
    {
      this.setState({shortQuestionError: value.length > 128})
    }
    if(questionType === 'long-text')
    {
      this.setState({longQuestionError: value.length > 512})
    }
    
    if (questionType === "short-text" && value.length > 128) {
      error = true;
    } else if (questionType === "long-text" && value.length > 512) {
      error = true;
    }
    this.updateAnswer(questionId, value, error,false);
  };
  onChangeRadioValue = (event: React.ChangeEvent<HTMLInputElement>, questionId: number) => {
    const { value } = event.target;
    this.updateAnswer(questionId, value,false,false);
  };
  onChangeCheckValue = (event: React.ChangeEvent<HTMLInputElement> | DateChangeEvent, questionId: number) => {
    const { value, checked } = event.target;
    this.updateAnswer(questionId, value,false, checked);
  };
  onChangeSliderValue = (event: React.ChangeEvent<{}>,newValue:number|number[], questionId: number) => {
    this.updateAnswer(questionId, newValue,false,false);
  };
 
  onChangeDate = (date: any, event:any) => {
    
    let inputDate = event.input.value;
    const Qid = this.state.QuestionId;
    this.updateAnswer(Qid, null, false, false);
        let error = false;
        if (/^\d{8}$/.test(inputDate)) {
          inputDate = `${inputDate.slice(0, 2)}/${inputDate.slice(2, 4)}/${inputDate.slice(4)}`;
        }
         const calendarDate = event.validatedValue[event.validatedValue.length - 1];

        if (!event.isTyping) {
        this.setState(prevState => ({
        errorDate: {
          ...prevState.errorDate,
          [Qid]: '' 
        },
        birthDates: {
          ...prevState.birthDates,
          [Qid]: calendarDate 
        }
        }));
        this.updateAnswer(Qid, calendarDate, false, false);
        return;
      }
      const nonNumericRegex = /[^0-9/]/;
      if(nonNumericRegex.test(inputDate)){
        error = true;
        this.updateAnswer(Qid, null, error, false);
 
         this.setState(prevState => ({
         errorDate: {
           ...prevState.errorDate,
           [Qid]: 'Please enter a valid date' 
         },
         birthDates: {
           ...prevState.birthDates,
           [Qid]: inputDate 
         }
         }));
         return;
      }

      const digitCount = inputDate.replace(/[^0-9]/g, "").length;
      if (digitCount > 8) {
          error = true;
          this.updateAnswer(Qid, null, error, false);
  
          this.setState(prevState => ({
              errorDate: {
                  ...prevState.errorDate,
                  [Qid]: 'Please enter a valid date'
              },
              birthDates: {
                  ...prevState.birthDates,
                  [Qid]: inputDate
              }
          }));
          return;
      }

      const parts = inputDate.split('/');
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10);
      const year = parseInt(parts[2], 10);
      const selectedDate = new Date(year, month - 1, day);
    
      if (month < 1 || month > 12) {
        error = true;
       this.updateAnswer(Qid, null, error, false);
        this.setState(prevState => ({
        errorDate: {
          ...prevState.errorDate,
          [Qid]: 'Please enter a valid date' 
        },
        birthDates: {
          ...prevState.birthDates,
          [Qid]: inputDate 
        }
        }));
        return;
      }
      if (day < 1 || day > new Date(year, month, 0).getDate()) {
        error = true;
       this.updateAnswer(Qid, null, error, false);

        this.setState(prevState => ({
        errorDate: {
          ...prevState.errorDate,
          [Qid]: 'Please enter a valid date' 
        },
        }));
        return;
      }

      if (inputDate.length <= 8) {
        error = true;
        this.updateAnswer(Qid, null, error, false);
         this.setState(prevState => ({
         errorDate: {
           ...prevState.errorDate,
           [Qid]: 'Please enter a valid date' 
         },
         birthDates: {
          ...prevState.birthDates,
          [Qid]: inputDate 
        }
         }));
         return;
      }
      
      this.setState(prevState => ({
        errorDate: {
        ...prevState.errorDate,
        [Qid]: ''
        },
        birthDates: {
        ...prevState.birthDates,
        [Qid]: selectedDate.toString()
        }
      }));
    
   this.updateAnswer(Qid, inputDate, error, false);
   }
   
   handleMouseEnter = (id:number, answer:Date) => {
   if (answer) {
    this.setState(prevState => ({
      errorDate: {
        ...prevState.errorDate,
        [id]: ''
      },
      birthDates: {
        ...prevState.birthDates,
        [id]: answer.toString()
      }
    }));
   }
    this.updateAnswer(id, null, false, false);
  };

  handleMouseLeave = (id: number) => {
    
    if (this.state.calenderOpen) {
      return;
    }
    const currDate = this.state.birthDates[id];
    const error = this.state.errorDate[id]; 
  
    if (error) {
      this.updateAnswer(id, null, true, false);
    } else if (currDate) {
      let formattedDate;
      const regexDDMMYYYY = /^\d{2}\/\d{2}\/\d{4}$/;
      if (regexDDMMYYYY.test(currDate)) {
        formattedDate = currDate;
      } else {
        const date = new Date(currDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); 
        const year = date.getFullYear();
        formattedDate = `${day}/${month}/${year}`;
      }
      this.updateAnswer(id, formattedDate, false, false);
    }
  };
  
  handleID = (id:number)=>{
    this.setState({QuestionId:id});
  }

  updateAnswer = (questionId:number, value:any, error = false, checked :boolean) => {
  
    const updatedSections = this.state.sections.map((section) => {
    const updatedQuestions = section.questions.map((question:any) => {
      
    if (question.id === questionId) {
    if (question.question_type === "checkbox") {
    const answers = question.answer || [];
    question.answer = checked ? [...answers, value] : answers.filter((answer:Answer) => answer !== value);
    } else {
    question.answer = value;
    }
    question.error = error;
    }
    return question;
    });
    return { ...section, questions: updatedQuestions };
    });
    // this.createAnswerData();
    
    this.setState({ sections: updatedSections });
    
    };
    handlecheckboxfilled = () => {
      let check2 = true;
      if (check2) {
       this.handleunchecked()
      }
    }
    handleunchecked = () => {
      let check1 = false
      if (!check1) {
       this.handleSubmits()
      }
    }
    handleSubmits = ()=>{
      let assign = true
      if(assign){
        this.handleCalaneder()
      }
    }
    handleCalaneder = ()=>{
      let assign = true
      if(assign){
       return;
      }
    }
    handleSnackbarClose = () => this.setState({ error: false, success: false,});
    // Customizable Area End
}
