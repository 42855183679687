import React from "react";

import {
    Button,
    InputLabel,
    Typography,
    // Customizable Area Start
    TextField,
    Select,
    styled,
    Checkbox,
    MenuItem,
    FormHelperText,
    FormControl,
    Snackbar
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import TermsAndPrivacyDialog from "../../../components/src/TermsAndPrivacyDialog.web";
import AlertIcon from '@material-ui/icons/ErrorOutline';
import { ErrorIcon } from "../../email-account-login/src/assets";
import { Alert } from "@material-ui/lab";
// Customizable Area End

import CreateUserAccountController, {
    Props,
} from "./CreateUserAccountController.web";

export default class CreateUserAccount extends CreateUserAccountController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { snackbar } = this.state;
        const checkboxStyle = {
            borderRadius: '2px',
            width: this.state.agreed ? '20px' : '19px',
            height: this.state.agreed ? '20px' : '19px',
            border: this.state.agreed ? 'none' : '1px solid grey',
            display: 'inline-block' as const,
            position: 'relative' as const,
            cursor: 'pointer' as const,
            background: this.state.agreed ? 'linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)' : 'transparent' as const,

        };

        const checkmarkStyle = {
            content: '""',
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            width: '6px',
            height: '12px',
            border: 'solid white',
            borderWidth: '0 2.5px 2.5px 0' as const,
            transform: 'translate(-50%, -60%) rotate(45deg)',
            display: this.state.agreed ? 'block' : 'none',
        };
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <div style={{ width: '100%', padding: '24px', marginTop: '5px' }}>
                    <div style={{ background: 'white', width: '100%', borderRadius: '12px 12px 2px 2px', paddingBottom: '15px' }}>
                        <div style={{ padding: '5px 24px', borderBottom: '2px solid #F3F3F3' }}>
                            <h2 style={{ fontFamily: 'aeonik-bold' }}>Create account</h2>
                        </div>
                        <WrapperDiv>
                            <div style={{ flex: '0 0 calc(50% - 10px)' }}>
                                <CustomInputLabel>First name</CustomInputLabel>
                                <CustomTextField
                                    data-test-id="first-name"
                                    inputProps={{ maxLength: 64 }}
                                    onChange={(event) => this.handleChange(event, 'first-name')}
                                    variant="outlined"
                                    placeholder="Example: John"
                                    fullWidth
                                    style={{ borderRadius: '40px' }}
                                    error={this.state.firstNameError}
                                    helperText={
                                        this.state.firstNameError && (
                                            <ErrorSpan>
                                                <img src={ErrorIcon} height={20} width={20} style={{ marginRight: '5px', marginLeft: '-13px' }} />
                                                Error : Missing required field(s)
                                            </ErrorSpan>
                                        )
                                    }
                                />
                            </div>
                            <div style={{ flex: '0 0 calc(50% - 10px)' }}>
                                <CustomInputLabel>Last name</CustomInputLabel>
                                <CustomTextField
                                    data-test-id="last-name"
                                    inputProps={{ maxLength: 64 }}
                                    onChange={(event) => this.handleChange(event, 'last-name')}
                                    variant="outlined"
                                    placeholder="Example: Doe"
                                    fullWidth
                                    style={{ borderRadius: '10px' }}
                                    error={this.state.lastNameError}
                                    helperText={
                                        this.state.lastNameError && (
                                            <ErrorSpan>
                                                <img src={ErrorIcon} height={20} width={20} style={{ marginRight: '5px', marginLeft: '-13px' }} />
                                                Error : Missing required field(s)
                                            </ErrorSpan>
                                        )
                                    }
                                />
                            </div>
                            {this.state.showCompanyField && (
                                <div style={{ flex: '0 0 calc(50% - 10px)' }}>
                                    <CustomInputLabel>Company name</CustomInputLabel>
                                    <CustomTextField
                                        value={this.state.companyName}
                                        data-test-id="company-name"
                                        inputProps={{ maxLength: 160 }}
                                        onChange={(event) => this.handleChange(event, 'company-name')}
                                        variant="outlined"
                                        placeholder="Example: Company Name"
                                        fullWidth
                                        error={this.state.companyNameError}
                                        helperText={
                                            this.state.companyNameError && (
                                                <ErrorSpan>
                                                    <img src={ErrorIcon} height={20} width={20} style={{ marginRight: '5px', marginLeft: '-13px' }} />
                                                    Error : Missing required field(s)
                                                </ErrorSpan>
                                            )
                                        }
                                    />
                                </div>
                            )}
                            {this.state.showLocalAuthorityField && (
                                <div style={{ flex: '0 0 calc(50% - 10px)' }}>
                                    <CustomInputLabel>Local authority</CustomInputLabel>
                                    <FormControl fullWidth error={this.state.localAuthorityError}>
                                        <CustomSelect
                                            value={this.state.selectedLocalAuthorityID}
                                            variant="outlined"
                                            fullWidth
                                            style={{ borderRadius: '8px' }}
                                            MenuProps={{
                                                getContentAnchorEl: null,
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                },
                                                transformOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                },
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 400,
                                                        overflowY: 'auto',
                                                    },
                                                },
                                            }}
                                            error={this.state.localAuthorityError}
                                        >
                                            {this.state.localAuthorityList.map((local, index) => (
                                                <MenuItem data-test-id={`menu-local-${local.id}`}
                                                    onClick={() => this.handleMenuItemClick(local.name, local.id, 'local-authority')}
                                                    style={{
                                                        fontFamily: 'aeonik-regular',
                                                        borderBottom: index !== this.state.localAuthorityList.length - 1 ? '1px solid #ccc' : 'none'
                                                    }}
                                                    key={local.id} value={local.id}
                                                >
                                                    {local.name}
                                                </MenuItem>
                                            ))}
                                        </CustomSelect>
                                        {this.state.localAuthorityError && (
                                            <FormHelperText>
                                                <ErrorSpan>
                                                    <img src={ErrorIcon} height={20} width={20} style={{ marginRight: '5px' }} />
                                                    Error : Missing required field(s)
                                                </ErrorSpan>
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                            )}
                            {this.state.showIndustrySectorField && (
                                <div style={{ flex: '0 0 calc(50% - 10px)' }}>
                                    <CustomInputLabel>Industry sector</CustomInputLabel>
                                    <FormControl error={this.state.industrySectorError} fullWidth>
                                        <CustomSelect
                                            value={this.state.selectedIndustrySectorID}
                                            variant="outlined"
                                            fullWidth
                                            style={{ borderRadius: '8px' }}
                                            MenuProps={{
                                                getContentAnchorEl: null,
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                },
                                                transformOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                },
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 400,
                                                        overflowY: 'auto',
                                                    },
                                                },
                                            }}
                                        >
                                            {this.state.industrySectorList.map((industry, index) => (
                                                <MenuItem data-test-id={`menu-indus-${industry.id}`}
                                                    onClick={() => this.handleMenuItemClick(industry.name, industry.id, 'industry-sector')}
                                                    style={{
                                                        fontFamily: 'aeonik-regular',
                                                        wordWrap: "break-word",
                                                        wordBreak: "break-word",
                                                        whiteSpace: 'normal',
                                                        borderBottom: index !== this.state.industrySectorList.length - 1 ? '1px solid #ccc' : 'none',
                                                    }}
                                                    key={industry.id} value={industry.id}
                                                >
                                                    {industry.name}
                                                </MenuItem>
                                            ))}
                                        </CustomSelect>
                                        {this.state.industrySectorError && (
                                            <FormHelperText>
                                                <ErrorSpan>
                                                    <img src={ErrorIcon} height={20} width={20} style={{ marginRight: '5px' }} />
                                                    Error : Missing required field(s)
                                                </ErrorSpan>
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                            )}
                        </WrapperDiv>
                        <DialogWrapper>
                            <div style={{ scale: '0.9' }}>
                                <input
                                    type="checkbox"
                                    id="gradient-checkbox"
                                    style={{ display: 'none' }}
                                    checked={this.state.agreed}
                                    onChange={this.handleCheckBoxClick}
                                />
                                <label
                                    htmlFor="gradient-checkbox"
                                    style={checkboxStyle}
                                >
                                    {this.state.agreed && <span style={checkmarkStyle}></span>}
                                </label>
                            </div>
                            <Typography style={{ fontFamily: 'aeonik-regular', display: 'inline' }}>I hereby consent to the
                                <SpanBoldAndUnderline style={{ marginRight: '4px' }} onClick={this.handlePrivacyPolicy}>privacy policy</SpanBoldAndUnderline>
                                and
                                <SpanBoldAndUnderline onClick={this.handleTermsAndCondition}>terms of use</SpanBoldAndUnderline>
                            </Typography>
                        </DialogWrapper>
                        {this.state.agreedError && (
                            <FormHelperText style={{ marginLeft: '24px' }}>
                                <ErrorSpan style={{ color: '#f44336' }}>
                                    <img src={ErrorIcon} height={20} width={20} style={{ marginRight: '5px' }} />
                                    Error : Please accept the privacy policy and terms of use
                                </ErrorSpan>
                            </FormHelperText>
                        )}
                    </div>
                    <ButtonWrapper>
                        <CustomButton data-test-id="create-account-btn" onClick={this.handleCreateAccount} variant="outlined">Create account</CustomButton>
                    </ButtonWrapper>
                </div>
                <TermsAndPrivacyDialog
                    title={this.renderTermsAndPrivacyData().title}
                    data={this.renderTermsAndPrivacyData().data}
                    visible={this.state.showPrivacyAndTermsDialog}
                    onClose={this.handleDialogClose}
                />
                <Snackbar
                    anchorOrigin={{ horizontal: "right", vertical: "top" }}
                    autoHideDuration={3000}
                    open={snackbar.open}
                    onClose={this.handleCloseSnackbar}
                >
                    <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
                </Snackbar>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const ErrorSpan = styled('span')({
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px'
})
const WrapperDiv = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '24px',
    gap: '20px',
    '@media (max-width: 765px)': {
        flexDirection: 'column',
    },
});
const ButtonWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '24px',
    '@media (max-width: 500px)': {
        justifyContent: 'center',
    },
});
const DialogWrapper = styled('div')({
    display: 'flex',
    paddingRight: '14px',
    paddingLeft: '24px',
    columnGap: '10px',
    '@media (max-width: 500px)': {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
});
const SpanBoldAndUnderline = styled('span')({
    fontWeight: 'bolder',
    cursor: 'pointer',
    textDecoration: 'underline',
    marginLeft: '4px',
})
const CustomTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px'
    },
    '& .MuiInputBase-input::placeholder': {
        fontFamily: 'aeonik-regular',
        fontSize: '1rem'
    },
})
const CustomSelect = styled(Select)({
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px'
    }
})
const CustomInputLabel = styled(InputLabel)({
    marginBottom: '10px',
    fontFamily: 'aeonik-bold',
    color: 'black',
})
const CustomButton = styled(Button)({
    borderRadius: '8px',
    textTransform: 'none',
    color: 'white',
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    padding: '8px 22px',
    fontFamily: 'aeonik-bold',
    fontSize: '1rem'
})

// Customizable Area End
