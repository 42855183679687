// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { buildRequestMessage } from "../../../components/src/buildRequestMessage.web";
import moment from "moment";
import UserLists from "../../Groups/src/UserLists";

export const configJSON = require("./config");

enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}

export interface QuestionTemplates {
  [key: string]: (data: any) => JSX.Element;
}

interface Item {
  id: number | null,
  ind?: number,
  question_type?: string,
  question_title?: string,
  answer?: any
  options?: any
  required?: boolean
  minumum?: number
  maximum?: number
  higher_label?: string
  lower_label?: string
  max_number?: number
  min_number?: number
}

interface S {
  activeItem?: number | null
  selectedItem?: Item
  renderQuestions: Item[]
  scheduleDialogVisible: boolean
  scheduleSurvey: {
    selectedGroup: Array<number>;
    selectedUser:Array<number>;
    surveyName: string;
    selectedStartDate: string;
    selectedStartTime: string;
    selectedEndDate: string;
    selectedEndTime: string;
  };
  snackbar: {
    open: boolean;
    severity: 'success' | 'info' | 'warning' | 'error' | undefined;
    message: string;
  };
  groupList: any[];
  userId1: number | null;
  selectedGroupList: number[];
  userList: any[];
  individualUserGroup: number | null;
  draggedItem: any;
  dragOverItem: number;
  submitType: string;
  dropBoxes: any[],// sections
  showDeleteConfirmDialog: boolean,
  deleteSectionIndex: number,
  isIndividualSection: boolean,
  nextBoxId:number,
  isLiveorPastSurvey:boolean,
  createdStandalone:boolean;
}

interface SS {
  id: any;
}

export default class SurveysController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      activeItem: null,
      selectedItem: { id: null, question_type: '' },
      renderQuestions: [],
      scheduleDialogVisible: false,
      scheduleSurvey: {
        selectedGroup: [],
        selectedUser:[],
        surveyName: '',
        selectedStartDate: '',
        selectedStartTime: '',
        selectedEndDate: '',
        selectedEndTime: ''
      },
      snackbar: {
        open: false,
        severity: 'info',
        message: ''
      },
      groupList: [],
      userId1: null,
      individualUserGroup: null,
      selectedGroupList: [],
      userList: [],
      draggedItem: null,
      dragOverItem: 0,
      submitType: "",
      dropBoxes: [],
      showDeleteConfirmDialog: false,
      deleteSectionIndex: 0,
      isIndividualSection: false,
      nextBoxId:3,
      isLiveorPastSurvey:false,
      createdStandalone:false,
    };

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    ];

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  handleDragStart = (item: any, itemType: any, boxIndex: any, itemIndex: any) => {
    const { dropBoxes } = this.state;
    const draggedItem = boxIndex !== -1 ? dropBoxes[boxIndex].items[itemIndex] : { item, itemType, data: {} };
    this.setState({ draggedItem: { ...draggedItem, boxIndex, itemIndex } });
  };

  handleDrop = (boxIndex: any, dropIndex: any) => {
    const { draggedItem, dropBoxes } = this.state;
    if(this.state.isLiveorPastSurvey)
    {
      return;
    }
    if (!draggedItem) return;

    const { item, itemType, boxIndex: prevBoxIndex, itemIndex, data } = draggedItem;
    const updatedDropBoxes = [...dropBoxes];

    const showSnackbar = (message: string) => {
      this.setState({
        snackbar: {
          message,
          severity: 'error',
          open: true,
        },
      });
    };
    if(this.state.dropBoxes[boxIndex].individual === true)
      {
        if(this.state.dropBoxes[boxIndex].items.length + this.state.dropBoxes.length > 6)
        {
          showSnackbar("You've already reached a maximum number of questions for this section");
        return;
        }
      }

    const canAddItemToBox = (box: any) => box.items.length < 6;

    const addItemToBox = (box: any, index: number, newItem: any) => {
      box.items.splice(index, 0, newItem);
    };

    const removeItemFromBox = (box: any, index: number) => {
      box.items.splice(index, 1);
    };

    if (!canAddItemToBox(updatedDropBoxes[boxIndex])) {
      showSnackbar("You've already reached a maximum number of questions for this section");
      return;
    }

    if (prevBoxIndex === -1) {
      addItemToBox(updatedDropBoxes[boxIndex], dropIndex, { item, itemType, data });
    } else if (boxIndex === prevBoxIndex) {
      // Reorder within the same box
      // const items = updatedDropBoxes[boxIndex].items;
      removeItemFromBox(updatedDropBoxes[boxIndex], itemIndex);
      addItemToBox(updatedDropBoxes[boxIndex], dropIndex, { item, itemType, data });
    } else {
      // Move item from one box to another
      if (canAddItemToBox(updatedDropBoxes[boxIndex])) {
        addItemToBox(updatedDropBoxes[boxIndex], dropIndex, { item, itemType, data });
        removeItemFromBox(updatedDropBoxes[prevBoxIndex], itemIndex);
      } else {
        showSnackbar("You've already reached a maximum number of questions for this section");
        return;
      }
    }

    this.setState({ dropBoxes: updatedDropBoxes, draggedItem: null });
  };
  onDragEnd2 = (result: any) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }
    const sourceBoxIndex = this.state.dropBoxes.findIndex(box => box.id.toString() === source.droppableId);
    const destBoxIndex = this.state.dropBoxes.findIndex(box => box.id.toString() === destination.droppableId);

    if (sourceBoxIndex === destBoxIndex) {
      const items = [...this.state.dropBoxes[sourceBoxIndex].items];
      const [reorderedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, reorderedItem);

      const updatedDropBoxes = [...this.state.dropBoxes];
      updatedDropBoxes[sourceBoxIndex].items = items;

      this.setState({ dropBoxes: updatedDropBoxes });
    } else {
      if(this.state.dropBoxes[destBoxIndex].individual === true)
      {
        if(this.state.dropBoxes[destBoxIndex].items.length + this.state.dropBoxes.length > 6)
        {
          this.setState({
            snackbar: {
              message: "You've already reached a maximum number of questions for this section",
              severity: 'error',
              open: true,
            },
          });
          return;
        }
      }
      if (this.state.dropBoxes[destBoxIndex].items.length === 6) {
        this.setState({
          snackbar: {
            message: "You've already reached a maximum number of questions for this section",
            severity: 'error',
            open: true,
          },
        });
        return;
      }

      const sourceItems = [...this.state.dropBoxes[sourceBoxIndex].items];
      const destItems = [...this.state.dropBoxes[destBoxIndex].items];

      const [movedItem] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, movedItem);

      const updatedDropBoxes = [...this.state.dropBoxes];
      updatedDropBoxes[sourceBoxIndex].items = sourceItems;
      updatedDropBoxes[destBoxIndex].items = destItems;

      this.setState({ dropBoxes: updatedDropBoxes });
    }
  };
  handleHeaderChange = (boxIndex: number, newHeader: any) => {
    const updatedDropBoxes = [...this.state.dropBoxes];
    updatedDropBoxes[boxIndex].header = newHeader;
    this.setState({ dropBoxes: updatedDropBoxes });
  }

  handleRemoveItem = (boxIndex: any, itemIndex: any) => {
    const updatedDropBoxes = [...this.state.dropBoxes];
    updatedDropBoxes[boxIndex].items.splice(itemIndex, 1);
    this.setState({ dropBoxes: updatedDropBoxes });
  };

  handleDeleteBox = (boxIndex: any) => {
    this.setState({ showDeleteConfirmDialog: true, deleteSectionIndex: boxIndex })

  };
  toggleStandaloneButton = () => {
    if(!this.state.createdStandalone)
    {
      if(this.state.dropBoxes.length<6)
      {
        this.setState({isIndividualSection:false});
      }
    }
  }
  handleDeleteBoxConfirmed = () => {
    if (this.state.dropBoxes[this.state.deleteSectionIndex].individual === true) {
      this.setState({ isIndividualSection: false });
      this.setState({createdStandalone:false});
    }
    const updatedDropBoxes = this.state.dropBoxes.filter((_, index) => index !== this.state.deleteSectionIndex);
    this.setState({ dropBoxes: updatedDropBoxes, showDeleteConfirmDialog: false },()=>this.toggleStandaloneButton());
  }
  handleCancelDeleteModal = () => {
    this.setState({ showDeleteConfirmDialog: false });
  }

  handleAddBox = () => {
    if (this.state.isIndividualSection) {
      if (this.state.dropBoxes[0].items.length > 0 && this.state.dropBoxes[0].items.length + this.state.dropBoxes.length > 6) {
        this.setState({
          snackbar: {
            message: "Maximum 6 sections are allowed",
            severity: 'error',
            open: true
          }
        });
        return

      }

      if (this.state.dropBoxes[0].items.length === 0 && this.state.dropBoxes[0].items.length + this.state.dropBoxes.length > 5) {
        this.setState({
          snackbar: {
            message: "Maximum 6 sections are allowed",
            severity: 'error',
            open: true
          }
        });
        return
      }
    }
    if (!this.state.isIndividualSection && this.state.dropBoxes.length > 4) {
      this.setState({ isIndividualSection: true })
      // return;
    }
    const { nextBoxId, dropBoxes } = this.state;
    const newBox = { id: nextBoxId, items: [], individual: false };

    this.setState({
      dropBoxes: [...dropBoxes, newBox],
      nextBoxId: nextBoxId + 1, // Increment the nextBoxId
    });
  };
  handleAddIndividualSection = () => {

    this.setState({ isIndividualSection: true });
    this.setState({createdStandalone:true});
    const { nextBoxId, dropBoxes } = this.state;
    const newBox = { id: nextBoxId, items: [], individual: true };

    this.setState({
      dropBoxes: [newBox, ...dropBoxes],
      nextBoxId: nextBoxId + 1, 
    });
  }
  handleDragOver = (event: any) => {
    event.preventDefault();
  };

  handleItemChange = (boxIndex: any, itemIndex: any, newData: any) => {
    const updatedDropBoxes = [...this.state.dropBoxes];
    updatedDropBoxes[boxIndex].items[itemIndex].data = newData;
    this.setState({ dropBoxes: updatedDropBoxes });
  };


  transformData = (data: any, surveyId: any) => {
    const transformedData:any = {
      survey_id: surveyId,
      sections: []
    };

    data.forEach((section:any) => {
      if (section.individual) {
        // Each item in the section becomes its own section with an empty header
        section.items.forEach((item:any) => {
          transformedData.sections.push({
            header: "",
            questions: [this.transformQuestion(item)]
          });
        });
      } else {
        // All items in the section remain in one section
        const newSection:any = {
          header: section.header || "",
          questions: section.items.map((item:any) => this.transformQuestion(item))
        };
        transformedData.sections.push(newSection);
      }
    });

    return transformedData;
  }

 transformQuestion = (item:any) => {
  const { itemType, data } = item;
  let question:any = {
    question_type: itemType,
    question_title: data.question_title || "",
    required: data.required || false,
    question_label: data.question_label || ""
  };

  if (itemType === "radio" || itemType === "checkbox" || itemType === "ranking") {
    question.options = data.options || [];
  }

  if (itemType === "slider") {
    question.higher_label = data.higher_label || "";
    question.lower_label = data.lower_label || "";
    question.min_number = data.min_number !== undefined ? data.min_number : "";
    question.max_number = data.max_number !== undefined ? data.max_number : "";
  }

  return question;
}

  async receive(from: string, message: Message) {
  // Customizable Area Start
  runEngine.debugLog("Message Recived", message);

  if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
    return;
  }

  const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  const callID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

  switch (callID) {
    case this.RequestMessage.getSurveyQuestionsRequest.messageId:
      if (response) {
        this.updateQuestionState(response);
      }
      break;
    case this.RequestMessage.createSurveyQuestions.messageId:
      if (response) {
        this.handleCreateSurveyQuestionsResponse(response);
      }
      break;
    case this.RequestMessage.scheduleSurvey.messageId:
      if (response) {
        this.handleScheduleSurveyResponse(response);
      }
    case this.RequestMessage.getSurveyGroupsRequest.messageId:
      if (response.errors === undefined) {
        this.handleGroupResponse(response);
      }
      break;
    case this.RequestMessage.getSurveyUsersRequest.messageId:
        this.handleUserResponse(response);
      break;
      case this.RequestMessage.addUsersToSelectedList.messageId:
        this.handleUserAddResponse(response);
      break;
      case this.RequestMessage.removeUserFromSelectedList.messageId:
        this.handleUserRemoveResponse(response);
      break;
  }

  // Customizable Area End
}

  // Customizable Area Start
  public RequestMessage = {
  createSurveyQuestions: buildRequestMessage(Method.POST, configJSON),
  scheduleSurvey: buildRequestMessage(Method.POST, configJSON),
  getSurveyQuestionsRequest: buildRequestMessage(Method.GET, configJSON),
  getSurveyGroupsRequest: buildRequestMessage(Method.GET, configJSON),
  getSurveyUsersRequest: buildRequestMessage(Method.POST, configJSON),
  removeUserFromSelectedList: buildRequestMessage(Method.POST, configJSON),
  addUsersToSelectedList: buildRequestMessage(Method.POST, configJSON),
  Null: undefined as any,
};



handleScheduleSurveyResponse = (response: any) => {
  if (response.data) {
    this.setState({ scheduleDialogVisible: false });
    this.props.navigation.navigate("AdminSurveys");
  } else {
    const error = response.error || response.message;
    this.setState({
      snackbar: {
        message: error || 'Something went wrong',
        severity: 'error',
        open: true
      }
    });
  }
};

handleCreateSurveyQuestionsResponse = (response: any) => {
  
  if (response.survey_id !== undefined) {
    this.setState({
      snackbar: {
        message: 'Survey changes have been saved',
        severity: 'success',
        open: true
      },
      
    });
    if (this.state.submitType == 'share') {
      this.handleScheduleSurveyClick();
      this.setState({ submitType: "" });
    }
  } else {
    const error = response.errors[0] || 'Something went wrong';
    this.setState({
      snackbar: {
        message: error,
        severity: 'error',
        open: true
      }
    });
  }
};

handleGroupResponse = (response: any) => {
  if (response.groups) {
    const sortedGroups = response.groups.sort((a: any, b: any) => {
      if (a.name && b.name) {
        return a.name.localeCompare(b.name);
      } else if (a.name) {
        return -1;
      } else if (b.name) {
        return 1;
      }
      return 0;
    });
    this.setState({ groupList: sortedGroups });
  }
}

handleUserResponse = (response: any) => {
  if (response.users) {
    const sortedUsers = response.users.sort((a: any, b: any) => {
      if (a.first_name && b.first_name) {
        return a.first_name.localeCompare(b.first_name);
      } else if (a.first_name) {
        return -1;
      } else if (b.first_name) {
        return 1;
      }
      return 0;
    });
    this.setState({ userList: sortedUsers });

    const initialSelectedUsers = response.users
                .filter((user: { is_in_group: any; is_individual_user_added: any; }) => user.is_in_group || user.is_individual_user_added)
                .map((user: { id: any; }) => user.id);
   
    this.state.scheduleSurvey.selectedUser = initialSelectedUsers;        
    
  }
}

handleUserAddResponse = (response: any) => {
  if (response) {
    this.getUsers();
  }
}

handleUserRemoveResponse = (response: any) => {
  if (response) {
    this.getUsers();
  }
}

getLocalDate = (date: string, time: string) => {
  if (!date || !time) {
    return '';
  }
  const local = moment.utc(`${date} ${time}`, "YYYY-MM-DD HH:mm")
  return local.local().format("YYYY-MM-DD")
}

getLocalTime = (date: string, time: string) => {
  if (!date || !time) {
    return '';
  }
  const local = moment.utc(`${date} ${time}`, "YYYY-MM-DD HH:mm")
  return local.local().format("HH:mm")
}
transformIndividualQuestions = (apiResponse:any)=> {
  return {
    id: 1, // Assign a unique ID or use any other logic for the ID
    items: apiResponse.individual_questions.map((question:any) => {
      let itemData:any = {
        question_title: question.question_title,
      };

      // Add additional fields based on the question type
      switch (question.question_type) {
        case "long-text":
        case "short-text":
        case "date":
          itemData.question_label = question.question_label;
          itemData.required = question.required;
          break;
        case "slider":
          itemData.min_number = question.min_number;
          itemData.lower_label = question.lower_label;
          itemData.max_number = question.max_number;
          itemData.required = question.required;
          itemData.higher_label = question.higher_label;
          break;
        case "radio":
          case "ranking":
          case "checkbox":
          itemData.options = question.options.options.map((option:any) => option.name);
          break;
        default:
          break;
      }

      return {
        item: "",
        itemType: question.question_type,
        data: itemData
      };
    }),
    individual: true
  };
}
  transformApiResponse = (apiResponse: any) => {
    return apiResponse.sections.map((section: any) => {
      return {
        id: section.id,
        header: section.header,
        items: section.questions.map((question: any) => {
          let itemData: any = {
            question_title: question.question_title,
          };

          
          switch (question.question_type) {
            case "short-text":
            case "long-text":
            case "date":
              itemData.required = question.required;
              itemData.question_label = question.question_label;
              break;
            case "slider":
              itemData.min_number = question.min_number;
              itemData.max_number = question.max_number;
              itemData.lower_label = question.lower_label;
              itemData.higher_label = question.higher_label;
              itemData.required = question.required;
              break;
            case "radio":
            case "checkbox":
            case "ranking":
              itemData.options = question.options.options.map((option: any) => option.name);
              break;
            default:
              break;
          }

          return {
            item: "",
            itemType: question.question_type,
            data: itemData
          };
        }),
        individual: false 
      };
    });
  }

updateQuestionState = (response: any) => {

  this.setState({ individualUserGroup: response.survey.data?.attributes.individual_user_group }) 
  

  let sections = this.transformApiResponse(response);
  this.setState({dropBoxes:sections});

  if(response.individual_questions.length > 0)
  {
    this.setState({isIndividualSection:true})
    let individualQuestions = this.transformIndividualQuestions(response);
    let myDrop = this.state.dropBoxes;
    myDrop.unshift(individualQuestions);
  }
  this.checkLiveORPastSurvey(response.survey.data.attributes.status)
  
  if (response.survey) {
    const survey = response.survey.data?.attributes;
    let selectedStartTime = survey.start_time ? new Date(survey.start_time).toISOString().substring(11, 16) : '';
    let selectedEndTime = survey.end_time ? new Date(survey.end_time).toISOString().substring(11, 16) : '';
    let individualUser: number;
    if (selectedStartTime === "00:00") selectedStartTime = "24:00";
    if (selectedEndTime === "00:00") selectedEndTime = "24:00";

    if (survey) {
      const scheduleSurvey = {
        selectedGroup: survey?.groups||[],
        selectedUser:[],
        surveyName: survey.name,
        selectedStartDate: this.getLocalDate(survey.start_date, selectedStartTime),
        selectedStartTime: this.getLocalTime(survey.start_date, selectedStartTime),
        selectedEndDate: this.getLocalDate(survey.end_date, selectedEndTime),
        selectedEndTime: this.getLocalTime(survey.end_date, selectedEndTime),
      };

      this.setState({ scheduleSurvey });
    }
  }

}

getSurveyQuestions = () => {
  let surveyId: any = this.props.navigation.getParam('surveyId');

  this.RequestMessage.getSurveyQuestionsRequest.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getQuestionsEndPoint}?id=${surveyId}`
  );

  runEngine.sendMessage(this.RequestMessage.getSurveyQuestionsRequest.id, this.RequestMessage.getSurveyQuestionsRequest);
};

createSurveyQuestions = (data: any) => {
  this.RequestMessage.createSurveyQuestions.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.createSurveyQuestionsEndpoint
  );

  this.RequestMessage.createSurveyQuestions.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(data)
  );

  runEngine.sendMessage(this.RequestMessage.createSurveyQuestions.id, this.RequestMessage.createSurveyQuestions);
};

getGroups = () => {
  this.RequestMessage.getSurveyGroupsRequest.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getSurveyGroupsEndPoint
  );

  runEngine.sendMessage(this.RequestMessage.getSurveyGroupsRequest.id, this.RequestMessage.getSurveyGroupsRequest);
};



getUsers = () => {

  const groupIds = this.state.selectedGroupList;
  const individualUserGroupId = this.state.individualUserGroup;

  if (groupIds.length === 0 && individualUserGroupId !== null) {
    this.RequestMessage.getSurveyUsersRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSurveyUsersEndPoint}`
    );
    this.RequestMessage.getSurveyUsersRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ individual_user_group: individualUserGroupId })
    );
  } else {
    this.RequestMessage.getSurveyUsersRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSurveyUsersEndPoint}`
    );
    this.RequestMessage.getSurveyUsersRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ individual_user_group: individualUserGroupId, group_ids: groupIds })
    );
  }
  runEngine.sendMessage(this.RequestMessage.getSurveyUsersRequest.id, this.RequestMessage.getSurveyUsersRequest);
}

removeUsers = (id: number) => {
  const individualUserGroupId = this.state.individualUserGroup;
  this.setState({ userId1: id });

  this.RequestMessage.removeUserFromSelectedList.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.removeUserGroupApiEndPoint}`
  );
  this.RequestMessage.removeUserFromSelectedList.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify({id:id , group_id : individualUserGroupId  })
  );

  runEngine.sendMessage(this.RequestMessage.removeUserFromSelectedList.id, this.RequestMessage.removeUserFromSelectedList);

}

addUsers = (id: number) => {
  const individualUserGroupId = this.state.individualUserGroup;
  this.RequestMessage.addUsersToSelectedList.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.selectUserGroupApiEndPoint}`
  );
  this.RequestMessage.addUsersToSelectedList.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify({id:id , group_id : individualUserGroupId  })
  );

  runEngine.sendMessage(this.RequestMessage.addUsersToSelectedList.id, this.RequestMessage.addUsersToSelectedList);
}

scheduleSurvey = (data: any) => {

  let surveyId: any = this.props.navigation.getParam('surveyId');
  this.RequestMessage.scheduleSurvey.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.scheduleSurveyEndPoint}?id=${surveyId}`
  );

  this.RequestMessage.scheduleSurvey.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(data)
  );

  runEngine.sendMessage(this.RequestMessage.scheduleSurvey.id, this.RequestMessage.scheduleSurvey);
};

handleSelectedGroupList = () => {
const individualUserGroupId = this.state.individualUserGroup; 
const selectedGroup = this.state.scheduleSurvey.selectedGroup; 
const filteredGroupList = selectedGroup.filter(group => group !== individualUserGroupId);
this.setState({ selectedGroupList: filteredGroupList });
};

handleCloseSnackbar = () => {
  this.setState({
    snackbar: {
      message: '',
      open: false,
      severity: 'info'
    }
  })
}

  async componentDidMount() {
  super.componentDidMount();
  await Promise.all([this.getSurveyQuestions(), this.getGroups(), this.getUsers()]);
}

  async componentDidUpdate(prevProps: Props, prevState: S): Promise < void> {
if (prevState.selectedGroupList !== this.state.selectedGroupList ||
  prevState.individualUserGroup !== this.state.individualUserGroup
) {
  await Promise.all([this.state.selectedGroupList, this.state.individualUserGroup, this.getUsers(), this.state.userList, this.state.userId1]);
}
if(prevState.scheduleSurvey.selectedGroup  !== this.state.scheduleSurvey.selectedGroup){
  this.handleSelectedGroupList()
}
  }

handleInputChange = (data: any) => {
  const updateData = this.state.renderQuestions.map((el, index) => {
    if (index === data.ind) {
      return data;
    }
    return el;
  });

  this.setState({ renderQuestions: updateData });
}

handleDeleteClick = (ind: any) => {
  const updateData = this.state.renderQuestions.filter((el, index) => index !== ind);
  this.setState({ renderQuestions: updateData });
}

onItemHover = (id: number) => {
  this.setState({ activeItem: id });
}

onMouseLeave = () => {
  this.setState({ activeItem: undefined });
}

onItemClick = (data: any): void => {
  const { icon, ...val } = data;
  this.setState({ selectedItem: val });
}
checkHeaders =(data:any) =>{
  for (const item of data) {
    if (!item.individual && (!item.header || item.header.trim() === "")) {
      
      return true;
    }
  }
  return false;
}
checkEmptyItems(data:any) {
  for (const item of data) {
    if (item.items.length === 0) {
      
      return true;
    }
  }
  return false;
}
checkLiveORPastSurvey = (status:string) => {
  if(status === 'live' || status === 'past')
  {
    this.setState({isLiveorPastSurvey:true});
  }
}
validateSliderItems = (data:any) => {
  const errors:any = [];

  data.forEach((section:any) => {
    section.items.forEach((item:any, itemIndex:number) => {
      if (item.itemType === "slider") {
        const { min_number, max_number, lower_label, higher_label } = item.data;
        
        if (!lower_label || !higher_label) {
          errors.push({
            id: section.id,
            itemIndex,
            message: "Higher and lower label is required for slider question"
          });
        }
        
        if (min_number == undefined || max_number == undefined) {
          errors.push({
            id: section.id,
            itemIndex,
            message: "Minimum and maximum value is required for slider question"
          });
        }
        
        if (Number(min_number) >= Number(max_number)) {
          errors.push({
            id: section.id,
            itemIndex,
            message: "Minimum value should be less than maximum value"
          });
        }
      }
    });
  });

  return errors;
}
validateQuestionTitles = (data: any) => {
  for (let section of data) {
    for (let item of section.items) {
      if (!item.data.question_title || item.data.question_title.trim() === "") {
        return true;
      }
    }
  }
  return false;
};
handleSaveChangesClick = (str: string) => {
  this.setState({submitType:str});
  if (this.checkHeaders(this.state.dropBoxes)) {
    this.setState({
      snackbar: {
        message: 'Section header is required',
        severity: 'error',
        open: true
      }
    });
    return;
  }
  if(this.checkEmptyItems(this.state.dropBoxes))
  {
    this.setState({
      snackbar: {
        message: 'Please add atleast one question into a section',
        severity: 'error',
        open: true
      }
    });
    return;
  }
  if (this.validateQuestionTitles(this.state.dropBoxes)) {
    this.setState({
      snackbar: {
        message: "Question title can't be blank",
        severity: 'error',
        open: true
      }
    });
    return;
  }
  const errors = this.validateSliderItems(this.state.dropBoxes);
  if (errors.length > 0) {
    // Handle errors
    this.setState({
      snackbar: {
        message: errors[0].message,
        severity: 'error',
        open: true
      }
    });
    return;
  }
  const surveyId = this.props.navigation.getParam('surveyId')
  const payloadData = this.transformData(this.state.dropBoxes,surveyId);
  const { renderQuestions } = this.state;
  const questions = renderQuestions.map((el) => {
    const { id, ind, ...val } = el;
    return val;
  }) || [];

  

  // check if question title is empty then show error
  const emptyQuestion = questions.filter((el) => !el.question_title);
  if (emptyQuestion?.length > 0) {
    this.setState({
      snackbar: {
        message: 'Question title is required',
        severity: 'error',
        open: true
      }
    });
    return;
  }

  const sliderType = questions.find((el) => el.question_type === 'slider');

  if (sliderType) {
    if (!sliderType.higher_label || !sliderType.lower_label) {
      this.setState({
        snackbar: {
          message: 'Higher and lower label is required for slider question',
          severity: 'error',
          open: true
        }
      });
      return;
    }

    if (!sliderType.min_number || !sliderType.max_number) {
      this.setState({
        snackbar: {
          message: 'Minimum and maximum value is required for slider question',
          severity: 'error',
          open: true
        }
      });
      return;
    }

    if (Number(sliderType.min_number) >= Number(sliderType.max_number)) {
      this.setState({
        snackbar: {
          message: 'Minimum value should be less than maximum value',
          severity: 'error',
          open: true
        }
      });
      return;
    }
  }

  const data = payloadData;

  this.createSurveyQuestions(data);
}

addNewFieldClick = () => {
  if (!this.state.selectedItem?.id) {
    return;
  }

  this.setState({ renderQuestions: [...this.state.renderQuestions, this.state.selectedItem] });
}

handleScheduleSurveyClick = () => {
  this.setState({ scheduleDialogVisible: true });
};

handleDialogClose = () => {
  this.setState({ scheduleDialogVisible: false });
};

handleChange = (event: any) => {
  const { name, value } = event.target;
  this.setState((prevState) => ({
    scheduleSurvey: {
      ...prevState.scheduleSurvey,
      [name]: value,
    },
  }));
};

handleSubmitSchduleSurvey = (quickSchedule: boolean) => {
  
  const { scheduleSurvey, renderQuestions } = this.state;
  
  if (!this.state.dropBoxes.length) {
    this.setState({
      snackbar: {
        message: 'Please save questions before scheduling the survey',
        severity: 'error',
        open: true
      }
    });
    return;
  }

  

  if (!this.state.selectedGroupList.length && !scheduleSurvey.selectedUser.length) {
    this.setState({
      snackbar: {
        message: 'Please select a group or user',
        severity: 'error',
        open: true
      }
    });
    return;
  }

  if (!scheduleSurvey.selectedEndDate || !scheduleSurvey.selectedEndTime || (!quickSchedule && (!scheduleSurvey.selectedStartDate || !scheduleSurvey.selectedStartTime ))) {
    this.setState({
      snackbar: {
        message: 'Please select dates and times',
        severity: 'error',
        open: true
      }
    });
    return;
  }
  const startDateTime = `${scheduleSurvey.selectedStartDate} ${scheduleSurvey.selectedStartTime}`;
  const endDateTime = `${scheduleSurvey.selectedEndDate} ${scheduleSurvey.selectedEndTime}`;
  const startLocal = moment(startDateTime, "YYYY-MM-DD hh:mm A");
  const startUTC = startLocal.utc();
  const endLocal = moment(endDateTime, "YYYY-MM-DD hh:mm A");
  const endUTC = endLocal.utc();
  const start24HourFormat = startLocal.format("YYYY-MM-DD HH:mm");
  const end24HourFormat = endLocal.format("YYYY-MM-DD HH:mm");
  const startTime =start24HourFormat.split(' ')[1];
  const endTime = end24HourFormat.split(' ')[1];

  const data = {
    admin_start_time : scheduleSurvey.selectedStartTime,
    admin_start_date : scheduleSurvey.selectedStartDate,
    end_date: endUTC.format("YYYY-MM-DD"),
      end_time: endTime,
    group_ids: scheduleSurvey.selectedGroup,
    //name: scheduleSurvey.surveyName,
    is_activated: true,
    start_date: "",
    start_time: "",
    is_start_now: false,
  };
  if (quickSchedule) {
    const current = moment.utc();
    data.start_date = current.format('YYYY-MM-DD')
    data.start_time = current.add(1, 'minutes').format('HH:mm'),
      data.is_start_now = quickSchedule
  } else {
    data.start_date = startUTC?.format("YYYY-MM-DD")
    data.start_time = startTime;
  }

  if (!data.start_date || !data.end_date) {
    this.setState({
      snackbar: {
        message: 'Start date and end date is required',
        severity: 'error',
        open: true
      }
    });
    return;
  }

  if (!data.start_time || !data.end_time) {
    this.setState({
      snackbar: {
        message: 'Start time and end time is required',
        severity: 'error',
        open: true
      }
    });
    return;
  }

  this.scheduleSurvey(data);
}
navigateToAdminSurvey = () => {
  const msg: Message = new Message(
    getName(MessageEnum.NavigationDraftSurveyMessage)
  );
  msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(msg);
}

onDragEnter = (e: React.DragEvent<HTMLDivElement>, ind: number) => {
  this.setState({ dragOverItem: ind });
}

onDragLeave = (e: React.DragEvent<HTMLDivElement>, ind: number) => {
  this.setState({ dragOverItem: ind });
}

onDrop = (e: React.DragEvent<HTMLDivElement>) => {
  e.preventDefault();
  const { dragOverItem } = this.state;

  if (!this.state.selectedItem?.id) {
    return;
  }

  if (dragOverItem === 99) {
    this.setState({ renderQuestions: [...this.state.renderQuestions, this.state.selectedItem] });
  }
}

onDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
  e.preventDefault();
  const { draggedItem, dragOverItem, renderQuestions } = this.state;
  //duplicate items
  let _renderQuestions = [...renderQuestions];

  //remove and save the dragged item content
  const draggedItemContent = _renderQuestions.splice(draggedItem, 1)[0];

  //switch the position
  _renderQuestions.splice(dragOverItem, 0, draggedItemContent);

  //reset the position
  this.setState({ draggedItem: 0, dragOverItem: 0 });
  //update the actual array
  this.setState({ renderQuestions: _renderQuestions });
}

  // Customizable Area End
}

// Customizable Area End
const lebhai = [
  {
    "id": 1,
    "items": [
      {
        "item": "",
        "itemType": "short-text",
        "data": {}
      },
      {
        "item": "",
        "itemType": "long-text",
        "data": {}
      },
      {
        "item": "",
        "itemType": "radio",
        "data": {}
      },
      {
        "item": "",
        "itemType": "checkbox",
        "data": {}
      },
      {
        "item": "",
        "itemType": "slider",
        "data": {}
      },
      {
        "item": "",
        "itemType": "date",
        "data": {}
      }
    ]
  }
]
const ApiResp = {
  "survey": {
    "data": {
      "id": "14391",
      "type": "survey",
      "attributes": {
        "id": 14391,
        "name": "Ranking question test ",
        "description": "Your Survey Description",
        "respondents": 0,
        "start_date": null,
        "end_date": null,
        "start_time": null,
        "end_time": null,
        "status": "draft",
        "individual_user_group": 94924,
        "draft": null,
        "groups": [
          94924
        ],
        "user_avatar": null,
        "user_first_name": "test user",
        "user_last_name": "test"
      }
    }
  },
  "individual_questions": [
    {
      "id": 14554,
      "survey_id": 14391,
      "question_type": "short-text",
      "question_title": "What is your name",
      "options": {},
      "required": true,
      "order_number": 1,
      "question_label": null
    },

    {
      "id": 14555,
      "survey_id": 14391,
      "question_type": "radio",
      "question_title": "What is your age",
      "options": {
        "options": [
          {
            "id": 4587,
            "name": "15-24",
            "question_id": 14555
          },
          {
            "id": 4588,
            "name": "25-34",
            "question_id": 14555
          },
          {
            "id": 4589,
            "name": "35-44",
            "question_id": 14555
          },
          {
            "id": 4590,
            "name": "45+",
            "question_id": 14555
          }
        ]
      },
      "required": true,
      "order_number": 2
    }
  ],
  "sections": [
    {
      "id": 176,
      "survey_id": 14391,
      "header": "work experience",
      "questions": [
        {
          "id": 14556,
          "survey_id": 14391,
          "question_type": "short-text",
          "question_title": "What is your current job title?",
          "options": {},
          "required": true,
          "order_number": 3,
          "question_label": "please say you job time"
        },
        {
          "id": 13441,
          "survey_id": 13513,
          "question_type": "date",
          "question_title": "What is your birthdate",
          "options": {},
          "required": false,
          "order_number": null,
          "question_label": null
        },
        {
          "id": 13442,
          "survey_id": 13513,
          "question_type": "ranking",
          "question_title": "Rannk your priority to live",
          "options": {
            "options": [
              {
                "id": 3951,
                "name": "America",
                "question_id": 13442
              },
              {
                "id": 3952,
                "name": "Europe",
                "question_id": 13442
              },
              {
                "id": 3953,
                "name": "Asia",
                "question_id": 13442
              },
              {
                "id": 3954,
                "name": "Australia",
                "question_id": 13442
              }
            ]
          },
          "required": true,
          "order_number": null
        },
        {
          "id": 14557,
          "survey_id": 14391,
          "question_type": "radio",
          "question_title": "How many years of experience do you have?",
          "options": {
            "options": [
              {
                "id": 4591,
                "name": "0-1",
                "question_id": 14557
              },
              {
                "id": 4592,
                "name": "2-5",
                "question_id": 14557
              },
              {
                "id": 4593,
                "name": "6-10",
                "question_id": 14557
              },
              {
                "id": 4594,
                "name": "10+",
                "question_id": 14557
              }
            ]
          },
          "required": true,
          "order_number": 4
        },
        {
          "id": 13443,
          "survey_id": 13513,
          "question_type": "slider",
          "question_title": "Temprature in your city",
          "options": {},
          "required": false,
          "order_number": null,
          "lower_label": "Lowest",
          "higher_label": "Highest",
          "min_number": 1,
          "max_number": 20
        },
        {
          "id": 14558,
          "survey_id": 14391,
          "question_type": "checkbox",
          "question_title": "What industries have you worked in?",
          "options": {
            "options": [
              {
                "id": 4595,
                "name": "Technology",
                "question_id": 14558
              },
              {
                "id": 4596,
                "name": "Finance",
                "question_id": 14558
              },
              {
                "id": 4597,
                "name": "Healthcare",
                "question_id": 14558
              },
              {
                "id": 4598,
                "name": "Education",
                "question_id": 14558
              },
              {
                "id": 4599,
                "name": "Other",
                "question_id": 14558
              }
            ]
          },
          "required": true,
          "order_number": 5
        },
        {
          "id": 14559,
          "survey_id": 14391,
          "question_type": "long-text",
          "question_title": "Describe your typical workday",
          "options": {},
          "required": true,
          "order_number": 6,
          "question_label": null
        }
      ]
    },
    {
      "id": 177,
      "survey_id": 14391,
      "header": "hobbies and interests",
      "questions": [
        {
          "id": 14560,
          "survey_id": 14391,
          "question_type": "short-text",
          "question_title": "What is your favorite hobby?",
          "options": {},
          "required": true,
          "order_number": 7,
          "question_label": null
        },
        {
          "id": 14561,
          "survey_id": 14391,
          "question_type": "radio",
          "question_title": "How often do you engage in your hobby?",
          "options": {
            "options": [
              {
                "id": 4600,
                "name": "Daily",
                "question_id": 14561
              },
              {
                "id": 4601,
                "name": "Weekly",
                "question_id": 14561
              },
              {
                "id": 4602,
                "name": "Monthly",
                "question_id": 14561
              },
              {
                "id": 4603,
                "name": "Rarely",
                "question_id": 14561
              }
            ]
          },
          "required": true,
          "order_number": 8
        },
        {
          "id": 14562,
          "survey_id": 14391,
          "question_type": "checkbox",
          "question_title": "What types of hobbies do you enjoy?",
          "options": {
            "options": [
              {
                "id": 4604,
                "name": "Sports",
                "question_id": 14562
              },
              {
                "id": 4605,
                "name": "Reading",
                "question_id": 14562
              },
              {
                "id": 4606,
                "name": "Traveling",
                "question_id": 14562
              },
              {
                "id": 4607,
                "name": "Gaming",
                "question_id": 14562
              },
              {
                "id": 4608,
                "name": "Cooking",
                "question_id": 14562
              }
            ]
          },
          "required": true,
          "order_number": 9
        },
        {
          "id": 14563,
          "survey_id": 14391,
          "question_type": "long-text",
          "question_title": "Describe why you enjoy your favorite hobby",
          "options": {},
          "required": true,
          "order_number": 10,
          "question_label": null
        }
      ]
    }
  ]
}