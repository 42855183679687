// Customizable Area Start
import React from "react";

import {
  Box,
  Typography,
  Grid,
  Snackbar,
  Select,
  MenuItem,
  styled,
  Button,
  DialogContent,
  withStyles,
  Dialog,
  DialogActions,
  Slider,
  TextField,
  InputAdornment,
  IconButton,
  InputLabel,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';

import CustomInput from '../../../components/src/CustomInput.web';
import UserSettingsSider from '../../../components/src/UserSettingsSider.web';
import DeleteUserAccountDialog from "../../../components/src/DeleteUserAccountDialog.web";
import TermsAndConditionDialog from "../../../components/src/TermsAndPrivacyDialog.web";

import UserSettingsController, { Props } from "./UserSettingsController.web";
import AvatarEditor from "react-avatar-editor";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

export default class UserSettings extends UserSettingsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { user } = this.state;
    return (
      <Settings>
        <Box style={styles.header}>
          <TitleSettings variant="h4" align="center" gutterBottom>
            Settings
          </TitleSettings>
        </Box>
        <Body>
          <Grid style={styles.form} container spacing={4}>
            <Grid item lg={2} xs={12} md={3} >
              <Box style={styles.sidebar}>
                <UserSettingsSider
                  activeItem={this.state.activeItem}
                  selectedId={this.state.selectedId}
                  onItemHover={this.onItemHover}
                  onMouseLeave={this.onMouseLeave}
                  onItemClick={this.onItemClick}
                  onSignOutClick={this.onSignOutClick}
                  signOutConfirmationVisible={this.state.signOutConfirmationVisible}
                  onClose={this.onSignOutDialogClose}
                />
              </Box>
            </Grid>
            {
              this.state.changepasswordVisible ? (
                <Grid item lg={10} xs={12} md={9} >
                  <Grid
                    item
                    style={{
                      ...styles.profile,
                      margin: '0',
                    }}
                    xs={12}
                    md={12}
                  >
                    <Box>
                      <InputLabel
                        style={{
                          fontFamily: "aeonik-bold",
                          color: 'black',
                          margin: '10px'
                        }}
                      >
                        Old password
                        <CustomTextField
                          data-test-id="show-old-password"
                          variant="outlined"
                          placeholder="Old Password"
                          type={this.state.showOldPassword ? 'text' : 'password'}
                          value={this.state.oldPassword}
                          onChange={(e) => this.setState({
                            oldPassword: e?.target.value
                          })}
                          fullWidth
                          margin="normal"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  data-test-id="show-old-password"
                                  style={{ color: "#4B6EB0" }}
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowOldPassword}
                                >
                                  {!this.state.showOldPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </InputLabel>

                      <InputLabel style={{
                        fontFamily: "aeonik-bold",
                        color: 'black',
                        margin: '10px'
                      }}>
                        New password
                        <CustomTextField
                          placeholder="New Password"
                          variant="outlined"
                          type={this.state.showNewPassword ? 'text' : 'password'}
                          value={this.state.newPassword}
                          onChange={(e) => this.setState({
                            newPassword: e?.target.value
                          })}
                          fullWidth
                          margin="normal"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  style={{ color: "#4B6EB0" }}
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowNewPassword}
                                >
                                  {!this.state.showNewPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </InputLabel>

                      <InputLabel style={{
                        fontFamily: "aeonik-bold",
                        color: 'black',
                        margin: '10px'
                      }}>
                        Confirm password
                        <CustomTextField
                          placeholder="Confirm Password"
                          variant="outlined"
                          type={this.state.showConfirmPassword ? 'text' : 'password'}
                          value={this.state.confirmPassword}
                          onChange={(e) => this.setState({
                            confirmPassword: e?.target.value
                          })}
                          fullWidth
                          margin="normal"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  style={{ color: "#4B6EB0" }}
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowConfirmPassword}
                                >
                                  {!this.state.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </InputLabel>

                    </Box> 
                  </Grid> 
                      <div style={{marginTop:'20px',display:'flex',justifyContent:'end'}}>
                        <SaveChangesButton  
                        disabled={!(this.state.confirmPassword && this.state.newPassword && this.state.oldPassword)} 
                        onClick={this.handlePasswordChanges} 
                        style={{   background: (this.state.confirmPassword && this.state.newPassword && this.state.oldPassword) ? "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)" : "grey",color:'white'}}
                        >
                          Change password
                        </SaveChangesButton>
                      </div>
                  </Grid>
              ) : (
                <Grid item lg={10} xs={12} md={9} >
                  <Grid item style={styles.profile} xs={12} md={12} >
                    <div style={{ padding: '20px' }}>
                      <Typography style={webStyle.Profile}>Edit profile</Typography>
                      <div>
                        <label htmlFor="image-upload" style={{ display: 'inline-block' }}>
                          {this.state.user.avatar ? (
                            <>
                              <img
                                data-test-id="profile-image"
                                src={this.state.user.avatar}
                                onClick={this.showProfileEditor}
                                alt="profile"
                                style={{
                                  width: '150px',
                                  height: '150px',
                                  objectFit: 'cover',
                                  borderRadius: '50%',
                                  cursor: 'pointer',
                                  border: this.state.isImageValid ? 'none' : '1px solid red',
                                }}
                              />
                              <button
                                style={{
                                  display: 'block',
                                  margin: '10px auto',
                                  background: 'none',
                                  border: 'none',
                                  color: '#4B6EB0',
                                  cursor: 'pointer',
                                }}
                                data-test-id="onClick"
                                onClick={(ev) => this.removeProfilePicture(ev)}
                              >
                                Remove profile icon
                              </button>
                            </>
                          ) : (
                            <div
                              style={{
                                marginTop: "15px",
                                width: '150px',
                                height: '150px',
                                borderRadius: '50%',
                                backgroundColor: '#ECEFF7',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '75px',
                                color: '#A2AFCD',
                                textTransform: 'uppercase',
                                cursor: 'pointer',
                                fontWeight: 700,
                                fontFamily: 'aeonik-regular',
                                border: this.state.isImageValid ? 'none' : '1px solid red',
                              }}
                            >
                              {this.state.initials}
                            </div>
                          )}
                        </label>
                        {!this.state.user.avatar && (
                          <input
                            type="file"
                            accept="image/*"
                            id="image-upload"
                            onChange={this.handleImageChange}
                            style={{ display: 'none' }}
                          />
                        )}
                      </div>
                      <span style={{ color: "red", fontFamily: 'aeonik-regular', fontSize: "13px" }}>{this.state.errorMessage}</span>
                      <Typography style={webStyle.supported} >5mb size or less, PNG/JPEG/GIF files only supported.</Typography>
                    </div>
                    <Grid style={styles.section} container spacing={2}>

                      <Grid item xs={12} md={6}>
                        <CustomInput
                          label='First name'
                          value={this.state.user.first_name}
                          name="first_name"
                          onChange={this.onChange}
                          maxLength={64}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CustomInput
                          label='Last name'
                          value={this.state.user.last_name}
                          name="last_name"
                          onChange={this.onChange}
                          maxLength={64}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CustomInput
                          label="Email address"
                          value={this.state.user.email}
                          name="email"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} md={6} />
                    </Grid>
                    <div style={styles.divider} />
                    <Grid style={styles.section} container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Typography style={{ fontFamily: "aeonik-regular" }}>Other details</Typography>
                      </Grid>
                      {
                        user.has_company && (
                          <Grid item xs={12} md={6}>
                            <CustomInput
                              onChange={this.onChange}
                              value={this.state.user.company}
                              name="company"
                              label='Company Name'
                              maxLength={160}
                            />
                          </Grid>
                        )
                      }
                      {
                        user?.has_districts && (
                          <Grid item xs={12} md={6}>
                            <div style={styles.selectBox}>
                              <label style={styles.label} htmlFor="local_authority">District/Local authority</label>
                              <Select

                                variant="outlined"
                                fullWidth
                                style={styles.select}
                                name="district_id"
                                id="local_authority"
                                data-test-id="local_authority"
                                value={this.state.user.district_id}
                                onChange={this.onChange}
                              >
                                {this.state.user?.districts?.map((district: any) => (
                                  <MenuItem data-test-id='district_menu' onClick={() => this.getDistName(district)} key={district.id} value={district.id} style={{ fontFamily: "aeonik-regular" }}>
                                    {district.name}
                                  </MenuItem>
                                  // <option key={district.id} value={district.id} style={{ fontFamily: "aeonik-regular" }}>{district.name}</option>
                                ))}
                              </Select>
                            </div>
                          </Grid>
                        )
                      }
                      {
                        user?.has_industry_sector && (
                          <Grid item xs={12} md={6}>
                            <div style={styles.selectBox}>
                              <label style={styles.label} htmlFor="local_authority">Industry sector</label>
                              <Select
                                fullWidth
                                variant="outlined"
                                style={styles.select}
                                name="industry_sector_id"
                                id="industry_sector"
                                data-test-id="industry_sector"
                                value={this.state.user.industry_sector_id}
                                onChange={this.onChange}
                              >
                                {this.state.user?.industries?.map((industry: any) => (
                                  <MenuItem data-test-id='industry_menu' onClick={() => this.getIndusName(industry)} key={industry.id} value={industry.id} style={{ fontFamily: "aeonik-regular" }}>
                                    {industry.name}
                                  </MenuItem>
                                  // <option key={industry.id} value={industry.id} style={{ fontFamily: "aeonik-regular"}}>{industry.name}</option>
                                ))}
                              </Select>
                            </div>
                          </Grid>
                        )
                      }
                      <Grid item xs={12} md={6} />
                      <Grid item xs={12} md={12}>
                        <div data-test-id='terms-btn' onClick={this.handleOpenTermsAndConditionDialog} style={styles.link}>Terms and conditions</div>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <div data-test-id='privacy-btn' onClick={this.handleOpenPrivacyPolicyDialog} style={styles.link}>Privacy policy</div>
                      </Grid>
                    </Grid>
                    <Grid container>
                    <Grid item xs={12} md={10}>
                     
                    </Grid>
                  </Grid>
                  </Grid>
                  <ButtonBox>
                        <DeleteButton onClick={this.handleDeleteAccount} >
                          Delete account
                        </DeleteButton>
                        <SaveChangesButton 
                          disabled={!this.state.isChange} 
                          onClick={this.handleSave} 
                          style={{   background: this.state.isChange ? "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)" : "grey",color:'white'}}
                        >
                          Save changes
                        </SaveChangesButton>
                      </ButtonBox>

                  </Grid>
                  )
            }
          </Grid>
        </Body>
        <Snackbar anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          autoHideDuration={3000}
          open={this.state.snackbar.open}
          onClose={this.handleCloseSnackbar}
        >
          <Alert severity={this.state.snackbar.severity}>
            {this.state.snackbar.message}
          </Alert>
        </Snackbar>
        <ProfileDialog open={this.state.profilEditor}>
          <ProfileTitle>Adjust position of photo</ProfileTitle>
          <DialogContent style={{ padding: '0px', background: '#eeeeee' }}>
            <AvatarWrapper>
              <div>
                <AvatarEditor
                  crossOrigin="anonymous"
                  ref={this.state.editorRef}
                  image={this.state.fullProfileImage}
                  width={200}
                  height={200}
                  border={50}
                  borderRadius={125}
                  scale={this.state.zoomLevel}
                  color={[236, 236, 236, 0.6]}
                  rotate={0}
                  style={styles.editor}
                />
              </div>
            </AvatarWrapper>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#eeeeee', zIndex: -99, marginTop: '-15px' }}>
              <div style={{ width: '40%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <p style={{ fontFamily: 'aeonik-regular' }}>Zoom</p>
                  <p style={{ fontFamily: 'aeonik-regular' }}>{this.state.zoomLevel.toFixed(2)}</p>
                </div>
                <PrettoSlider
                  data-test-id="photo-zoom"
                  style={{ marginTop: '-20px' }}
                  value={this.state.zoomLevel}
                  min={1}
                  max={3}
                  step={0.01}
                  onChange={this.handleZoomChange}
                  aria-labelledby="zoom-slider"
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center', alignItems: 'center' }}>
            <MyButton data-test-id="profile-dialog-apply" variant="outlined" onClick={this.handleApplyProfileEditor}>
              Apply
            </MyButton>
            <MyButton data-test-id="profile-dialog-close" style={{ background: 'none', color: '#374f81' }} variant="outlined" onClick={this.handleProfileEditorClose}>
              Cancel
            </MyButton>
          </DialogActions>
        </ProfileDialog>
        <TermsAndConditionDialog
          title={this.renderTermsAndPrivacyData()?.title}
          data={this.renderTermsAndPrivacyData()?.data}
          visible={this.state.termsAndConditionDialogVisible}
          onClose={this.handleCloseTermsAndCondtionDioalg}
        />
        <DeleteUserAccountDialog
          onChange={this.handlePasswordChange}
          password={this.state.password}
          visible={this.state.deleteConfirmationVisible}
          handleClose={this.handleDeleteConfirmationClose}
          onDeleteClick={this.handleDeleteAccountSave}
        />
      </Settings>
    );
  }
}
const CustomTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
  '& input::-ms-reveal, & input::-ms-clear': {
    display: 'none',
  },
});
const ButtonBox = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '22px 12px 22px 12px',
  '@media screen and (max-width: 768px)': {
    flexDirection: 'column',
    rowGap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
const ProfileTitle = styled(Typography)({
  padding: '20px',
  fontWeight: 'bold',
  fontFamily: 'aeonik-bold',
});
const AvatarWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#eeeeee',
});
const ProfileDialog = styled(Dialog)({
  '& .MuiPaper-rounded': {
    borderRadius: '10px',
    width: 'calc(45% - 100px)',
  },
});
const MyButton = styled(Button)({
  borderRadius: '8px',
  textTransform: 'none',
  color: 'white',
  background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
  padding: '6px 18px',
  fontFamily: 'aeonik-bold',
  fontSize: '1rem'
})
const PrettoSlider = withStyles({
  root: {
    color: '#374f81',
    height: 4,
  },
  thumb: {
    height: 12,
    width: 12,
    backgroundColor: '#fff',
    border: '4px solid currentColor',
    marginTop: -4,
    marginLeft: -6,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: '-30px',
    '& *': {
      background: 'white',
      color: '#000',
      transform: 'none',
      width: '80px',
      borderRadius: '4px',
      textAlign: 'center',
    },
  },
  track: {
    height: 4,
    borderRadius: 2,
  },
  rail: {
    height: 4,
    borderRadius: 2,
    opacity: 1,
    backgroundColor: 'white',
  },
})(Slider);
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  additionalFields: {
    fontFamily: 'aeonik-regular',
  },
  initials: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
    backgroundColor: '#ECEFF7',
    borderRadius: '50%',
    color: '#A2AFCD',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    textAlign: 'center',
    padding: '8px',
    marginRight: '40px'
  },
  primaryBtn: {
    borderRadius: '10px',
    background: 'var(--g, linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%))',
    padding: '18px 32px',
    color: '#FFFFFF',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    minWidth: '180px',
    textTransform: "none" as const,
    fontFamily: 'aeonik-bold',
  },
  addBtn: {
    borderRadius: "10px",
    border: "1px solid #BFCAE4",
    color: "rgb(59 84 132)",
    fontFamily: 'aeonik-regular',
    fontSize: "14px",
    fontWeight: 700,
    display: 'flex',
    justifyContent: "flex-start",
    marginLeft: "15px",
    textTransform: 'none' as const,
  },
  subBox: {
    border: '1px solid #E7E7E7',
    margin: '20px',
    height: "auto",
    boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px;',
    borderRadius: '20px',
    background: "#FFFFFF",
    // width: "95%",
    // padding: "20px",
    "@media (max-width: 500px)": {
      margin: '20px',
    }
  },
  Profile: {
    fontFamily: 'aeonik-regular',
    fontWeight: 600,
    fontSize: "16px",
  },
  close: {
    padding: '15px 60px',
    borderRadius: '10px',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
    color: "#1C386D",
    marginLeft: '0px'
  },
  edit: {
    padding: '15px 60px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    fontSize: '16px',
    fontWeight: 700,
    color: 'rgba(255, 255, 255, 1)',
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    textTransform: "none" as const,
  },
  close1: {
    padding: '18px 55px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    textTransform: "capitalize" as const,
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    "-webkit-background-clip": "text",
    color: "transparent",
  },
  select: {
    border: "1px solid #E0E0E0",
    borderRadius: "10px",
  },
  Error: {
    display: "flex",
    justifyContent: "center",
    fontFamily: 'aeonik-regular',
    fontSize: "20px",
    fontWeight: 700
  },

  Link: {
    display: 'inline-block',
    color: "#4B6EB0", fontFamily: 'aeonik-regular', fontSize: "16px", fontWeight: 700,
    cursor: "pointer" as const,
    textDecoration: 'underline'
  },
  modalbtn: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: "center",
    gap: "10px",
    marginTop: "15px"
  },
  supported: {
    fontFamily: 'aeonik-regular',
    fontWeight: 600,
    fontSize: "16px",
    marginTop: "10px"
  },
  lable: {
    color: "#333333",
    fontSize: "16px",
    fontFamily: 'aeonik-regular',
    fontWeight: 600,
    marginBottom: "10px"
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  Tital: {
    color: "#202020",
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: 'aeonik-regular',
    display: "flex",
    padding: "15px",
    justifyContent: "center",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: "20px"
  },
  surveyTital: {
    color: "#202020",
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: 'aeonik-regular',
    display: "flex",
    padding: "20px",
    justifyContent: "center",
    borderBottom: "1px solid #E7E7E7",
    marginBottom: "10px"
  },
};
const DeleteButton = styled('button')({
  // background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
  borderRadius: "10px",
  padding: "18px 24px",
  display: "flex",
  alignItems: "center",
  color: "#1C386D",
  minWidth: '200px',
  fontWeight: 700,
  fontSize: "16px",
  // border: 'none',
  userSelect: 'none' as const,
  cursor: 'pointer',
  justifyContent: 'center',
  fontFamily: 'aeonik-bold',
  border: '1px solid #1C386D',
  backgroundColor: 'transparent',
  textTransform: 'none',
  '@media screen and (max-width:768px)': {
    padding: '12px 18px',
    fontSize: '14px',
    width: '60%',
  }
})
const SaveChangesButton = styled(Button)({
  background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
  borderRadius: "10px",
  padding: "18px 24px",
  display: "flex",
  alignItems: "center",
  color: "white",
  minWidth: '200px',
  fontWeight: 700,
  fontSize: "16px",
  border: 'none',
  userSelect: 'none' as const,
  cursor: 'pointer',
  justifyContent: 'center',
  fontFamily: 'aeonik-bold',
  textTransform: 'none',
  '@media screen and (max-width:768px)': {
    padding: '12px 18px',
    fontSize: '14px',
    width: '60%',
  }
})
const Settings = styled('div')({
  flexDirection: 'column' as const,
  display: 'flex',
  flex: '1 1 0px',
  boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px;',
  border: '1px solid #ddd',
  margin: '44px',
  borderRadius: '20px',
  overflow: 'hidden' as const,
  minHeigth: '70%',
  background: "#FFFFFF",

  '@media screen and (max-width:500px)': {
    borderRadius: '10px',
    margin: '30px 15px 15px 19px',

  }
})
const TitleSettings = styled(Typography)({
  fontSize: '20px',
  fontWeight: 700,
  textAlign: 'left',
  fontFamily: 'aeonik-bold',
  marginBottom: '0px',
  lineHeight: ' 23px',
  '@media screen and (max-width:500px)': {
    fontSize: '20px',
    fontWeight: 600,
    textAlign: 'center',
  }
})
const Body = styled('div')({
  padding: '30px 22px 0px 16px',
  marginBottom: '30px',
  '@media screen and (max-width:500px)': {
    padding: '15px 15px 15px 15px',
  }
})

const styles = {
  editor: {
    margin: '0 auto',
  },
  settings: {
    flexDirection: 'column' as const,
    display: 'flex',
    flex: '1 1 0px',
    boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px;',
    border: '1px solid #ddd',
    margin: '44px',
    borderRadius: '20px',
    overflow: 'hidden' as const,
    minHeigth: '70%',
    background: "#FFFFFF",
    backgroundColor: 'red',
  },
  header: {
    padding: '26px 30px',
    borderBottom: '1px solid #E7E7E7',
  },
  title: {
    fontSize: '20px',
    fontWeight: 700,
    textAlign: 'left' as const,
    fontFamily: 'aeonik-bold',
    marginBottom: '0px',
    lineHeight: ' 23px',
  },
  body: {
    padding: '30px 22px 0px 16px',
    // overflowY: 'scroll' as const,
    // height: "100vh"
    backgroundColor: 'red',
    marginBottom: '30px',
  },
  editProfile: {
    color: '#333',
    fontSize: '16px',
    fontStyle: 'normal',
    fontFamily: 'aeonik-bold',
    fontWeight: 600,
    lineHeight: 'normal',
    marginBottom: '12px',
    textTransform: 'none' as const
  },
  profile: {
    borderRadius: '12px',
    border: '1px solid #E7E7E7',
    // height: '100%',
    background: '#fff',
    marginBottom: '25px',
    padding:'10px'
  },
  divider: {
    background: '#E7E7E7',
    width: '100%',
    height: '1px',
  },
  section: {
    padding: '24px'
  },
  selectBox: {
    display: 'flex',
    flex: '1 1 0px',
    flexDirection: 'column' as const,
  },
  label: {
    fontWeight: 800,
    color: "#333333",
    fontSize: "16px",
    marginBottom: "12px",
    fontFamily: 'aeonik-bold',
    textTransform: 'none' as const,
  },
  select: {
    border: "1px solid #E0E0E0",
    borderRadius: "10px",
    fontFamily: "aeonik-regular",
    height: '53px',
  },
  form: {
    height: '100%'
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0px 12px 22px 12px',
  },
  button: {
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    borderRadius: "10px",
    padding: "18px 24px",
    display: "flex",
    alignItems: "center",
    color: "white",
    minWidth: '200px',
    fontWeight: 700,
    fontSize: "16px",
    border: 'none',
    userSelect: 'none' as const,
    cursor: 'pointer',
    justifyContent: 'center',
    fontFamily: 'aeonik-bold',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column' as const,
    background: 'var(--basic-white, #FFF)',
    boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)',
    borderRadius: '12px',
  },
  deleteBtn: {
    background: '#FF0000',
  },
  link: {
    display: 'inline-block',
    color: '#4B6EB0',
    fontSize: '16px',
    fontFamily: 'aeonik-bold',
    fontWeight: 700,
    cursor: "pointer" as const,
  }
};
// Customizable Area End
