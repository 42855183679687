// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { userTemplateFile } from "./assets"

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

interface S {
  test: string;
  personName: any;
  email: string;
  createUserModal: boolean;
  selectFields: [];
  getPastSurveysMessageId: any;
  rowsPerPage: number;
  page: number;
  group: [];
  userId: number;
  userGroups: number[];
  errorMessage: string;
  error: boolean;
  continueModal: boolean;
  uploadModal: boolean;
  uploadSuccessModal: boolean;
  uploadFailModal: boolean;
  particalUploadModal:boolean;
  uploadFailError: string;
  visible: boolean;
  surveys: any;
  surveyName: string;
  groupList: any;
  groupError: boolean;
  userList: any;
  userCount: number;
  adminDetails: any;
  displayedUser:[];
  createUserSuccess:boolean;
  groupId:number;
  bulkUploadResponseArray:string[];
  fileSizeErrorModal:boolean,
  successMessage:string,
  uploadCancelState:boolean,
}
interface SS {
  id: any;
}

export default class AccountCreationController extends BlockComponent<
  Props,
  S,
  SS
> {
  getGroupDetailCallId: string = "";
  getUserListDetailsCallId: string = "";
  createUserCallId: string = "";
  userBulkUploadCallId: string = "";
  onCreateCallId: string = "";
  selectUserCallId = "";
  removeUserCallId = "";
  userTemplateFile: any = userTemplateFile;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      test: "",
      createUserModal: false,
      selectFields: [],
      getPastSurveysMessageId: "",
      personName: ["Group Name 1"],
      rowsPerPage: 20,
      page: 1,
      email: "",
      group: [],
      userId: 0,
      userGroups: [],
      errorMessage: "",
      error: false,
      continueModal: false,
      uploadModal: false,
      uploadSuccessModal: false,
      particalUploadModal:false,
      uploadFailModal: false,
      uploadFailError: "",
      visible: false,
      surveys: [],
      surveyName: "",
      groupList: [],
      groupError: false,
      userList: [],
      userCount: 0,
      adminDetails: JSON.parse(localStorage.getItem("user") || '{}'),
      displayedUser:[],
      createUserSuccess:false,
      groupId:0,
      bulkUploadResponseArray:[],
      fileSizeErrorModal:false,
      successMessage:'',
      uploadCancelState:false,
      // Customizable Area End
    };

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (!apiRequestCallId || !responseJson) {
      return;
    }
    if (apiRequestCallId === this.getGroupDetailCallId && responseJson) {
      this.getGroupDetailReceive(responseJson);
    } else if (
      apiRequestCallId === this.getUserListDetailsCallId &&
      responseJson
    ) {
      this.getUserDetailsReveive(responseJson);
    } else if (apiRequestCallId === this.createUserCallId && responseJson) {
      this.createUserReceive(responseJson);
    } else if (apiRequestCallId === this.userBulkUploadCallId && responseJson) {
      this.userBulkUploadRecieve(responseJson);
    } else if (apiRequestCallId === this.selectUserCallId && responseJson) {
      this.handleSelectUserReceive(responseJson);
    } else if (apiRequestCallId === this.removeUserCallId && responseJson) {
      this.handleSelectUserReceive(responseJson);
    }

    // runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
    await Promise.all([this.getGroupDetails(), this.getUserDetails()]);
  }
  // Customizable Area Start

  getGroupDetails = () => {
    const authToken = localStorage.getItem("token");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGroupApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.getGroupDetailCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getUserDetails = () => {
    const authToken = localStorage.getItem("token");

    const url = window.location.href;
    const groupId = url.split('/').pop();
    const numericGroupId = Number(groupId);
    this.setState({groupId:numericGroupId});

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      
      `bx_block_user/users/group_users_list?id=${numericGroupId}&per_page=${this.state.rowsPerPage}&page=${this.state.page}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.getUserListDetailsCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  
  getGroupDetailReceive = (responseJson: any) => {
    if (responseJson?.groups) {
      this.setState({ groupList: responseJson.groups });
    }
  };

  getUserDetailsReveive = (responseJson: any) => {
    // const [arrayuser] = responseJson.users;

    if (responseJson?.users) {

      this.setState({
        userList: responseJson.users,
        userCount: responseJson.total_count,
      });

    }

  };

  handleUserPagination = (e: any, val: any) => {
    this.setState({ page: val }, this.getUserDetails);
  };

  

  handleCheck = (event: any) => {
    this.setState({ userGroups: event.target.value as number[] });
  };

  handleClickCreateUser = () => {
    this.setState({ email: "", userGroups: [this.state.groupId], createUserModal: true });
  };

  handleCancelCreateUser = () => {
    this.setState({ email: "", userGroups: [], createUserModal: false });
  };

  handleCreateUserSubmit = () => {
    if (!this.state.email) {
      this.setState({ error: true, errorMessage: "Email is required" });
    } else {
      const authToken = localStorage.getItem("token");
      const data = {
        email: this.state.email,
        group_ids: this.state.userGroups,
      };
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: authToken,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createUserApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );

      this.createUserCallId = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  createUserReceive = (responseJson: any) => {
    if (responseJson.message) {
      this.setState({ email: "", userGroups: [], createUserModal: false,createUserSuccess:true });
      this.getUserDetails();
      setTimeout(() => {
        this.setState({createUserSuccess:false})
    },4000);
    }

    if (responseJson.errors) {
      this.setState({ error: true, errorMessage: responseJson.errors[0] });
    }
  };

  handleSnackbarClose = () => {
    this.setState({ error: false, errorMessage: "" });
  };

  handleUploadUserClick = () => {
    this.setState({ uploadModal: true });
  };

  handleUpoadUserCancel = () => {
    this.setState({ uploadModal: false });
  };

  handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file.size > 15 * 1024 * 1024) {
      this.setState({ uploadModal: false, fileSizeErrorModal: true,})
      return;
    }
    const url = window.location.href;
    const groupId = url.split('/').pop();
    const numericGroupId = Number(groupId);
    const authToken = localStorage.getItem("token");

    const header = {
      // "Content-Type": configJSON.validationApiMultiPartFormData,
      token: authToken,
    };

    let formData = new FormData();

    if (file) {
      formData.append("excel_file", file);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_groups/groups/bulk_upload_users?group_id=${numericGroupId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    this.userBulkUploadCallId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({uploadCancelState:true});
  };

  userBulkUploadRecieve = (responseJson: any) => {
    if (responseJson.message) {
      this.setState({ uploadModal: false, uploadSuccessModal: true,successMessage:responseJson.message[0]});
      this.getUserDetails();
      this.setState({uploadCancelState:false});
    }
    if (responseJson.status === "fail") {
      this.setState({ uploadModal: false, uploadFailModal: true, bulkUploadResponseArray: responseJson.errors })
      this.setState({uploadCancelState:false});
    }
    if (responseJson.status === "partially_successful") {
      this.setState({ uploadModal: false, particalUploadModal: true, bulkUploadResponseArray: responseJson.errors });
      this.setState({uploadCancelState:false});
      this.getUserDetails();
    }
  };

  

  handleClickUploadSuccessContinue = () => {
    this.setState({ uploadSuccessModal: false });
    this.getUserDetails();
  };

  handleClickUploadUserFailTryAgain = () => {
    this.setState({
      uploadFailModal: false,
      uploadModal: true,
      uploadFailError: "",
    });
    this.setState({particalUploadModal:false,fileSizeErrorModal:false});
  };

  handleClickUploadUserFailCancel = () => {
    this.setState({ uploadFailModal: false, uploadFailError: "" });
    this.setState({fileSizeErrorModal:false});
    this.setState({particalUploadModal:false});
  };

  handleSelectUsersForGroup = (groupId: number) => {
    this.props.navigation.navigate("GroupUsers", { groupId });
  };

  selectUserForGroup = (userId: number) => {
    const authToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.selectUserGroupApiEndPoint +
        `?id=${userId}&group_id=${Number(
          this.props.navigation.getParam("groupId")
        )}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.selectUserGroupApiMethod
    );

    this.selectUserCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSelectUserReceive = (responseJson: any) => {
    if (responseJson.errors) {
      return this.setState({
        error: true,
        errorMessage: responseJson.errors[0],
      });
    }

    this.getUserDetails();
  };

  removeUserFromGroup = (userId: number) => {
    const authToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeUserGroupApiEndPoint +
        `?id=${userId}&group_id=${Number(
          this.props.navigation.getParam("groupId")
        )}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.removeUserGroupApiMethod
    );

    this.removeUserCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  // Customizable Area End
}

// Customizable Area End
