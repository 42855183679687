import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { AdjustRounded, FormatAlignJustify } from "@material-ui/icons";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  showPrivacyAndTermsDialog: boolean;
  showPrivacyPolicyDetails: boolean;
  termsAndConditionDescription: string;
  privacyPolicyDescription: string;
  firstName: string,
  lastName: string,
  companyName: string,
  selectedLocalAuthorityID: number | null,
  selectedIndustrySectorID: number | null,
  selectedLocalAuthorityName: string,
  selectedIndustrySectorName: string,
  localAuthorityList: any[],
  industrySectorList: any[],
  userID: number | null,
  firstNameError: boolean,
  lastNameError: boolean,
  companyNameError: boolean,
  industrySectorError: boolean,
  localAuthorityError: boolean,
  agreed: boolean,
  agreedError: boolean,
  snackbar: {
    open: boolean;
    severity: 'success' | 'info' | 'warning' | 'error' | undefined;
    message: string;
  };
  showCompanyField:boolean,
  showLocalAuthorityField:boolean,
  showIndustrySectorField:boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateUserAccountController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  TermsAndConditionCallID: string = "";
  PrivacyPolicyCallID: string = "";
  UserDetailsCallID: string = "";
  PostUserDetailsCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      showPrivacyAndTermsDialog: false,
      showPrivacyPolicyDetails: false,
      termsAndConditionDescription: '',
      privacyPolicyDescription: '',
      firstName: '',
      lastName: '',
      companyName: '',
      selectedLocalAuthorityID: null,
      selectedIndustrySectorID: null,
      selectedLocalAuthorityName: '',
      selectedIndustrySectorName: '',
      localAuthorityList: [],
      industrySectorList: [],
      userID: null,
      firstNameError: false,
      lastNameError: false,
      companyNameError: false,
      localAuthorityError: false,
      industrySectorError: false,
      agreed: false,
      agreedError: false,
      snackbar: {
        open: false,
        severity: 'error',
        message: ''
      },
      showCompanyField:false,
      showIndustrySectorField:false, 
      showLocalAuthorityField:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
    //   let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
    // }

    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (this.TermsAndConditionCallID === apiRequestCallId && responseJson !== undefined) {
      this.receiveTermsAndConditionResponse(responseJson);
    }
    if (this.PrivacyPolicyCallID === apiRequestCallId && responseJson !== undefined) {
      this.receivePrivacyPolicyResponse(responseJson);
    }
    if (this.UserDetailsCallID === apiRequestCallId && responseJson !== undefined) {
      this.receiveUserDetailsResponse(responseJson);
    }
    if (this.PostUserDetailsCallID === apiRequestCallId && responseJson !== undefined) {
      this.postUserDetailsResponse(responseJson)
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    await Promise.all([
      this.getUserDetails(),
      this.getTermsAndConditionDescription(),
      this.getPrivacyPolicyDescription(),
    ])
  }
  getTermsAndConditionDescription = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_terms_and_conditions/terms_and_conditions`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    this.TermsAndConditionCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  receiveTermsAndConditionResponse = (response: any) => {
    if (response?.description) {
      this.setState({ termsAndConditionDescription: response.description });
    }
  }
  getPrivacyPolicyDescription = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_privacy_policy/privacy_policies`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    this.PrivacyPolicyCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  receivePrivacyPolicyResponse = (response: any) => {
    if (response?.description) {
      this.setState({ privacyPolicyDescription: response.description });
    }
  }
  getUserDetails = () => {
    let token = localStorage.getItem("token");
    const user = localStorage.getItem('user');
    const userId = (user && user !== "undefined") ? JSON.parse(user).id : null;
    this.setState({ userID: userId })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserEndPoint}?id=${userId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    this.UserDetailsCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  receiveUserDetailsResponse = (response: any) => {

    if (response.user.activated) {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationAuthBlockMessage)
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
    if(response.has_company)
    {
      this.setState({showCompanyField:response.has_company})
    }
    if(response.has_districts)
    {
      this.setState({showLocalAuthorityField:response.has_districts})
    }
    if(response.user.has_industry_sector)
    {
      this.setState({showIndustrySectorField:response.has_industry_sector});
    }
    if (response.industries) {
      this.setState({ industrySectorList: response.industries })
    }
    if (response.districts) {
      this.setState({ localAuthorityList: response.districts })
    }
    if(response.user.company)
    {
      this.setState({companyName:response.user.company})
    }
    if(response.user.industry_sector)
    {
      this.setState({selectedIndustrySectorID:response.user.industry_sector})
    }
    if(response.user.local_authority)
    {
      this.setState({selectedLocalAuthorityID:response.user.local_authority});
    }
  }
  postUserDetails = () => {
    let postData = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      company: this.state.companyName,
      district_id: this.state.selectedLocalAuthorityID,
      industry_sector_id: this.state.selectedIndustrySectorID,
      industry_name: this.state.selectedIndustrySectorName,
      district_name: this.state.selectedLocalAuthorityName,
      id: this.state.userID
    };
    const authToken = localStorage.getItem('token')
    const header = {
      "Content-Type": 'application/json',
      token: authToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateUserEndPoint}?id=${this.state.userID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(postData)
    );
    this.PostUserDetailsCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  postUserDetailsResponse = (response: any) => {
    
   
    if(!response.errors)
    {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationSurveyCatalog)
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
      return;
    }
   
      this.setState({
        snackbar: {
          message: response.errors,
          severity: 'error',
          open: true,
        },
      });
      
    
  }
  handlePrivacyPolicy = () => {
    this.setState({ showPrivacyAndTermsDialog: true, showPrivacyPolicyDetails: true })
  }
  handleTermsAndCondition = () => {
    this.setState({ showPrivacyAndTermsDialog: true, showPrivacyPolicyDetails: false })
  }
  handleDialogClose = () => {
    this.setState({ showPrivacyAndTermsDialog: false })
  }
  renderTermsAndPrivacyData = () => {
    if (this.state.showPrivacyPolicyDetails) {
      return {
        title: 'Privacy policy',
        data: { description: this.state.privacyPolicyDescription }
      };
    } else {
      return {
        title: 'Terms and condition',
        data: { description: this.state.termsAndConditionDescription }
      };
    }
  }
  handleMenuItemClick = (name: string, selectID: number, type: string) => {
    if (type === 'local-authority') {
      this.setState({ localAuthorityError: false })
      this.setState({
        selectedLocalAuthorityID: selectID,
        selectedLocalAuthorityName: name
      })
    }
    if (type === 'industry-sector') {
      this.setState({ industrySectorError: false })
      this.setState({
        selectedIndustrySectorID: selectID,
        selectedIndustrySectorName: name
      })
    }
  }
  checkForErrors = () => {
    const firstNameError = this.state.firstName.trim() === '';
    const lastNameError = this.state.lastName.trim() === '';
    const companyNameError = this.state.showCompanyField ? this.state.companyName.trim() === '' : false;
    const localAuthorityError = this.state.showLocalAuthorityField ? this.state.selectedLocalAuthorityID === null : false;
    const industrySectorError = this.state.showIndustrySectorField ? this.state.selectedIndustrySectorID === null : false;
    const agreedError = !this.state.agreed;

    this.setState({
      firstNameError,
      lastNameError,
      companyNameError,
      localAuthorityError,
      industrySectorError,
      agreedError,
    });
    const hasErrors = firstNameError || lastNameError || companyNameError || agreedError || industrySectorError || localAuthorityError;
    return hasErrors;
  }

  handleChange = (event: any, name: string) => {
    if (name === 'first-name') {
      this.setState({ firstName: event.target.value })
      this.setState({ firstNameError: false });
    }
    if (name === 'last-name') {
      this.setState({ lastName: event.target.value })
      this.setState({ lastNameError: false });
    }
    if (name === 'company-name') {
      this.setState({ companyName: event.target.value })
      this.setState({ companyNameError: false });
    }
  }

  handleCheckBoxClick = () => {
    this.setState({ agreedError: false });
    this.setState({ agreed: !this.state.agreed });
  }
  confirmCreateAccount = () => {
    const { firstNameError, lastNameError, companyNameError, agreedError, industrySectorError, localAuthorityError } = this.state;
    if (firstNameError || lastNameError || companyNameError || agreedError || industrySectorError || localAuthorityError) {
      return true;
    }
  }
  handleCreateAccount = () => {
    this.checkForErrors();
    if (this.checkForErrors()) {
      return;
    }
    this.postUserDetails();
  }
  handleCloseSnackbar = () => {
    this.setState({
      snackbar: {
        message: "",
        open: false,
        severity: "error",
      },
    });
  };
  // Customizable Area End
}
