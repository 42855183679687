import React, { FunctionComponent } from 'react';

import {
    Typography,
    Slider,
    styled,
    withStyles
} from "@material-ui/core";

interface Props {
    reports: any,
    previousSurvey: boolean,
}

interface IProps {
    report: any
    lastItem?: boolean
    sequence?: number
    previousSurvey: boolean,
    standAloneQue:boolean,
}

interface Question {
    question_title: string;
    question_type: 'short-text' | 'radio' | 'checkbox' | 'long-text' | 'date' | 'ranking';
    answer: string | string[]; // This can be a string or an array of strings, depending on the question type
    total?: number; // Optional property
    options?: { option: string; average: number }[]; // Optional property for radio and checkbox questions
    analytics?: { option: { id: number; name: string }; score: number; rank: number }[]; // Optional property for ranking question
    slider?: null; // Placeholder for slider question type
    option_ids?: null; // Placeholder for option_ids question type
    respondents?: number; // Optional property
}
interface OriginalQuestion {
    id: number;
    title: string;
    data: {
        type: string;
        analytics: any[]; // Adjust as per actual structure
        answer: string;
        date_answer: string | null;
        slider: any; // Adjust as per actual structure
        option_ids: string[] | null;
        respondents: number;
    };
}

interface TransformedQuestionShortText {
    question_title: string;
    question_type: "short-text";
    answer: string;
}

interface TransformedQuestionRadio {
    question_title: string;
    question_type: "radio";
    answer: string[];
    total: number;
    options: {
        option: string;
        average: number;
    }[];
}

interface TransformedQuestionCheckbox {
    question_title: string;
    question_type: "checkbox";
    answer: string[];
    total: number;
    options: {
        option: string;
        average: number;
    }[];
}

interface TransformedQuestionLongText {
    question_title: string;
    question_type: "long-text";
    answer: string;
}

interface TransformedQuestionDate {
    question_title: string;
    question_type: "date";
    answer: string;
    analytics: any[]; // Adjust as per actual structure
    slider: any; // Adjust as per actual structure
    option_ids: any; // Adjust as per actual structure
    respondents: number;
}

interface TransformedQuestionRanking {
    question_title: string;
    question_type: "ranking";
    answer: string;
    analytics: {
        option: {
            id: number;
            name: string;
        };
        score: number;
        rank: number;
    }[];
}

type TransformedQuestion =
    | TransformedQuestionShortText
    | TransformedQuestionRadio
    | TransformedQuestionCheckbox
    | TransformedQuestionLongText
    | TransformedQuestionDate
    | TransformedQuestionRanking;

interface OriginalSection {
    header: string;
    questions: OriginalQuestion[];
}

interface TransformedSection {
    header: string;
    questions: TransformedQuestion[];
}

const QuestionAndAnswer: FunctionComponent<IProps> = ({ report, lastItem, sequence, previousSurvey,standAloneQue }) => {
    return (
        // <div style={(!lastItem || standAloneQue ? ({ ...styles.container }) : {})}>
        <div style={{
            ...styles.container,
            marginBottom: standAloneQue ? '0' : '30px', 
            border:standAloneQue? "none":'1px solid #DEDEDE'
          }}>
            <AnswerCard>
                <div>
                    <Typography style={styles.title}>
                        {sequence}{'.'} {report.question_title}
                    </Typography>
                </div>
                <div>
                    <Typography style={styles.answerTitle}>Answer</Typography>
                    <AnswerContainer>
                        {report.answer !== null ? <Typography style={styles.answer}>{report.answer}</Typography> :
                            <Typography style={styles.answer}>No response</Typography>
                        }
                    </AnswerContainer>
                </div>
            </AnswerCard>
        </div>
    )
}

const OptionialQuestion: FunctionComponent<IProps> = ({ report, lastItem, sequence, previousSurvey,standAloneQue }) => {
    if (report.answer[0] === "") {
        report.answer[0] = "None";
    }
    if (report.question_type === "radio") {
        const answerId = report.answer[0];
        const matchedOption = report.options.find((option: any) => option.optionId == answerId);

        if (matchedOption) {
            report.answer = [matchedOption.option];
        }
    }

    return (
        // <div style={(!lastItem ? ({ ...styles.container }) : {})}>
        <div style={{
            ...styles.container,
            marginBottom: standAloneQue ? '0' : '30px', 
            border:standAloneQue? "none":'1px solid #DEDEDE'
          }}>
            <div style={{ padding: '24px' }}>
                <div>
                    <Typography style={styles.title}>
                        {sequence}{'.'} {report?.question_title}
                    </Typography>
                </div>
                <div style={{ paddingTop: '10px' }} >
                    {report.options.map((option: any, index: number) => {
                        const isAnswer = report.answer.includes(option.option);
                        return (
                            <div style={styles.percentage} key={index}>
                                <AverageDiv
                                    style={{
                                        ...styles.average,
                                        width: option.average ? option.average + '%' : 'unset',
                                        background: isAnswer && !previousSurvey ? "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)" : '#e4e8f3',
                                        color: isAnswer && !previousSurvey ? "white" : 'black'
                                    }}
                                >
                                    <Typography style={styles.option}>{option.option}</Typography>
                                </AverageDiv>
                                <Typography style={styles.volume}>{option.average + '%'}</Typography>
                            </div>
                        );
                    })}
                    <div style={styles.result}>
                        {!previousSurvey && (<><Typography style={styles.yourAnswer}>Your Answer: {" "} {report?.answer?.join(', ')}</Typography>
                            <div style={styles.divider} /></>)}
                        <Typography style={styles.totalCount}>Total responses: {" "} {report?.total}</Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}

const SliderQuestion: FunctionComponent<IProps> = ({ report, lastItem, sequence, previousSurvey,standAloneQue }) => {
    const marks = [
        {
            value: report.min_number,
            label: report.min_number,
        },
        // {
        //   value: report?.average,
        //   label: 'average: ' + report?.average,
        // },
        {
            value: report.max_number,
            label: report.max_number,
        },
    ];
    const valueLabelFormat = (value: number) => {
        const averageValue = report?.average_value;
        return `Score: ${averageValue != null ? averageValue.toFixed(2) : report?.min_value}`;
    };
    if (report.answer === null) {
        report.answer = "None";
    }
    return (
        // <div style={(!lastItem ? ({ ...styles.container }) : {})}>
        <div style={{
            ...styles.container,
            marginBottom: standAloneQue ? '0' : '30px', 
            border:standAloneQue? "none":'1px solid #DEDEDE'
          }}>
            <div style={{ padding: '24px' }}>
                <div>
                    <Typography style={styles.title}>
                        {sequence}{'.'} {report.question_title}
                    </Typography>
                </div>
                <div style={{ backgroundColor: '#e4e8f3', padding: '10px', borderRadius: '6px' }}>
                    <div style={styles.sliderLabels}>
                        {/* <Typography style={styles.sliderLabel}>{report.lower_label}</Typography>
                        <Typography style={styles.sliderLabel}>{report.higher_label}</Typography> */}
                    </div>
                    <div >
                        <SliderWrapper>
                            <PrettoSlider
                                style={{ marginTop: '40px' }}
                                value={Number(report?.avgPercent) || 0}
                                data-test-id="slider"
                                min={report.min_number}
                                max={report.max_number}
                                defaultValue={report.avgPercent}
                                valueLabelDisplay="on"
                                valueLabelFormat={valueLabelFormat}
                            />

                        </SliderWrapper>
                        <LabelWrapper>
                            <div>
                                <SliderLabel>{report.min_label}</SliderLabel>
                                <Typography style={{ textAlign: 'center', fontWeight: 600, marginTop: '5px' }}>{report.min_value}</Typography>
                            </div>
                            <div>
                                <SliderLabel>{report.max_label}</SliderLabel>
                                <Typography style={{ textAlign: 'center', fontWeight: 600, marginTop: '5px' }}>{report.max_value}</Typography>
                            </div>
                        </LabelWrapper>
                    </div>
                    <div style={{...styles.result,marginLeft:'2.5%'}}>
                        {!previousSurvey && <Typography style={styles.yourAnswer}>Your response: {" "} <span style={{ fontWeight: 100 }}>{report?.answer}</span>{"  "}</Typography>}
                        <Typography style={{ ...styles.yourAnswer, marginLeft: '10px' }}> Total responses: {" "}  <span style={{ fontWeight: 100 }}>{report?.respondents}</span></Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}

const SurveyReportCards: FunctionComponent<Props> = ({
    reports,
    previousSurvey,

}) => {
    let order = 0;
    const formatDate = (dateString: string | undefined | null): string => {
        if (!dateString) {
            return '';
        }

        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        const dateParts = dateString.split("-");
        const year = dateParts[0];
        const month = months[parseInt(dateParts[1], 10) - 1];
        const day = parseInt(dateParts[2], 10);

        return `${day} ${month} ${year}`;
    }

    return (
        <div>
            {reports.map((section: TransformedSection, sectionIndex: number) => (
                <Sections style={section.header === '' ? { border: '1px solid lightgrey' } : {}} key={sectionIndex}>
                    <SectionHeader style={section.header === '' ? {padding:'0px',marginTop:'0px',background:'transparent' } : {}}>{section.header}</SectionHeader>
                    {section.questions.map((question: any, questionIndex: number) => {
                        if (question.question_type === 'short-text' || question.question_type === 'long-text') {
                            return (
                                <QuestionAndAnswer
                                    key={questionIndex}
                                    sequence={order = order + 1}
                                    lastItem={questionIndex === section.questions.length - 1}
                                    report={question}
                                    previousSurvey={previousSurvey}
                                    standAloneQue={section.header === ""}
                                />
                            );
                        } else if (question.question_type === 'radio' || question.question_type === 'checkbox') {
                            return (
                                <OptionialQuestion
                                    key={questionIndex}
                                    sequence={order = order + 1}
                                    lastItem={questionIndex === section.questions.length - 1}
                                    report={question}
                                    previousSurvey={previousSurvey}
                                    standAloneQue={section.header === ""}
                                />
                            );
                        } else if (question.question_type === 'slider') {
                            return (
                                <SliderQuestion
                                    key={questionIndex}
                                    sequence={order = order + 1}
                                    lastItem={questionIndex === section.questions.length - 1}
                                    report={question}
                                    previousSurvey={previousSurvey}
                                    standAloneQue={section.header === ""}
                                />
                            );
                        }
                        else if (question.question_type === 'date') {
                            return (
                                <div style={{
                                    ...styles.container,
                                    marginBottom: section.header === "" ? '0' : '30px', 
                                    border:section.header === ""? "none":'1px solid #DEDEDE'
                                  }}>
                                    <AnswerCard>
                                        <div>
                                            <Typography style={styles.title}>
                                                {order = order + 1}{'.'} {question.question_title}
                                            </Typography>
                                        </div>
                                        <div>
                                            {question.answer ? <Typography style={styles.answerDate}>Your answer: {question.answer}</Typography> : <Typography style={styles.answerDate}>No response</Typography>}
                                        </div>
                                    </AnswerCard>
                                </div>
                            )
                        }
                        else if (question.question_type === 'ranking') {
                            return (
                                <div style={{
                                    ...styles.container,
                                    marginBottom: section.header === "" ? '0' : '30px', 
                                    border:section.header === ""? "none":'1px solid #DEDEDE'
                                  }}>
                                    <AnswerCard>
                                        <div>
                                            <Typography style={styles.title}>
                                                {order = order + 1}{'.'} {question.question_title}
                                            </Typography>
                                        </div>
                                                <div>
                                                    {question.analytics.map((options: any) => {
                                                        return (
                                                            <RankWrapper key={options.option.id}>
                                                                <NameWrapper>{options.option.name}</NameWrapper>
                                                                <RankDetails>
                                                                    <RankBox>
                                                                        <RankTitle>Group rank</RankTitle>
                                                                        <RankValue>Rank {options.rank}</RankValue>
                                                                    </RankBox>
                                                                    {!previousSurvey &&
                                                                        <RankBox>
                                                                            <RankTitle>User rank</RankTitle>
                                                                            {question.answer ?
                                                                            <RankValue>Rank {options.user_rank}</RankValue>
                                                                            : <RankValue> None </RankValue>
                                                                        }
                                                                        </RankBox>}
                                                                </RankDetails>
                                                            </RankWrapper>
                                                        );
                                                    })}
                                                </div>
                                                <div style={styles.result}>
                                                    <Typography style={styles.yourAnswer}>Total responses: {" "} {question?.total}</Typography>
                                                </div>
                                    </AnswerCard>
                                </div>
                            )
                        }
                    })}
                </Sections>
            ))}
        </div>
    )
}


const RankWrapper = styled('div')({
    background: '#f5f6f8',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
    padding: '10px',
    borderRadius: '7px',
    alignItems: 'center',
});

const CustomSlider = styled(Slider)({
    color: '#2e4774',
    height: 8,
    padding: '10px 0',

    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '4px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },

    '& .MuiSlider-track': {
        height: 8,
        borderRadius: 4,
    },

    '& .MuiSlider-rail': {
        height: 8,
        borderRadius: 4,
        backgroundColor: 'white',
        opacity: '1'
    },
    '& .PrivateValueLabel-circle-29': {
        transform: 'none',
        width: '70px',
        color: 'white',
        borderRadius: '3px',
        position: 'absolute',
        left: '-20px'
    },
    '& .PrivateValueLabel-label-30': {
        transform: 'none',
        color: 'black'
    }
});
const PrettoSlider = withStyles({
    root: {
        color: '#374f81',
        height: 8,
        width: '95%',
        '@media (max-width: 940px)': {
            width:'85%',
           }, 
        '@media (max-width: 1340px)': {
            width:'92%',
           }, 
           '@media (max-width: 540px)': {
            width:'68%',
           },           
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '4px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: '-30px',
        '& *': {
            background: 'white',
            color: '#000',
            transform: 'none',
            width: '80px',
            borderRadius: '4px',
            textAlign: 'center'
        },
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
        opacity: 1,
        backgroundColor: 'white',
    },
})(Slider);

const NameWrapper = styled(Typography)({
    width: '50%',
    fontSize: '13px',
    fontFamily: 'aeonik-regular'
});

const RankDetails = styled('div')({
    display: 'flex',
});

const RankBox = styled('div')({
    backgroundColor: '#e4e8f3',
    margin: '0 5px',
    padding: '5px 10px',
    borderRadius: '6px',
    // textAlign: 'center',
});

const RankTitle = styled(Typography)({
    fontSize: '13px',
    fontFamily: 'aeonik-regular'
});

const RankValue = styled(Typography)({
    color: '#4B6EB0',
    fontFamily: 'aeonik-regular'
});
const sliderStyle = {
    width: '100%',
    height: '10px',
    borderRadius: '5px',
    background: `red`,
    outline: 'none',
    opacity: '0.7',
    transition: 'opacity 0.15s ease-in-out',
    WebkitAppearance: 'none', /* Remove default appearance for Webkit browsers */
    MozAppearance: 'none', /* Remove default appearance for Firefox */
    appearance: 'none' /* Remove default appearance */
};
const LabelWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '-10px',
    marginLeft:"2.5%",
    marginRight:"2.5%",
    width: '95%',
    '@media (max-width: 1340px)': {
      width:'92%',
      marginLeft: '4%',
      marginRight: '4%', 
     },
     '@media (max-width: 940)': {
        width:'85%',
        marginLeft: '7.5%',
        marginRight: '7.5%', 
       }, '@media (max-width: 540)': {
        width:'68%',
        marginLeft: '16%',
        marginRight: '16%', 
       },
})
const Sections = styled('div')({
    margin: '20px',
    borderRadius: '10px',
    borderLeft: '1px solid #3e5686',
})
const SectionHeader = styled('h2')({
    fontWeight: 500,
    borderRadius: '10px 10px 0px 0px',
    padding: '10px',
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    fontSize: '19px',
    fontFamily: 'aeonik-bold',
    color: 'white',
    marginBottom: '0px'

})
const AverageDiv = styled('div')({
    width: '80%',
    height: '100%',
    backgroundColor: '#E4E8F2',
    borderRadius: '10px',
    paddingLeft: '12px',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'aeonik-regular',
    '@media screen and (max-width:500px)': {
        minWidth: '50%',
    }
})
const SliderWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0px 10px 0px',
    backgroundColor: '#e4e8f3'
})
const AnswerCard = styled('div')({
    margin: '24px 24px ',
    '@media screen and (max-width:500px)': {
        margin: '15px 15px'
    }
})
const AnswerContainer = styled('div')({
    padding: '20px 16px',
    borderRadius: '10px',
    border: '1px solid #BFCAE4',
    '@media screen and (max-width:500px)': {
        borderRadius: '8px',
        padding: '10px 8px',
    }
})
const Card = styled('div')({
    margin: '30px 30px',
    border: '1px solid #DEDEDE',
    padding: '30px 24px',
    '@media screen and (max-width:500px)': {
        margin: '15px',
        padding: '15px 12px',
        paddingRight: '25px',
    }
})
const SliderLabel = styled('h2')({
    fontSize: '15px',
    // marginTop: '-22px',
    fontStyle: 'normal',
    fontWeight: 100,
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    wordWrap: 'break-word',
    // marginRight:'20px',
    '@media screen and (max-width:500px)': {
        fontSize: '11.5px',
        maxWidth: '90px',
        marginRight: '11px'
    }
})
const SliderLabelRight = styled('h2')({
    fontSize: '15px',
    // marginTop: '-22px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    fontFamily: 'aeonik-regular',
    wordWrap: 'break-word',
    // marginLeft:'20px',
    '@media screen and (max-width:500px)': {
        fontSize: '11.5px',
        maxWidth: '90px',
        marginLeft: '11px',
    }
})
const StyledSlider = styled(Slider)({
    '@media screen and (max-width:500px)': {
        marginLeft: '6px',
        marginRight: '6px',
    }
})
const styles = {
    container: {
        borderBottom: '1px solid #DEDEDE',
        borderRight: '1px solid #DEDEDE',
        borderTop: '1px solid #DEDEDE',
        marginBottom: '30px',
        borderRadius: '0px 10px 10px 10px',
        borderLeft:'none'
    },
    card: {
        margin: '30px 30px',
        border: '1px solid #DEDEDE',
        padding: '30px 24px',
    },
    answerCard: {
        margin: '30px 30px',
    },
    title: {
        color: '#333',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        marginBottom: '16px',
        fontFamily: 'aeonik-regular',
        wordWrap: 'break-word' as const,
    },
    answerTitle: {
        color: '#4B6EB0',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        marginBottom: '8px',
        fontFamily: 'aeonik-regular',
    },
    answerDate: {
        color: '#202020',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        marginBottom: '8px',
        fontFamily: 'aeonik-regular',
        wordWrap: 'break-word' as const,
    },
    sliderLabels: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: "50px",
    },
    sliderLabel: {
        fontSize: '15px',
        marginTop: '-22px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        fontFamily: 'aeonik-regular',
        wordWrap: 'break-word' as const,
    },
    answer: {
        color: '#000',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        fontFamily: 'aeonik-regular',
        wordBreak: 'break-all' as const,
    },
    answerContainer: {
        padding: '20px 16px',
        borderRadius: '10px',
        border: '1px solid #BFCAE4'
    },
    percentage: {
        display: 'flex',
        maxWidth: "90%",
        minWidth: "50%",
        // height: "30px",
        borderRadius: "10px",
        marginBottom: "16px",
        alignItems: 'center',
        wordWrap: 'break-word' as const,
    },
    average: {
        width: '80%',
        height: '100%',
        backgroundColor: '#e4e8f4',
        borderRadius: '3px',
        paddingLeft: '12px',
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'aeonik-regular',
    },
    volume: {
        marginRight: '-50px',
        marginLeft: '8px',
        fontFamily: 'aeonik-regular',
    },
    yourAnswer: {
        color: '#202020',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '24px',
        fontFamily: 'aeonik-regular',
        wordWrap: 'break-word' as const,
    },
    divider: {
        width: '2px',
        height: '25px',
        background: '#D9D9D9',
        margin: '0px 16px',
    },
    totalCount: {
        color: '#202020',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '24px',
        fontFamily: 'aeonik-regular',
    },
    result: {
        display: 'flex',
        paddingTop: "8px"
    },
    option: {
        marginLeft: '-8px',
        fontFamily: 'aeonik-regular',
        wordWrap: 'break-word' as const,
    }
};

export default SurveyReportCards
