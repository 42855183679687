import React, { CSSProperties, FunctionComponent, useState, useEffect } from 'react';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';

import QuestionCard from './QuestionCard.web';
import Input from './CustomInput.web';
import AddOptionDialog from './AddOptionDialog.web';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import { Box, makeStyles } from '@material-ui/core';

const plusSvg = require('./plus.svg');

interface Props {
  onChange: (data: any) => any;
  onDeleteClick: (data: any) => any;
  data: any;
  isLiveorPastSurvey: boolean;
  setMouseIn: (val: number) => void;
}

interface SortableItemProps {
  id: string;
  onDelete: () => void;
  children: React.ReactNode;
}

const POSITION_RELATIVE: CSSProperties['position'] = 'relative';

const SortableItem: React.FC<SortableItemProps> = ({ id, onDelete, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style: CSSProperties = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    transition,
    position: POSITION_RELATIVE,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
        <div style={{ flex: 1 }}>
          {children}
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <DragIndicatorIcon
            style={{ 
              cursor: 'grab',
              transform: 'rotate(90deg)',
              color: 'rgb(170, 181, 180)',
              marginLeft: '-33px',
              marginRight: '11px',
              marginTop: '45px',
            }}
            {...listeners}
          />
          <CloseIcon
            style={styles.deleteBtn}
            onClick={(event: { stopPropagation: () => void; }) => {
              event.stopPropagation();
              onDelete();
            }}
          />
        </div>
      </div>
    </div>
  );
};
const useStyles = makeStyles(() => ({
  mainBox:{
    fontFamily: 'aeonik-regular',
    "& ::placeholder":{
        fontFamily: 'aeonik-regular',
    },
  }
}));
const RankingQuestion: FunctionComponent<Props> = ({ onChange, onDeleteClick, data, isLiveorPastSurvey, setMouseIn }) => {
  const classes= useStyles()
  const [visible, setVisible] = useState(false);
  const [options, setOptions] = useState<string[]>([]);
  const [type, setType] = useState('ranking');

  useEffect(() => {
    setOptions(data?.options || []);
  }, [data?.options]);

  const handleAddOptionClicked = (updatedOptions: string[]) => {
    setOptions(updatedOptions);
    onChange({
      ...data,
      options: updatedOptions,
    });
  };

  const handleDialogClose = () => {
    setVisible(false);
  };

  const handleDeleteOption = (index: number) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
    onChange({
      ...data,
      options: newOptions,
    });
  };

  const handleDragEnd = (event: { active: any; over: any }) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = options.findIndex((option, idx) => `${option}-${idx}` === active.id);
      const newIndex = options.findIndex((option, idx) => `${option}-${idx}` === over.id);

      const updatedOptions = arrayMove(options, oldIndex, newIndex);
      setOptions(updatedOptions);
      onChange({
        ...data,
        options: updatedOptions,
      });
    }
  };

  return (
    <Box className={classes.mainBox}>
    <QuestionCard
      isLiveorPastSurvey={isLiveorPastSurvey}
      title='Ranking Question'
      data={data}
      required
      onDeleteClick={() => onDeleteClick(data?.ind)}
      onRequiredCheck={(val) =>
        onChange({
          ...data,
          required: val,
        })
      }
    >
      <Input
        readOnly={isLiveorPastSurvey}
        disabled={isLiveorPastSurvey}
        value={data?.question_title}
        placeholder='Add question text'
        onChange={(event) =>
          onChange({
            ...data,
            question_title: event.target.value,
          })
        }
        maxLength={128}
      />
      <div
        onMouseLeave={() => setMouseIn(0)}
        onMouseEnter={() => setMouseIn(1)}
      >
        <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={options.map((option, index) => `${option}-${index}`)} strategy={verticalListSortingStrategy}>
            {options?.map((el, ind) => (
              <SortableItem key={`${el}-${ind}`} id={`${el}-${ind}`} onDelete={() => handleDeleteOption(ind)}>
                <Input
                  readOnly={isLiveorPastSurvey}
                  disabled={isLiveorPastSurvey}
                  style={styles.input}
                  value={el}
                  label={`Statement ${ind + 1}`}
                  onChange={(event) => {
                    const newOptions = [...options];
                    newOptions[ind] = event.target.value;
                    setOptions(newOptions);
                    onChange({
                      ...data,
                      options: newOptions,
                    });
                  }}
                  maxLength={128}
                />
              </SortableItem>
            ))}
          </SortableContext>
        </DndContext>
      </div>

      {options?.length < 2 && (
        <div style={{ marginTop: '20px' }}>
          <p style={styles.warning}>Please select at least 2 options</p>
        </div>
      )}

      <button
        disabled={isLiveorPastSurvey}
        onClick={() => setVisible(true)}
        style={{
          ...styles.btn,
          borderColor: isLiveorPastSurvey ? 'grey' : '#375080',
          cursor: 'pointer',
        }}
      >
        <AddIcon htmlColor={isLiveorPastSurvey ? 'grey' : '#375080'} />
        Add option
      </button>
      <AddOptionDialog
        savedOptions={data?.options}
        visible={visible}
        onClose={handleDialogClose}
        onSave={handleAddOptionClicked}
        type={type}
      />
    </QuestionCard>
    </Box>
  );
};

const styles = {
  input: {
    marginTop: '16px',
  },
  plusIcon: {
    marginRight: '10px',
  },
  deleteBtn: {
    cursor: 'pointer',
    color: 'rgb(170, 181, 180)',
    position: POSITION_RELATIVE,
    marginLeft: '6px',
    marginTop: '45px',
    marginRight: '2px',
    right: '-3px',
  },
  btn: {
    display: 'flex',
    padding: '6px 32px',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #1C386D',
    borderRadius: '10px',
    maxWidth: 'fit-content',
    background: 'none',
    fontFamily: 'aeonik-regular',
    marginTop: '16px',
  },
  warning: {
    marginTop: '0px',
    color: '#f54f5f',
    fontFamily: 'aeonik-regular',
  },
};

export default RankingQuestion;

