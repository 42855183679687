import React from "react";

import {
  Container,
  Box,
  Typography,
  // Customizable Area Start
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Card from "../../../components/src/Card.web";
import { cardIcon } from "./assets";

const SurveysTheme = createTheme({
  palette: {
    primary: {
      main: "#0000FF",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


import SurveysController, { Props } from "./SurveysController.web";
// Customizable Area End


export default class SurveysWeb extends SurveysController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
  // Customizable Area Start
  const { surveyList } = this.state;
  // Customizable Area End

    return (
    // Customizable Area Start
      <Card title="Survey catalogue">
        <CustomBox>
          {surveyList.map((survey, index) => {
            return (
              <CustomCard key={index} gridColumn="span 3">
                <div style={webStyle.container}>
                    <div style={webStyle.imgDraftDiv}>
                      <div style={webStyle.profileContainer}>
                        {survey.attributes.user_avatar ?
                          <img style={webStyle.profileImg} src={survey.attributes.user_avatar} alt="admin avatar" /> :
                          <Typography style={webStyle.initials}>{this.renderInitials(survey.attributes.user_first_name, survey.attributes.user_last_name)}</Typography>
                        }
                      </div>
                      {survey.attributes.draft ? <span style={webStyle.draftDiv}>Draft</span> : null}
                    </div>
                  <Typography style={webStyle.cardHeader}>{survey.attributes.name}</Typography>
                  <Typography style={webStyle.text}>{survey.attributes.description}</Typography>
                  {/* <Typography style={webStyle.text}>Survey to collect data from customers.</Typography> */}
                  {!survey.attributes.draft && (
                    <Button
                      data-test-id={`fill-btn-${survey.id}`}
                      style={webStyle.btn}
                      onClick={() => this.fillSurveyClick(survey.id)}
                    >
                      Fill
                    </Button>
                  )}
                  {survey.attributes.draft && (
                    <Button
                      data-test-id={`complete-btn-${survey.id}`}
                      style={webStyle.secondaryBtn}
                      onClick={() => this.fillSurveyClick(survey.id)}
                    >
                      Complete
                    </Button>
                  )}
                </div>
              </CustomCard>
            )
          })}
        </CustomBox>
      </Card>
      // Customizable Area End
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const CustomBox = styled(Box)({
  display: 'grid',
  columnGap: '1rem',
  rowGap: '1rem', 
  gridTemplateColumns: 'repeat(12, 1fr)',
  '@media screen and (max-width: 500px)': {
    gridTemplateColumns: '1fr',
  },
  '@media screen and (min-width: 510px) and (max-width: 900px)': { // For tablets
    gridTemplateColumns: 'repeat(9, 3fr)',
  }
})
const CustomCard = styled(Box)({
  height: '280px',
  borderRadius: '28px',
  border: '0.5px solid #DEDEDE',
  background: '#FCFCFC',
  '@media screen and (max-width: 768px)': {

    borderRadius: '20px',
  },
  '@media screen and (max-width: 576px)': {

    borderRadius: '20px',
  }
})

const webStyle = {
  card: {
    height: '280px',
    borderRadius: '28px' as const,
    border: '0.5px solid #DEDEDE',
    background: '#FCFCFC',
    backgroundColor:'red',
  },
  container: {
    padding: '24px 16px',
    display: 'flex',
    flexDirection: 'column' as const,
    color: 'var(--text, #202020)',
  },
  imgDraftDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    height: "80px"
  },
  draftDiv: {
    padding: "2px 32px",
    backgroundColor: "#E9EFFD",
    fontFamily: 'aeonik-regular',
    lineHeight: "24px",
    fontSize: "12px",
    fontWeight: 700,
    borderRadius: "5px",
  },
  img: {
    width: '50px',
    height: '50px',
    marginBottom: '24px'
  },
  cardHeader: {
    fontSize: '20px',
    height: "25px",
    overflow: "auto",
    fontWeight: 600,
    marginBottom: '16px',
    lineHeight: 'normal',
    fontStyle: 'normal',
    fontFamily: 'aeonik-bold',
    wordWrap: 'break-word' as const,
  },
  subHead: {
    fontSize: '14px',
    fontWeight: 600,
    marginBottom: '8px',
    lineHeight: 'normal',
    fontStyle: 'normal',
    fontFamily: 'aeonik-bold',
  },
  text: {
    fontSize: '14px',
    fontWeight: 500,
    marginBottom: '30px',
    height: "33px",
    overflow: "auto",
    lineHeight: 'normal',
    fontStyle: 'normal',
    fontFamily: 'aeonik-regular',
    wordWrap: 'break-word' as const,
  },
  btn: {
    padding: '18px 32px',
    marginBottom: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    background: 'var(--g, linear-gradient(180deg, #1C386D 0%, #1C386D 100%))',
    fontSize: '16px',
    fontWeight: 700,
    color: 'rgba(255, 255, 255, 1)',
    lineHeight: 'normal',
    fontStyle: 'normal',
    textTransform: "capitalize" as const,
    fontFamily: 'aeonik-bold',
  },
  secondaryBtn: {
    padding: '18px 32px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    border: '1px solid #1C386D',
    color: '#1C386D',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 'normal',
    fontStyle: 'normal',
    textTransform: "capitalize" as const,
    fontFamily: 'aeonik-bold',
  },
  profileImg: {
    height: '52px',
    width: '52px',
    objectFit: 'cover' as const,
    borderRadius: '50%',
  },
  initials: {
    padding: '8px',
    fontSize: '20px',
    color: '#A2AFCD',
    fontFamily: 'aeonik-bold',
    fontWeight: 700,
    fontStyle: 'normal',
    textAlign: 'center' as const,
    lineHeight: 'normal',
  },
  profileContainer: {
    width: '52px',
    display: 'flex',
    height: '52px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ECEFF7',
    position: 'relative' as const,
    marginRight: '40px',
    borderRadius: '50%',
  },
};

// Customizable Area End
